import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import {PEButton, PEDiv, PEInput, PESelect, PETable, PETbody} from './permissionComponents/index'
function Cache_view_delete(props) {
  const [loading, sendRequest] = useAxios();
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [filterList, setFilterList] = useState([]);
  const [serchinput, setSearchInput] = useState();
  const [originalList, setOriginalList] = useState([]);
  const [keys, setKeys] = useState([]);
  const [checkedval, setcheckedval] = useState([]);
  const [search_string, setsearch_string] = useState([]);
  const [isCheck, setIsCheck] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [search_inputkey, setsearch_inputkey] = useState();
  const [search_inputvalue, setsearch_inputvalue] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {}, []);

  const toggleRowSelection = (name) => {
    setSelectedRows((prevSelectedRows) => {
      // Check if the name is already in the array
      if (prevSelectedRows.includes(name)) {
        // If it is, remove it
        return prevSelectedRows.filter((item) => item !== name);
      } else {
        // If it's not, add it
        return [...prevSelectedRows, name];
      }
    });
  };

  const toggleSelectAll = () => {
    const allSelected = selectedRows.length === keys.length;
    if (allSelected) {
      setSelectedRows([]);
    } else {
      setSelectedRows(keys);
    }
  };

  const search_input = () => {
    let apitoken = localStorage.getItem("api_token");
    // let { fieldname, type, mandatory, status } = serchinput[0];
    const form = new FormData();
    form.append("match", serchinput ? serchinput : "");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/filter/cache`,
        method: "POST",
        data: form,
        headers: {
          "Content-Type": "text/html",
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("Filter List", response);
        // setisErrOpen(true);
        //setFilterList(response["keys"]);
        setsearch_inputkey("");
        setsearch_inputvalue("");
        setKeys(response["keys"]);
        setOriginalList(response["keys"]);
        getSubstring(response["keys"]);
      }
    );
  };
  const getSubstring = (data) => {
    const uniqueKeys = new Set();
    // Loop through the data and extract values between curly braces
    data.forEach((item) => {
      const matches = item.match(/\{(.+?)\}/);
      if (matches) {
        const keyValue = matches[1];
        try {
          const obj = JSON.parse(`{${keyValue}}`);
          for (const key in obj) {
            uniqueKeys.add(key);
          }
        } catch (error) {
          console.error(`Error parsing JSON: ${keyValue}`);
        }
      }
    });
    // Convert the Set to an array for easier rendering in React
    let unique = Array.from(uniqueKeys);
    setsearch_string(unique);
  };
  const deletecache = (e = "", typ) => {
    let apitoken = localStorage.getItem("api_token");
    const form = new FormData();
    if (typ == "key") {
      form.append("key", e);
    }
    if (typ == "multiple_keys") {
      form.append("multiple_keys", selectedRows.toString());
    }
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/clear/cache`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setIsCheckAll(false);
        setIsCheck(false);
        setcheckedval([]);
        setisErrOpen(true);
        setapi_error_message(response.message);
      }
    );
  };
  // const out = () => {
  //   setIsCheckAll(!isCheckAll);
  //   setIsCheck(!isCheck);
  // };
  const chkchkbox = (e, i, index) => {
    const isChecked = i.target.checked;
    if (isChecked) {
      setcheckedval([...checkedval, e]);
    } else {
      const i = checkedval.indexOf(e);
      checkedval.splice(i, 1);
      setcheckedval(e);
    }
  };
  const filterdata = () => {
    // search_inputkey
    // search_inputvalue
    if (search_inputkey && search_inputvalue) {
      const searchQuery = `{"${search_inputkey}": "${search_inputvalue}"}`;
      const foundData = keys.find((command) => command.includes(searchQuery));
      setKeys([foundData]);
    }
  };
  // console.log("selectedRows->", selectedRows);
  //console.log("keys->", keys);
  return (
    <div className="container">
      <Commonheader pagetitle={"Cache View"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <PEButton element_id="cachedel_btn"
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                search_input();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </PEButton>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <PEDiv element_id="CacheSearch" className="row">
          <div className="col-md-7 ">
            <input
              name="search_cache"
              className="form-control"
              placeholder="Search API"
              value={serchinput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>

          <PEDiv element_id="cachedel_search" className="form-group col-md-2">
            <PEButton element_id="cachedel_searchbtn" className="btn btn-primary" onClick={search_input}>
              <SearchOutlined />
            </PEButton>
          </PEDiv>
        </PEDiv>
      </div>
      <div className="mt-2">
        <div className="row">
          {search_string.length > 0 && (
            <>
              <PEDiv element_id="cachedel_searchkey" className="col-md-3">
                <PESelect element_id="CacheSearchkey"
                  name="searchkey"
                  className="form-control"
                  value={search_inputkey}
                  onChange={(e) => setsearch_inputkey(e.target.value)}
                >
                  <option value="">select</option>
                  {search_string.map((keys) => {
                    return <option value={keys}>{keys}</option>;
                  })}
                </PESelect>
              </PEDiv>
              <div className="col-md-3">
                <PEInput element_id="CacheKeyValue"
                  name="keyvalue"
                  value={search_inputvalue}
                  className="form-control"
                  onChange={(e) => setsearch_inputvalue(e.target.value)}
                />
              </div>
              <PEDiv element_id="cachedel_filter" className="col-md-3">
                <PEButton element_id="CacheFilterBtn"
                  className="btn btn-primary"
                  onClick={() => filterdata()}
                >
                  filter
                </PEButton>
              </PEDiv>
            </>
          )}
        </div>
      </div>
      <div className="mt-4">
        <PETable element_id="cachedel_table" className="table">
          <thead>
            <tr>
              {keys && (
                <th>
                  <input
                    type="checkbox"
                    checked={selectedRows.length === keys.length}
                    onChange={toggleSelectAll}
                  />
                  <PEButton element_id="cachedel_dltcache"
                    className="btn btn-danger ml-2"
                    disabled={selectedRows.length == 0}
                    onClick={() => {
                      if (window.confirm("Delete the item?")) {
                        deletecache(selectedRows, "multiple_keys");
                      }
                    }}
                  >
                    <DeleteOutlined />
                  </PEButton>
                </th>
              )}
              <th>Name</th>
              <th>Data</th>
            </tr>
          </thead>
          <PETbody element_id="cachedel_tbody">
            {keys &&
              keys.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(item)}
                        onChange={() => toggleRowSelection(item)}
                      />
                    </td>
                    <td>{item}</td>
                    <td>
                      <PEButton element_id="cachedel_dltitem"
                        className="btn btn-danger"
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            deletecache(item, "key");
                          }
                        }}
                      >
                        <DeleteOutlined />
                      </PEButton>
                    </td>
                  </tr>
                );
              })}
          </PETbody>
        </PETable>
        {/* <table className="table">
          <thead>
            <tr>
              <th>
                
                <button
                  className="btn btn-danger"
                  disabled={checkedval.length == 0}
                  onClick={() => deletecache(checkedval, "multiple_keys")}
                >
                  <DeleteOutlined />
                </button>
              </th>
              <th>
                Keys
                
              </th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {keys &&
              keys.map((e, index) => {
                return (
                  <>
                    <tr>
                      <td>
                        <input
                          className="form-control p-5"
                          type="checkbox"
                          value={e}
                          onChange={(i) => chkchkbox(e, i, index)}
                          checked={checkedval.includes(e)}
                        />
                      </td>
                      <td>{e}</td>
                      <td>
                        <button
                          className="btn btn-danger"
                          onClick={() => deletecache(e, "key")}
                        >
                          <DeleteOutlined />
                        </button>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </table> */}
      </div>
    </div>
  );
}
export default Cache_view_delete;

import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { Button } from "antd";
import {PEButton, PETable, PETr} from './permissionComponents/index'
import { EditOutlined } from "@ant-design/icons";

function View_list(props) {
  const [loading, sendRequest] = useAxios();
  const [viewList, setviewList] = useState();
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [
    effetive_designation_idList,
    seteffetive_designation_idList,
  ] = useState();

  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        //At-2143||rohit chitampalle||api-restructuring-and-connection

        url: `${process.env.REACT_APP_BASE_URL}/get/webhook/configuration/data`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("setviewList", response);
        setviewList(response);
        //setjiraTicket(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/designation/list`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        seteffetive_designation_idList(response);
        //setjiraTicket(response);
      }
    );
  }, []);
  const get_eftDesi = (eid) => {
    const foundDesignation =
      effetive_designation_idList &&
      effetive_designation_idList.find(
        (designation) => designation.effective_designation_id === eid
      );
    return foundDesignation && foundDesignation.designation_code;
  };
  return (
    <div className="container">
      <Commonheader pagetitle={"View List"} props={props} />
      {loading && (
        <div class="load-4">
          <div class="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <PEButton  element_id="viewlist_closetext"
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </PEButton>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <PETable element_id="viewlist_table" class="table">
          <thead class="thead-light">
            <PETr element_id="viewlist_tablerow">
              <th scope="col">View Name</th>
              <th scope="col">Bucket Name</th>
              <th scope="col">Event Name</th>
              <th scope="col">Effective Designation</th>
              <th scope="col">Action</th>
            </PETr>
          </thead>
          <tbody>
            {viewList &&
              viewList.map((list) => (
                <tr>
                  <td>
                    {list.view_name}{" "}
                    {!list.getpayloadfields ? (
                      <span className="errmessage">*</span>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{list.bucket_name}</td>
                  <td>{list.event_name}</td>
                  <td>{get_eftDesi(list.effective_designation_id)}</td>
                  <td>
                    <PEButton element_id="viewlist_view"
                      type="button"
                      class="btn btn-outline-info"
                      onClick={() =>
                        props.history.push({
                          pathname: "/View",
                          state: {
                            view_id: list.view_id,
                            payloadTile: true,
                            component_mapping: "",
                          },
                        })
                      }
                    >
                      <EditOutlined />
                    </PEButton>
                  </td>
                </tr>
              ))}
          </tbody>
        </PETable>
      </div>
    </div>
  );
}

export default View_list;

import React, { useState, useEffect } from "react";
import axios from "axios";
import loader from "../assets/images/loader.gif";

import Logo from "../assets/images/aceN_Logo.png";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import Cookies from "js-cookie";
function Login(props) {
  const [isDisabledBTN, setisDisabledBTN] = useState(false);
  const [username, setusername] = useState();
  const [userpass, setuserpass] = useState();
  const [usernamerr, setusernamerr] = useState();
  const [errormsgpassword, seterrormsgpassword] = useState();
  const [IPv4, setIPv4] = useState();
  const [latitude, setlatitude] = useState();
  const [longitude, setlongitude] = useState();
  const [isSpin, setisSpin] = useState(false);
  const [login_status, setlogin_status] = useState();
  const [isLoginstatus, setisLoginstatus] = useState(false);

  useEffect(() => {
    console.log("accesstokenlogout", localStorage.getItem("access_token"));
    if (localStorage.getItem("access_token")) {
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/new/logout`,
          {},
          {
            headers: {
              "api-token": localStorage.getItem("access_token"),
            },
          }
        )
        .then((res) => res.json())
        .then((data) => {
          localStorage.clear();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const handleChange = (event) => {
    setusername(event.target.value);
    // if (event.target.name === "username") {
    //   const pattern = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i;
    //   const result = pattern.test(event.target.value);
    //   //console.log("Email Valid:", result);
    //   if (result === true) {
    //     //this.setState({ errormsg: "", isEnable: false });
    //   } else {
    //     //this.setState({ errormsg: "Enter valid User Name", isEnable: true });
    //     setusernamerr("Enter valid User Name");
    //   }
    // }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setisSpin(true);

    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/userexist?in_username=` + username,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        //console.log(res.data.Valid);
        if (res.data.Valid == false) {
          setusernamerr("User Not Found.");
          setisDisabledBTN(true);
        } else {
          setusernamerr("");
          setisDisabledBTN(false);
          // let UserId = res.data.userid;
          localStorage.setItem("userid", res.data.userid);
          console.log("userid", res.data.userid);

          const ftoken = localStorage.getItem("currentToken");
          const UserId = localStorage.getItem("userid");
          const formdata = new FormData();
          //formdata.append("in_username", username);
          formdata.append("in_userid", UserId);
          formdata.append("in_user_password", userpass);
          formdata.append("in_client_ip", IPv4);
          formdata.append("in_latitude", latitude);
          formdata.append("in_longitude", longitude);
          formdata.append("in_gps_address", latitude + "," + longitude);
          formdata.append("firebase_token", ftoken);

          axios
            .post(`${process.env.REACT_APP_BASE_URL}/new/login`, formdata, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              console.log(res.data);
              if (res.data.logged_in === true) {
                localStorage.setItem("in_userid", res.data.in_userid);
                localStorage.setItem("in_username", res.data.in_username);
                localStorage.setItem("api_token", res.data.access_token);
                localStorage.setItem("access_token", res.data.access_token);
                localStorage.setItem("refresh_token", res.data.refresh_token);
                Cookies.set("refresh_token", res.data.refresh_token);
                const formdata1 = new FormData();
                formdata1.append("userid", res.data.in_userid);
                //At-2143||rohit chitampalle||api-restructuring-and-connection
                axios

                  .get(
                    `${process.env.REACT_APP_BASE_URL}/getrole?userid=` +
                      res.data.in_userid,
                    {
                      headers: {
                        "api-token": res.data.access_token,
                      },
                    }
                  )
                  .then((res1) => {
                    localStorage.setItem(
                      "effective_designation_id",
                      res1.data.effective_designation_id
                    );
                    localStorage.setItem("branch_id", res1.data.branch_id);
              localStorage.setItem(
                "e_designation_id",
                res1.data.effective_designation_id
              );
                    setisSpin(false);
                    props.history.push({
                      pathname: "/dashboard",
                      state: {
                        effective_designation_id:
                          res1.data.effective_designation_id,
                        api_token: res.data.access_token,
                      },
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });

                //props.history.push("Dashboard");
              } else {
                if (res.data.login_status == "LI") {
                  setisLoginstatus(true);
                }
                localStorage.setItem("access_token", res.data.access_token);
                setisSpin(false);
                seterrormsgpassword(res.data.action_message);
                setlogin_status(res.data.login_status);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBlur = (e) => {
    e.preventDefault();

    axios.get("https://geolocation-db.com/json/").then((res) => {
      console.log("IPv4", res.data.IPv4);
      setIPv4(res.data.IPv4);
      setlatitude(res.data.latitude);
      setlongitude(res.data.longitude);
    });
  };
  const clearlogin = () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/new/logout`, {
        headers: {
          "Content-Type": "multipart/form-data",
          "api-token": localStorage.getItem("access_token"),
        },
      })
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <div className="simple-login-container container">
        {isSpin ? (
          <div className="preloader">
            <img className="w30" src={loader} />
          </div>
        ) : (
          ""
        )}

        <div
          className={
            isLoginstatus
              ? "modal fade show d-block mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setisLoginstatus(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <p className="popuperrtext">{errormsgpassword}</p>
              <span
                className="btn btn-danger col-md-2 mb-2"
                onClick={() => clearlogin()}
              >
                Logout
              </span>
            </div>
          </div>
        </div>

        <div className="logo-holder">
          <img src={Logo} className="login-logo" />
        </div>
        <div className="row">
          <div className="col-md-12 form-group">
            <input
              type="text"
              className="form-control"
              placeholder="Username"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <span className="errmessage">{usernamerr}</span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 form-group">
            <input
              type="password"
              placeholder="Enter your Password"
              className="form-control"
              onChange={(e) => setuserpass(e.target.value)}
              name="password"
            />
            {login_status != "LI" && (
              <div className="errmessage">{errormsgpassword}</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 form-group">
            <button
              disabled={isDisabledBTN}
              className="btn btn-block btn-login"
              //onClick={() => props.history.push("Dashboard")}
              onClick={handleSubmit}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

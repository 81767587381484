import EditableJsonTree from "./JsonEditor";
import React, { useEffect, useRef, useState } from "react";
import {PEButton, PEDiv} from './permissionComponents/index'

import {
  createNestedJson as createNestedPayload,
  linearize,
  getObjectDifference,
} from "./Update";
import { Modal, Form, Input, Select, InputNumber, Button, Row, Col } from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import e from "cors";

const _ = require("lodash");

const { Option } = Select;

const defaultConfig = {
  NDFields: "",
  VendorFields: "",
  isTransform: "",
  ven_defaultval: "",
  toupper: false
};
const defaultConditionsConfig = {
  field_name: "",
  field_value: "",
  operator: "",
  condition_type: "",
};

export default function TransformationMappingEditor({
  payloadMappings,
  sourcePayload,
  destPayload,
  conditions,
  storeOnSource,
  onChange,
}) {
  const [data, setData] = useState({});
  const [fieldlist, setFieldlist] = useState([]);
  const [preTransformationMappings, setPreTransformationMappings] = useState(
    []
  );
  const [conditionsMap, setConditionsMap] = useState({});

  // States to be returned for the parent.
  const [
    localTransformationMappings,
    setLocalTransformationMappings,
  ] = useState([]);
  const [localConditions, setLocalConditions] = useState([]);
  const [localStoreOnSource, setLocalStoreOnSource] = useState([]);

  useEffect(() => {
    // splits the piped vendor fields and sets default keys for empty vendor fields
    const _payloadMappings = preprocessing(payloadMappings);

    // create nested object with configs as values
    const nestedConfig = createNestedJson(_payloadMappings);

    // apply store on source on the pre-transformation mappings
    // _payloadMappings.forEach((mapping=>{
    //     mapping['storeOnSource'] = storeOnSource.filter((config)=>{
    //         // console.log(config.payload_path,mapping.VendorFields)
    //         return config.payload_path === mapping.NDFields
    //     })
    //     if(mapping['storeOnSource'].length>0)
    //     console.log(mapping['storeOnSource'])
    // }))
    // creating nested fields with source payload details(vendor_payload_detail)
    const mergedObj = get_merged_config(nestedConfig, sourcePayload);

    const _fieldlist = destPayload.reduce((obj, fieldConfig) => {
      obj.push(fieldConfig["payload_field"]);
      return obj;
    }, []);

    const _conditionsMap = constructConditionsMap(conditions);

    setConditionsMap(_conditionsMap);
    setLocalConditions(conditions);
    setPreTransformationMappings(_payloadMappings);
    setLocalTransformationMappings(
      getCleanTransformationMappings(_payloadMappings)
    );
    setLocalStoreOnSource(storeOnSource);
    setFieldlist(_fieldlist);
    setData(mergedObj);
  }, [payloadMappings, sourcePayload, destPayload, conditions, storeOnSource]);

  const get_merged_config = (nestedConfig, sourcePayload) => {
    const nestedSourcePayload = createNestedPayload(sourcePayload);

    setLeafNodes(nestedSourcePayload, "[]");
    // merge the existing configs with referenceJson
    const mergedObj = _.mergeWith(nestedSourcePayload, nestedConfig);
    return mergedObj;
  };

  // preprocessing for data, fieldlist
  const preprocessing = (mappings) => {
    return mappings.reduce((obj, mapping) => {
      let sequence = 0;
      mapping["VendorFields"] =
        mapping["VendorFields"] === ""
          ? "__default__"
          : mapping["VendorFields"];
      const vendorFieldKeys = mapping["VendorFields"].split("|");

      vendorFieldKeys.forEach((vendorFieldKey) => {
        obj.push({
          ...mapping,
          VendorFields: vendorFieldKey,
          mappingSequence: ++sequence,
        });
      });
      return obj;
    }, []);
  };

  // onChange event to return the linearized field mapping
  const createTransformationMapping = (value) => {
    const linearConfig = linearize(value);

    const transformationMapping = Object.keys(linearConfig).reduce(
      (obj, key) => {
        const configs = JSON.parse(linearConfig[key]);
        configs.forEach((config) => {
          obj.push({
            ...config,
            VendorFields: key,
          });
        });

        return obj;
      },
      []
    );
    return transformationMapping;
  };

  // onchange handler to return the updated mapping to the parent
  const onChangeHandlerMappings = (value) => {
    const _fieldlist = createTransformationMapping(value);

    // create transformation mapping list
    // sort the mappings by destination keys and the sequence count
    const sortedfieldlist = _fieldlist.sort((a, b) => {
      if (a["NDFields"] < b["NDFields"]) return -1;
      if (a["NDFields"] > b["NDFields"]) return 1;
      if (a["NDFields"] === b["NDFields"]) {
        return a["mappingSequence"] - b["mappingSequence"];
      }
      return 0;
    });

    const transformationMappings = sortedfieldlist.reduce(
      (newMappings, mappingConfig) => {
        const previousMappingConfig = newMappings[newMappings.length - 1];
        if (
          previousMappingConfig &&
          previousMappingConfig["NDFields"] === mappingConfig["NDFields"]
        ) {
          const vendorKeys = previousMappingConfig["VendorFields"].split("|");
          vendorKeys.push(mappingConfig["VendorFields"]);
          previousMappingConfig["VendorFields"] = vendorKeys.join("|");
        } else {
          newMappings.push(mappingConfig);
        }
        return newMappings;
      },
      []
    );

    let _preTransformationMappings = transformationMappings.reduce(
      (obj, mapping) => {
        let sequence = 0;
        const vendorFieldKeys = mapping["VendorFields"].split("|");
        vendorFieldKeys.forEach((vendorFieldKey) => {
          obj.push({
            ...mapping,
            VendorFields: vendorFieldKey,
            mappingSequence: ++sequence,
          });
        });
        return obj;
      },
      []
    );

    const _nestedConfig = createNestedJson(_preTransformationMappings);

    setData(get_merged_config(_nestedConfig, sourcePayload));
    setPreTransformationMappings(_preTransformationMappings);

    onChange({
      payloadMappings: getCleanTransformationMappings(
        _preTransformationMappings
      ),
      conditions: localConditions,
    });
  };

  const getCleanTransformationMappings = (preTransformationMappings) => {
    return preTransformationMappings.reduce((obj, mapping) => {
      mapping["VendorFields"] =
        mapping["VendorFields"] === "__default__"
          ? ""
          : mapping["VendorFields"];
      delete mapping.mappingSequence;
      obj.push(mapping);
      return obj;
    }, []);
  };
  // Handler to show only assigned fields and unassigned fields and all fields.
  const handleFilterSelect = (values) => { };

  // onChange event to return the linearized field mapping

  // ValueRenderer for field mapping
  const filterOptions = [
    { option: "All fields", value: 0 },
    { option: "Mapped fields", value: 1 },
  ];

  let fieldListCounts = preTransformationMappings.reduce((obj, config) => {
    obj[config["NDFields"]] = obj[config["NDFields"]]
      ? obj[config["NDFields"]] + 1
      : 1;
    return obj;
  }, {});

  fieldListCounts = {
    ...fieldlist.reduce((obj, value) => {
      obj[value] = 0;
      return obj;
    }, {}),
    ...fieldListCounts,
  };

  const constructConditionsMap = (conditions) => {
    return conditions.reduce((obj, condition) => {
      if (!obj[condition["field_name"]]) {
        obj[condition["field_name"]] = [];
      }
      obj[condition["field_name"]].push(condition);
      return obj;
    }, {});
  };
  const linearizeConditionsMap = (conditionsMap) => {
    const linearConditions = [];
    Object.keys(conditionsMap).forEach((field_name) => {
      linearConditions.push(...conditionsMap[field_name]);
    });
    return linearConditions;
  };

  const updateCondition = (path, values) => {
    const pathString = getPathString(path);
    const _conditionsMap = { ...conditionsMap, [pathString]: values };
    setConditionsMap(_conditionsMap);
    onChangeHandlerConditions(_conditionsMap);
  };

  const onChangeHandlerConditions = (conditionsMap) => {
    const _conditions = linearizeConditionsMap(conditionsMap);
    setLocalConditions(_conditions);
    onChange({
      payloadMappings: localTransformationMappings,
      conditions: _conditions,
    });
  };

  return (
    <div className="view_field_mapping_editor">
      {/* <div className="d-flex justify-content-between pl-2">
            <div></div>
            <Select style={{width:'150px'}} onChange={handleFilterSelect} defaultValue={0}>
                {
                    filterOptions.map(({option,value}) => (
                        <Option key={option} value={value}>
                            {option}
                        </Option>
                    ))
                }
            </Select>
            
        </div> */}
      <EditableJsonTree
        editEnabled={false}
        data={data}
        onChange={onChangeHandlerMappings}
        LabelRenderer={({ path, keyName, type, expanded }) => {
          let pathString = getPathString(path);
          const _conditions = conditionsMap[pathString]
            ? conditionsMap[pathString]
            : [];

          return (
            <CustomLabelRenderer
              path={path}
              keyName={keyName}
              type={type}
              expanded={expanded}
              conditions={_conditions}
              updateCondition={updateCondition}
            />
          );
        }}
        ValueRenderer={({ path, jsonConfig, onFormSubmit, valueType }) => {
          return (
            <ConfigFormRenderer
              path={path}
              jsonConfig={jsonConfig}
              onFormSubmit={onFormSubmit}
              valueType={valueType}
              fieldlist={fieldlist}
              fieldListCounts={fieldListCounts}
            />
          );
        }}
      />
    </div>
  );
}

const ConfigFormRenderer = ({
  path,
  jsonConfig,
  onFormSubmit,
  valueType,
  fieldlist,
  fieldListCounts,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [configs, setConfigs] = useState(JSON.parse(jsonConfig));
  const [originalFieldCount, setOriginalFieldCount] = useState({
    ...fieldListCounts,
  });

  useEffect(() => {
    setConfigs(JSON.parse(jsonConfig));
  }, [jsonConfig]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setConfigs(JSON.parse(jsonConfig));
    for (let key in fieldListCounts) {
      if (originalFieldCount.hasOwnProperty(key)) {
        fieldListCounts[key] = originalFieldCount[key];
      }
    }
    setIsModalVisible(false);
  };

  const handleSave = (e) => {
    e.stopPropagation();
    onFormSubmit(
      path,
      configs.reduce((obj, config) => {
        obj.push({ VendorFields: getPathString(path), ...config });
        return obj;
      }, [])
    );
    setIsModalVisible(false);
  };

  const fieldNames = configs.reduce((obj, config) => {
    obj.push(`${config["NDFields"]} (${config['globalMappingSequence']})`);
    return obj;
  }, []);

  const updateConfig = (config, index) => {
    const _configs = [...configs];
    _configs.splice(index, 1, config);
    setConfigs(_configs);
  };

  const deleteConfig = (index) => {
    const _configs = [...configs];
    _configs.splice(index, 1);
    setConfigs(_configs);
  };

  const handleAddField = () => {
    const _configs = [...configs];
    _configs.push(defaultConfig);
    setConfigs(_configs);
  };

  const selectedFieldlists = configs.reduce((obj, config) => {
    obj.push(config["NDFields"]);
    return obj;
  }, []);

  let filteredList = fieldlist.filter(
    (obj) => !selectedFieldlists.includes(obj)
  );

  return (
    <div
      style={{ display: "inline" }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span onClick={showModal} style={{ cursor: "pointer" }}>[ {
        configs.map((config, index) => {
          return <span >
            {config['NDFields']}
            <span style={{ 'color': 'grey' }}> ({config['globalMappingSequence']})</span>{configs.length - 1 != index ? ', ' : ''}
          </span>
        })
      } ]</span>

      <Modal
        title="Fields"
        open={isModalVisible}
        onCancel={handleCancel}
        width={"100%"}
        height={"80%"}
        footer={null}
      >
        {configs.map((c, index) => {
          return (
            <div
              className="view_field_form_container"
              key = {c.NDFields}    
            >
              <FieldMappingForm
                config={c}
                setConfig={(newConfig) => {
                  updateConfig(newConfig, index);
                }}
                deleteConfig={() => {
                  deleteConfig(index);
                }}
                valueType={valueType}
                fieldlist={filteredList}
                fieldListCounts={fieldListCounts}
              />
            </div>
          );
        })}
        <Row justify="center" align="middle" gutter={8}>
          <Col>
            <PEButton element_id="transformationedit_newfield"
              className="add_view_field_button"
              onClick={handleAddField}
              type="primary"
            >
              New Field <PlusOutlined />
            </PEButton>
          </Col>
          <Col>
            <PEButton element_id="transformationedit_save"
              className="add_view_field_button"
              onClick={handleSave}
              type="primary"
            >
              Save
            </PEButton>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const FieldMappingForm = ({
  config,
  setConfig,
  deleteConfig,
  fieldlist,
  fieldListCounts,
  valueType,
}) => {
  const [form] = Form.useForm();
  const [sequence, setSequence] = useState(config.mappingSequence);
  const [splitString, setSplitString] = useState(config.split_string);

  useEffect(() => {
    setSequence(config.mappingSequence);
  }, [config.mappingSequence]);
  const _config = { ...defaultConfig, ...config };

  const valueChangeHandler = (changedValues, allValues) => {
    if (changedValues["NDFields"]) {
      fieldListCounts[_config["NDFields"]] -= 1;
      fieldListCounts[changedValues["NDFields"]] += 1;
      allValues["mappingSequence"] = fieldListCounts[changedValues["NDFields"]];
      // setSequence(allValues['mappingSequence']);
    }
    if (changedValues['globalMappingSequence']) {
      allValues['mappingSequenceTimestamp'] = Date.now()
    }
    if (!allValues.toupper) {
      delete allValues.toupper;
    }
    if (!allValues.split_string) {
      delete allValues.split_string;
      delete allValues.split_character_count;
    }
    console.log(allValues)
    // console.log(_config.mappingSequence);
    setConfig({ ...config, ...allValues });
  };

  const deleteHandler = () => {
    fieldListCounts[_config["NDFields"]] -= 1;
    deleteConfig();
  };

  const yesnoOptions = [
    { option: "Yes", value: "1" },
    { option: "No", value: "0" },
  ];

  const yesnoBooleanOptions = [
    { option: "Yes", value: true },
    { option: "No", value: false },
  ];


  return (
    <Form
      className="view_field_form"
      layout="vertical"
      form={form}
      onValuesChange={valueChangeHandler}
    >
      <Row gutter={[24, 16]}>
        <Col span={5}>
          <Form.Item
            label="Select Destination Field"
            name="NDFields"
            initialValue={_config.NDFields || ""}
          >
            <Select
              showSearch
            >
              <Option value="" disabled>
                Select
              </Option>
              {fieldlist.map((fieldname) => (
                <Option key={fieldname} value={fieldname}>
                  {fieldname}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Vendor Default Value"
            name="ven_defaultval"
            initialValue={config.ven_defaultval || ""}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Value Transformation"
            name="isTransform"
            initialValue={config.isTransform || ""}
          >
            <Select
            >
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Capitalize"
            name="toupper"
            initialValue={config.toupper || false}
          >
            <Select
            >
              <Option value="" disabled>
                Select
              </Option>
              {yesnoBooleanOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="String Split"
            name="split_string"
            initialValue={config.split_string || false}
          >
            <Select onChange={(value) => { value === true ? setSplitString(true) : setSplitString(false) }}>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoBooleanOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {
          splitString &&
          <Col span={3}>
            <Form.Item
              label="Max characters per line"
              name="split_character_count"
              initialValue={config.split_character_count || 1}
            >
              <InputNumber min={1} />
            </Form.Item>
          </Col>
        }
      </Row>
      <Row>
        <Col span={3}>
          <Form.Item
            label="Mapping Sequence"
            name="globalMappingSequence"
            initialValue={config.globalMappingSequence || 9999}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
      </Row>
      <div className="transformation_mapping_config_count">
        <span>{sequence}</span>
      </div>
      <PEButton element_id="transformationedit_delete"
        className="delete_view_field"
        type="primary"
        danger
        onClick={deleteHandler}
      >
        <DeleteFilled />
      </PEButton>
    </Form>
  );
};

const CustomLabelRenderer = ({
  path,
  keyName,
  type,
  expanded,
  conditions,
  updateCondition,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newConditions, setNewConditions] = useState(conditions);

  const showModal = (e) => {
    e.stopPropagation();
    if (keyName != "__default__") setIsModalVisible(true);
  };

  const handleCancel = () => {
    setNewConditions(conditions);
    setIsModalVisible(false);
  };

  const handleFormSubmit = () => {
    updateCondition(path, newConditions);
    setIsModalVisible(false);
  };

  const updateConfig = (config, index) => {
    const _newConditions = [...newConditions];
    _newConditions.splice(index, 1, config);
    setNewConditions(_newConditions);
  };

  const deleteConfig = (index) => {
    const _newConditions = [...newConditions];
    _newConditions.splice(index, 1);
    setNewConditions(_newConditions);
  };

  const handleAddField = () => {
    const _newConditions = [...newConditions];
    _newConditions.push({
      ...defaultConditionsConfig,
      field_name: getPathString(path),
    });
    setNewConditions(_newConditions);
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <span onClick={showModal} style={{ cursor: "pointer", position: 'relative' }}>
        <span style={{ position: 'absolute', left: '-10px' }}>{newConditions.length > 0 ? "(c)" : ""} </span>
        {keyName}
      </span>

      <Modal
        title="Add Mapping Condition"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {newConditions.map((c, index) => {
          return (
            <div key={c.field_value}>
              <ConditionsForm
                condition={c}
                setConfig={(newCondition) => {
                  updateConfig(newCondition, index);
                }}
                deleteConfig={() => {
                  deleteConfig(index);
                }}
              />
            </div>
          );
        })}

        <Row justify="center" align="middle" gutter={8}>
          <Col>
            <PEButton element_id="transformationedit_newcondition"
              className="add_view_field_button"
              onClick={handleAddField}
              type="primary"
            >
              New Condition
              <PlusOutlined />
            </PEButton>
          </Col>
          <Col>
            <PEButton element_id="transformationedit_save"
              className="add_view_field_button"
              onClick={handleFormSubmit}
              type="primary"
            >
              Save
            </PEButton>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const ConditionsForm = ({ condition, setConfig, deleteConfig, valueType }) => {
  const config = { ...defaultConditionsConfig, ...condition };
  const [form] = Form.useForm();
  const operatorOptions = [
    { option: "==", value: "==" },
    { option: "!=", value: "!=" },
  ];
  const conditionTypeOptions = [
    { option: "AND", value: "AND" },
    { option: "OR", value: "OR" },
  ];
  const yesnoOptions = [
    { option: "Yes", value: "1" },
    { option: "No", value: "0" },
  ];

  const valueChangeHandler = (changedValues, allValues) => {
    setConfig({ ...config, ...allValues });
  };
  return (
    <Form
      form={form}
      onFinish={() => {
        console.log("submitted");
      }}
      onValuesChange={valueChangeHandler}
      layout="vertical"
    >
      <Row gutter={[24, 16]}>
        <Col span={5}>
          <Form.Item
            label="Field Name"
            name="field_name"
            initialValue={config.field_name || ""}
          >
            <Select
              disabled>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Field Value"
            name="field_value"
            initialValue={config.field_value || ""}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            label="Operator"
            name="operator"
            initialValue={config.operator || ""}
          >
            <Select
              showSearch
            >
              {operatorOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            label="Condition Type"
            name="condition_type"
            initialValue={config.condition_type || ""}
          >
            <Select

            >
              {conditionTypeOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={2}>
          <PEButton element_id="transformationedit_deletefield"
            className="delete_view_field"
            type="primary"
            danger
            onClick={deleteConfig}
          >
            <DeleteFilled />
          </PEButton>
        </Col>
      </Row>
    </Form>
  );
};

function createNestedJson(objectsList) {
  const result = {};
  function getConfigString(obj, value) {
    if (
      (typeof value === "object" && Object.keys(value).length === 0) ||
      value == undefined
    ) {
      value = "[]";
    }
    const config = { ...obj };
    // delete config['BucketField'];
    let x = 10;

    x = JSON.parse(value);

    return JSON.stringify([...x, config]);
  }
  objectsList.forEach((obj) => {
    const keys = obj.VendorFields.split(".");
    let currentLevel = result;

    keys.forEach((key, index) => {
      if (key.includes(":")) {
        const nestedKeys = key.split(":");
        nestedKeys.forEach((currentKey, nestedIndex) => {
          if (nestedIndex === nestedKeys.length - 1) {
            if (index != keys.length - 1) {
              if (!currentLevel[currentKey]) {
                currentLevel[currentKey] = {};
              }
            } else {
              currentLevel[currentKey] = getConfigString(
                obj,
                currentLevel[currentKey]
              );
            }
            currentLevel = currentLevel[currentKey];
          } else {
            currentLevel[currentKey] = currentLevel[currentKey] || [];
            currentLevel = currentLevel[currentKey];
          }
        });
      } else {
        if (!currentLevel[key]) {
          currentLevel[key] = {};
        }

        if (index === keys.length - 1) {
          currentLevel[key] = getConfigString(obj, currentLevel[key]);
        } else {
          currentLevel = currentLevel[key];
        }
      }
    });
  });

  return result;
}

function setLeafNodes(obj, value) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      setLeafNodes(obj[key], value);
    } else {
      obj[key] = value;
    }
  }
}

function getPathString(path) {
  return [...path].reverse().reduce((str, value, index) => {
    str += value;
    if (typeof value == "string" && index != path.length - 1) {
      str += ".";
    } else if (typeof value == "number" && index != path.length - 1) {
      str += ":";
    }
    return str;
  }, "");
}

function getObjectPath(bucketField) {
  const _path = bucketField.split(".");
  const path = _path.reduce((pathArr, key) => {
    if (key.includes(":")) {
      key.split(":").forEach((item, index) => {
        if (index == 0) pathArr.push(item);
        else pathArr.push(parseInt(item));
      });
    } else {
      pathArr.push(key);
    }
    return pathArr;
  }, []);
  return path;
}

export const MappingEditor = ({
  event_direction,
  ndfield_list,
  vendorfield_list,
  payloadMappings,
  conditions,
  storeOnSource,
  onChange,
}) => {
  let sourceFieldList = vendorfield_list;
  let destFieldList = ndfield_list;
  let mappings = payloadMappings;

  useEffect(() => {
  }, [payloadMappings, conditions, storeOnSource]);

  if (event_direction == "O") {
    sourceFieldList = ndfield_list;
    destFieldList = vendorfield_list;
    mappings = payloadMappings.reduce((obj, entry) => {
      obj.push({
        ...entry,
        VendorFields: entry["NDFields"],
        NDFields: entry["VendorFields"],
      });
      return obj;
    }, []);
  }

  mappings.forEach((obj, index) => {
    obj['globalMappingSequence'] = index + 1;
  });

  const onChangeHandler = ({ payloadMappings, conditions }) => {
    if (!onChange) {
      return;
    }
    if (event_direction === "O") {
      payloadMappings = payloadMappings.reduce((obj, entry) => {
        obj.push({
          ...entry,
          VendorFields: entry["NDFields"],
          NDFields: entry["VendorFields"],
        });
        return obj;
      }, []);
    }


    let attributeName = 'globalMappingSequence';
    let timestampAttributeName = 'mappingSequenceTimestamp';

    payloadMappings.sort((a, b) => {
      if (a[attributeName] !== b[attributeName]) {
        return a[attributeName] - b[attributeName];
      } else {
        const timestampA = a[timestampAttributeName] || 0;
        const timestampB = b[timestampAttributeName] || 0;
        return timestampB - timestampA;
      }

    });

    payloadMappings = payloadMappings.reduce((acc, obj) => {
      const { [timestampAttributeName]: timestamp, [attributeName]: mappingSequence, ...rest } = obj;
      acc.push(rest);
      return acc;
    }, []);
    onChange({ payloadMappings, conditions });
  };

  return (
    <TransformationMappingEditor
      payloadMappings={mappings}
      sourcePayload={sourceFieldList}
      destPayload={destFieldList}
      conditions={conditions}
      storeOnSource={storeOnSource}
      onChange={onChangeHandler}
    />
  );
};

import EditableJsonTree from "./JsonEditor";
import React, { useEffect, useRef, useState } from "react";
import { linearize, getObjectDifference } from "./Update";
import useAxios from "../hooks/useAxios";

import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  InputNumber,
  Checkbox,
} from "antd";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { PEButton } from "./permissionComponents/index";

const _ = require("lodash");

const { Option } = Select;

const defaultConfig = {
  fieldname: "",
  isEdit: "0",
  isRequired: "0",
  isVisible: "1",
  defaultfilter: "",
  addapi: "0",
  datatype: null,
  tablesource: "",
  showLabel: "1",
  dropdownlist: "",
  Downloadfile: "",
  isSearch: "0",
  productlist: "",
  productid: "",
  massupdate: "0",
  isConfirm: "0",
  isEditDisable: "0",
  DisableOnFieldValue: "",
  isRedirect: "0",
  FileType: "",
  FileSize: "",
  FilePath: "",
  FileName: "",
};

export default function FieldMappingEditor({
  referenceJson,
  field_mapping,
  onChange,
  apidata,
  viewlist,
  dropdowndata,
  view_type,
}) {
  const [data, setData] = useState({});
  const [blockFieldMappings, setBlockFieldMappings] = useState({});
  const [leafFieldMappings, setLeafFieldMappings] = useState([]);
  const [fieldlist, setFieldlist] = useState([]);

  useEffect(() => {
    field_mapping.forEach((obj, index) => {
      obj["globalMappingSequence"] = index + 1;
    });
    // filter block and jsonData types
    let _blockFieldMappings = field_mapping.filter((mapping) => {
      return (
        mapping["datatype"] === "block" || mapping["datatype"] === "jsondata"
      );
    });

    // removing field_mappings with block Configs
    const leaf_field_mapping = field_mapping.filter((mapping) => {
      // return true
      return !(
        mapping["datatype"] === "block" || mapping["datatype"] === "jsondata"
      );
    });
    setLeafFieldMappings(leaf_field_mapping);

    // create nested object with configs as values
    const nestedConfig = createNestedJson(leaf_field_mapping);

    // initialize leaf nodes as empty objects for reference Json
    setLeafNodes(referenceJson, "[]");

    // merge the existing configs with referenceJson
    const mergedObj = _.mergeWith(referenceJson, nestedConfig);

    // to set the data type of the configs to object, once nested fields are found.
    try {
      _blockFieldMappings.forEach((mapping) => {
        const path = getObjectPath(mapping["BucketField"]);
        let currentLevel = mergedObj;
        for (let i = 0; i < path.length; i++) {
          if (i == path.length - 1) {
            if (currentLevel[path[i]]) {
              continue;
            } else {
              currentLevel[path[i]] = {};
              break;
            }
          } else {
            currentLevel = currentLevel[path[i]];
          }
        }
      });

      _blockFieldMappings = _blockFieldMappings.reduce((obj, value) => {
        if (!obj[value["BucketField"]]) {
          obj[value["BucketField"]] = [];
        }
        obj[value["BucketField"]].push(value);
        return obj;
      }, {});

      setData(mergedObj);
      setBlockFieldMappings({ ..._blockFieldMappings });
      setFieldlist(field_mapping);
    } catch (e) {
      console.log(e);
    }
  }, [referenceJson, field_mapping]);

  // onChange event to return the linearized field mapping
  const createFieldMapping = (value) => {
    const linearConfig = linearize(value);
    const fieldMapping = Object.keys(linearConfig).reduce((obj, key) => {
      const configs = JSON.parse(linearConfig[key]);
      configs.forEach((config) => {
        obj.push({
          ...config,
          BucketField: key,
        });
      });

      return obj;
    }, []);
    return fieldMapping;
  };

  const onChangeWithSequence = (new_field_mapping) => {
    let attributeName = "globalMappingSequence";
    let timestampAttributeName = "mappingSequenceTimestamp";

    new_field_mapping.sort((a, b) => {
      if (a[attributeName] !== b[attributeName]) {
        return a[attributeName] - b[attributeName];
      } else {
        const timestampA = a[timestampAttributeName] || 0;
        const timestampB = b[timestampAttributeName] || 0;
        return timestampB - timestampA;
      }
    });

    new_field_mapping = new_field_mapping.reduce((acc, obj) => {
      const {
        [timestampAttributeName]: timestamp,
        [attributeName]: mappingSequence,
        ...rest
      } = obj;
      acc.push(rest);
      return acc;
    }, []);

    onChange(new_field_mapping);
  };

  const onChangeHandler = (value) => {
    const _fieldlist = createFieldMapping(value);
    setLeafFieldMappings(_fieldlist);
    const new_field_mapping = createNewFieldMapping(
      _fieldlist,
      blockFieldMappings
    );
    setFieldlist(new_field_mapping);
    onChangeWithSequence(new_field_mapping);
  };

  // handler to save the config of block types
  const saveBlockConfig = (path, values) => {
    const bucketFieldString = getBucketFieldString(path);
    blockFieldMappings[bucketFieldString] = values;
    const new_field_mapping = createNewFieldMapping(
      leafFieldMappings,
      blockFieldMappings
    );
    setFieldlist(new_field_mapping);
    onChangeWithSequence(new_field_mapping);
  };

  const createNewFieldMapping = (fieldMappings, blockFieldMappings) => {
    // console.log(fieldMappings,blockFieldMappings)
    const new_field_mappings = [...fieldMappings];
    Object.keys(blockFieldMappings).forEach((bucketField) => {
      new_field_mappings.push(...blockFieldMappings[bucketField]);
    });
    return new_field_mappings;
  };

  // Handler to show only assigned fields and unassigned fields and all fields.
  const handleFilterSelect = (values) => {};

  // ValueRenderer for field mapping
  const filterOptions = [
    { option: "All fields", value: 0 },
    { option: "Mapped fields", value: 1 },
  ];

  return (
    <div className="view_field_mapping_editor">
      {/* <div className="d-flex justify-content-between pl-2">
            <div></div>
            <Select style={{width:'150px'}} onChange={handleFilterSelect} defaultValue={0}>
                {
                    filterOptions.map(({option,value}) => (
                        <Option key={option} value={value}>
                            {option}
                        </Option>
                    ))
                }
            </Select>
            
        </div> */}
      <EditableJsonTree
        editEnabled={false}
        data={data}
        onChange={onChangeHandler}
        ValueRenderer={({ path, jsonConfig, onFormSubmit, valueType }) => {
          return (
            <ConfigFormRenderer
              path={path}
              jsonConfig={jsonConfig}
              onFormSubmit={onFormSubmit}
              apidata={apidata}
              fieldlist={fieldlist}
              valueType={valueType}
              dropdownlist={dropdowndata}
              viewlist={viewlist}
              view_type={view_type}
            />
          );
        }}
        NestedValueRenderer={({
          path,
          valueType,
          value,
          itemString,
          itemType,
        }) => {
          const bucketFieldString = getBucketFieldString(path);

          const jsonConfig = JSON.stringify(
            blockFieldMappings[bucketFieldString]
              ? blockFieldMappings[bucketFieldString]
              : []
          );

          return (
            <ConfigFormRenderer
              path={path}
              jsonConfig={jsonConfig}
              onFormSubmit={saveBlockConfig}
              apidata={apidata}
              fieldlist={fieldlist}
              valueType={valueType}
              dropdownlist={dropdowndata}
              viewlist={viewlist}
              itemType={itemType}
              view_type={view_type}
            />
          );
        }}
      />
    </div>
  );
}

const ConfigFormRenderer = ({
  path,
  jsonConfig,
  onFormSubmit,
  apidata,
  valueType,
  fieldlist,
  dropdownlist,
  viewlist,
  itemType,
  view_type,
}) => {
  const [loading, sendRequest] = useAxios();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [configs, setConfigs] = useState(JSON.parse(jsonConfig));
  const [dropdownResponse, setdropdownResponse] = useState();
  const [allRules, setallRules] = useState([]);

  useEffect(() => {
    const _configs = jsonConfig ? JSON.parse(jsonConfig) : [];
    _configs.forEach((config, index) => {
      config["index"] = index;
    });
    setConfigs(_configs);
  }, [jsonConfig]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setConfigs(JSON.parse(jsonConfig));
    setIsModalVisible(false);
  };

  const handleSave = (e) => {
    e.stopPropagation();

    onFormSubmit(
      path,
      configs.reduce((obj, config) => {
        const { index: index, ...rest } = config;
        obj.push({ BucketField: getBucketFieldString(path), ...rest });
        return obj;
      }, [])
    );
    setIsModalVisible(false);
  };

  const fieldNames = configs.reduce((obj, config) => {
    obj.push(config["fieldname"]);
    return obj;
  }, []);

  // getBucketFieldString

  const updateConfig = (config, index) => {
    const _configs = [...configs];
    config["index"] = index;
    _configs.splice(index, 1, config);
    setConfigs(_configs);
  };

  const deleteConfig = (index) => {
    const _configs = [...configs];
    _configs.splice(index, 1);
    setConfigs(_configs);
  };

  const handleAddField = () => {
    const _configs = [...configs];
    defaultConfig["index"] = _configs.length;
    _configs.push(defaultConfig);
    setConfigs(_configs);
  };
  const filteroutput = (dropdownlistid) => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/dropdown/item/list?dropdown_id=` +
          dropdownlistid,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        // const transformedData = response.map((item) => ({
        //   label: item.item_name,
        //   value: item.item_value,
        // }));
        // console.log("dropdownlist", transformedData);
        setdropdownResponse(response);
      }
    );
    if (fieldlist[0].search_query) {
      const allRules = extractAllRules(fieldlist[0].search_query.rules);
      const groupedData = Object.values(groupData(allRules));
      console.log("ConfigFormRenderer", groupedData);
      setallRules(groupedData);
    }
  };
  const groupData = (data) => {
    return data.reduce((acc, item) => {
      const field = item.field;
      if (!acc[field]) {
        acc[field] = { field, values: [] };
      }
      acc[field].values.push(item.value);
      return acc;
    }, {});
  };
  return (
    <>
      <div
        style={{ display: "inline" }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <span onClick={showModal} style={{ cursor: "pointer" }}>
          [{" "}
          {configs.map((config, index) => {
            return (
              <span key={config["fieldname"]}>
                {config["fieldname"]}
                <span style={{ color: "grey" }}>
                  {" "}
                  ({config["globalMappingSequence"]})
                </span>
                {configs.length - 1 != index ? ", " : ""}
              </span>
            );
          })}{" "}
          ]
        </span>
        <Modal
          title="Fields"
          open={isModalVisible}
          onCancel={handleCancel}
          width={"100%"}
          height={"80%"}
          footer={null}
        >
          {configs.map((c,index) => {
            return (
              <div className="view_field_form_container" key={c.fieldname}>
                <FieldMappingForm
                  config={c}
                  fieldlist={fieldlist}
                  apidata={apidata}
                  dropdownlist={dropdownlist}
                  viewlist={viewlist}
                  setConfig={(newConfig) => {
                    updateConfig(newConfig, index);
                  }}
                  deleteConfig={() => {
                    deleteConfig(index);
                  }}
                  filteroutput={(e) => {
                    filteroutput(e);
                  }}
                  valueType={valueType}
                  path={path}
                  dropdownResponse={dropdownResponse}
                  allRules={allRules}
                  view_type={view_type}
                />
              </div>
            );
          })}
          <Row justify="center" align="middle" gutter={8}>
            <Col>
              <PEButton element_id="createview_newfield"
                className="add_view_field_button"
                onClick={handleAddField}
                type="primary"
              >
                New Field <PlusOutlined />
              </PEButton>
            </Col>
            <Col>
              <PEButton element_id="createview_save"
                className="add_view_field_button"
                onClick={handleSave}
                type="primary"
              >
                Save
              </PEButton>
            </Col>
          </Row>
        </Modal>
      </div>
    </>
  );
};
const extractAllRules = (rules) => {
  let result = [];

  rules.forEach((rule) => {
    if (rule.rules) {
      // Recursively handle nested rules
      result = result.concat(extractAllRules(rule.rules));
    } else {
      result.push({
        field: rule.field,
        operator: rule.operator,
        value: rule.value,
      });
    }
  });

  return result;
};
const FieldMappingForm = ({
  config,
  setConfig,
  deleteConfig,
  filteroutput,
  fieldlist,
  apidata,
  dropdownlist,
  viewlist,
  valueType,
  path,
  dropdownResponse,
  allRules,
  view_type,
}) => {
  const [form] = Form.useForm();
  config = { ...defaultConfig, ...config };
  const [editDisableConfig, setEditDisableConfig] = useState(
    config.isEditDisable
  );
  const [addApiConfig, setAddApiConfig] = useState(config.addapi);
  const [datatypeConfig, setDatatypeConfig] = useState(config.datatype);
  const [redirectConfig, setRedirectConfig] = useState(config.isRedirect);

  const yesnoOptions = [
    { option: "Yes", value: "1" },
    { option: "No", value: "0" },
  ];
  const aggregateOptions = [
    { option: "Aggregate Data", value: "AD" },
    { option: "Group By", value: "GD" },
    { option: "First Row", value: "FR" },
    { option: "Hide Data", value: "HD" },
  ];

  const dropdown_Condition = [{ option: "Check All Checkbox", value: "1" }];
  const dropDownOptions = [
    { option: "Show Value", value: "V" },
    { option: "Show Label", value: "L" },
    { option: "Show Both", value: "B" },
  ];
  const defaultfilterOptions = [
    { option: "contextuser_id", value: "==.contextuser_id" },
    {
      option: "contextuser_branchid",
      value: "array_contains_any.contextuser_branchid",
    },
  ];
  const datatypeOptions = [
    { option: "Textbox", value: "textbox" },
    { option: "Date", value: "date" },
    { option: "Boolean", value: "boolean" },
    { option: "Image", value: "image" },
    { option: "Dropdown List", value: "dropdownlist" },
    { option: "INT", value: "int" },
    { option: "Float", value: "float" },
    { option: "Sub View", value: "sub_view" },
    { option: "Download", value: "Downloadfile" },
    { option: "Group ID", value: "group_id" },
    { option: "Get Product List", value: "productlist" },
    { option: "Uploadfile", value: "Uploadfile" },
    { option: "Select", value: "selectoption" },
    { option: "Block", value: "block" },
    { option: "JSON", value: "jsondata" },
  ];

  let field_path = getBucketFieldString(path);
  let isArray = 0;
  if (field_path.indexOf(":") !== -1) {
    isArray = 1;
  } else {
    isArray = 0;
  }

  if (valueType == "string") {
    datatypeOptions.splice(datatypeOptions.length - 2, 2);
  } else {
    datatypeOptions.splice(0, datatypeOptions.length - 2);
  }

  const valueChangeHandler = (changedValues, allValues) => {
    if (changedValues["globalMappingSequence"]) {
      allValues["mappingSequenceTimestamp"] = Date.now();
    }

    console.log("allValues", allValues);
    setConfig({ ...config, ...allValues });
  };

  const handleCheckboxChange = (e, itemId) => {
    const value = e.target.value;
    const dropdowncondition = config.dropdowncondition || [];
    const checked = e.target.checked;

    // Check if the item is already in the dropdowncondition
    const existingSelection = dropdowncondition.find(
      (item) => item.filtereddropdownlist === itemId
    );

    if (checked) {
      if (existingSelection) {
        // Update the existing item
        const updatedSelections = {
          ...config,
          dropdowncondition: dropdowncondition.map((item) =>
            item.filtereddropdownlist === itemId
              ? { ...item, filtereddropdownlist: value }
              : item
          ),
        };
        setConfig(updatedSelections);
      } else {
        // Add new item
        const updatedSelections = {
          ...config,
          dropdowncondition: [
            ...dropdowncondition,
            { filtereddropdownlist: value, filteredCondition: null },
          ],
        };
        console.log("ConditionChange_", updatedSelections);

        setConfig(updatedSelections);
      }
    } else {
      const updatedSelections = {
        ...config,
        dropdowncondition: dropdowncondition.filter(
          (item) => item.filtereddropdownlist !== value
        ),
      };
      console.log("ConditionChange_1", updatedSelections);
      setConfig(updatedSelections);
    }
  };

  const handleConditionChange = (value, itemId) => {
    const dropdowncondition = config.dropdowncondition || [];

    const updatedSelections = {
      ...config,
      dropdowncondition: dropdowncondition.map((item) =>
        item.filtereddropdownlist === itemId
          ? { ...item, filteredCondition: value }
          : { filteredCondition: value }
      ),
    };
    console.log("ConditionChange", value);

    setConfig(updatedSelections);
  };
  const handleMassValidationKey = (value, itemId) => {
    const dropdowncondition = config.dropdowncondition || [];

    const updatedSelections = {
      ...config,
      dropdowncondition: dropdowncondition.map((item) =>
        item.filtereddropdownlist === itemId
          ? { ...item, massValidationKey: value }
          : { massValidationKey: value }
      ),
    };
    console.log("handleMassValidationKey", value);

    setConfig(updatedSelections);
  };
  const handleMassValidationvalue = (value, itemId) => {
    const dropdowncondition = config.dropdowncondition || [];

    const updatedSelections = {
      ...config,
      dropdowncondition: dropdowncondition.map((item) =>
        item.filtereddropdownlist === itemId
          ? { ...item, massValidationvalue: value }
          : { massValidationvalue: value }
      ),
    };
    console.log("handleMassValidationKey", value);

    setConfig(updatedSelections);
  };

  return (
    <Form
      className="view_field_form"
      layout="vertical"
      form={form}
      onValuesChange={valueChangeHandler}
    >
      <Row gutter={[24, 16]}>
        <Col span={6}>
          <Form.Item
            label="Field Name"
            name="fieldname"
            initialValue={config.fieldname}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Can Edit"
            name="isEdit"
            initialValue={config.isEdit || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="is Required"
            name="isRequired"
            initialValue={config.isRequired || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="is Visible"
            name="isVisible"
            initialValue={config.isVisible || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="is Search"
            name="isSearch"
            initialValue={config.isSearch || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="show Label?"
            name="showLabel"
            initialValue={config.showLabel || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Default Filter"
            name="defaultfilter"
            initialValue={config.defaultfilter || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {defaultfilterOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Mass Update"
            name="massupdate"
            initialValue={config.massupdate || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {config.massupdate == 1 && (
          <>
            <Col span={3}>
              <Form.Item
                label="Show Condition"
                name="showcondition"
                initialValue={config.showcondition || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Is Required?"
                name="massrequire"
                initialValue={config.massrequire || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {config.showcondition == 1 && (
              <>
                <Col span={3}>
                  <Form.Item
                    label="Select Field"
                    name="mass_condition_field"
                    initialValue={config.mass_condition_field || ""}
                  >
                    <Select showSearch>
                      <Option value="" disabled>
                        Select
                      </Option>
                      {fieldlist.map(({ BucketField, fieldname }) => (
                        <Option key={fieldname} value={BucketField}>
                          {fieldname}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item
                    label="Add Condition"
                    name="mass_condition_value"
                    initialValue={config.mass_condition_value}
                    placeholder="10,20,30"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={3}>
              <Form.Item
                label="Use As Input"
                name="useasinput"
                initialValue={config.useasinput || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={3}>
          <Form.Item
            label="Add API?"
            name="addapi"
            initialValue={config.addapi || ""}
          >
            <Select onChange={(value) => setAddApiConfig(value)}>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {addApiConfig == "1" && (
          <Col span={4}>
            <Form.Item
              label="Select API"
              name="selectapi"
              initialValue={config.selectapi}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {apidata.map(({ action_name }) => (
                  <Option key={action_name} value={action_name}>
                    {action_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={3}>
          <Form.Item
            label="Auto Generate txn No"
            name="autogeneratetxn"
            initialValue={config.autogeneratetxn || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Add to SUM"
            name="addtosum"
            initialValue={config.addtosum || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {config.addtosum && (
          <Col span={3}>
            <Form.Item
              label="Select Field"
              name="sumFieldlist"
              initialValue={config.sumFieldlist || ""}
            >
              <Select>
                <Option value="" disabled>
                  Select
                </Option>
                {fieldlist.map(({ BucketField, fieldname }) => (
                  <Option key={fieldname} value={BucketField}>
                    {fieldname}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={4}>
          <Form.Item
            label="Require Confirmation?"
            name="isConfirm"
            initialValue={config.isConfirm || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Disable Edit"
            name="isEditDisable"
            initialValue={config.isEditDisable || ""}
          >
            <Select onChange={(value) => setEditDisableConfig(value)}>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {editDisableConfig == "1" && (
          <Col span={6}>
            <Form.Item
              label="Disable Value(bucket field operator value)"
              name="DisableOnFieldValue"
              initialValue={config.DisableOnFieldValue}
              placeholder="payload.status>40"
            >
              <Input />
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 16]}>
        <Col span={5}>
          <Form.Item
            label="Data Type"
            name="datatype"
            initialValue={config.datatype || datatypeOptions[0].value}
          >
            <Select onChange={(value) => setDatatypeConfig(value)}>
              <Option value="" disabled>
                Select
              </Option>
              {datatypeOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {datatypeConfig == "selectoption" && (
          <>
            <Col span={4}>
              <Form.Item
                label="Select API"
                name="selectoption"
                initialValue={config.selectoption}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {apidata.map(({ action_name }) => (
                    <Option key={action_name} value={action_name}>
                      {action_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        {datatypeConfig == "productlist" && (
          <>
            <Col span={5}>
              <Form.Item
                label="Save Product ID"
                name="productid"
                initialValue={config.productid || ""}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {fieldlist.map(({ BucketField, fieldname }) => (
                    <Option key={fieldname} value={BucketField}>
                      {fieldname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Save Product Amount"
                name="productlist"
                initialValue={config.productlist || ""}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {fieldlist.map(({ BucketField, fieldname }) => (
                    <Option key={fieldname} value={BucketField}>
                      {fieldname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Save Product Tenure"
                name="tenure"
                initialValue={config.tenure || ""}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {fieldlist.map(({ BucketField, fieldname }) => (
                    <Option key={fieldname} value={BucketField}>
                      {fieldname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        {datatypeConfig == "sub_view" && (
          <Col span={5}>
            <Form.Item
              label="Select View"
              name="exprow"
              initialValue={config.exprow || ""}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {viewlist.map(({ view_id, view_name }) => (
                  <Option key={view_id} value={view_id}>
                    {view_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {datatypeConfig == "dropdownlist" && (
          <>
            <Col span={5}>
              <Form.Item
                label="dropdownlist"
                name="dropdownlist"
                initialValue={config.dropdownlist || ""}
              >
                <Select showSearch>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {dropdownlist.map(({ dropdown_id, dropdown_name }) => (
                    <Option key={dropdown_id} value={dropdown_id}>
                      {dropdown_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button
                className="delete_view_field"
                type="primary"
                disabled={!config.dropdownlist}
                onClick={() => filteroutput(config.dropdownlist)}
              >
                Config Output
              </Button>
            </Col>
            <Col span={3}>
              <Form.Item
                label="Show Dropdown Options"
                name="dropDownOptions"
                initialValue={config.dropDownOptions || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {dropDownOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </>
        )}
        {datatypeConfig == "Uploadfile" && (
          <>
            <Row gutter={[24, 16]}>
              <Col span={5}>
                <Form.Item
                  label="File Type"
                  name="UploadType"
                  initialValue={config.UploadType}
                >
                  <Input placeholder="PDF,JPG,XLSX" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="File Size (In MB)"
                  name="UploadSize"
                  initialValue={config.UploadSize}
                >
                  <Input placeholder="2" />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="File Path"
                  name="UploadPath"
                  initialValue={config.UploadPath}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label="File Name"
                  name="UploadName"
                  initialValue={config.UploadName}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
        <Col span={3}>
          <Form.Item
            label="Redirect"
            name="isRedirect"
            initialValue={config.isRedirect || ""}
          >
            <Select onChange={(value) => setRedirectConfig(value)}>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {redirectConfig == "1" && (
          <>
            <Col span={5}>
              <Form.Item
                label={`use {"{{ fieldname }}"}`}
                name="RedirectData"
                type="textarea"
                initialValue={config.RedirectData}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                label="Add Redirect Condition"
                name="RedirectCondition"
                initialValue={config.RedirectCondition}
              >
                <Input />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={3}>
          <Form.Item
            label="EditinPopup"
            name="EditinPopup"
            initialValue={config.EditinPopup || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {isArray == 1 && (
          <>
            <Col span={4}>
              <Form.Item
                label="Pick Value From Array"
                name="picklast"
                initialValue={config.picklast || ""}
              >
                <Select>
                  <Option value="" disabled>
                    Select
                  </Option>
                  {yesnoOptions.map(({ option, value }) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {config.picklast == 1 && (
              <Col span={3}>
                <Form.Item
                  label="Array Index"
                  name="pickindex"
                  initialValue={config.pickindex || ""}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}
          </>
        )}
        <Col span={5}>
          <Form.Item
            label="Field Length"
            name="FieldLength"
            initialValue={config.FieldLength || null}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item
            label="Use as Add Data Before"
            name="add_data_before"
            initialValue={config.add_data_before || ""}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {dropdownResponse && (
        <Row gutter={[24, 16]}>
          <Col span={24}>
            {/* <Form.Item
                  name="filtereddropdownlist"
                  initialValue={config.filtereddropdownlist || ""}
                >
                  <Checkbox.Group
                    options={dropdownResponse}
                    //onChange={onChange}
                    defaultValue={config.filtereddropdownlist || ""}
                  />
                </Form.Item> */}
            <table className="table">
              <thead>
                <th>#</th>
                <th>Mass Condition</th>
                <th>Validation to Stop Mass Processing</th>
              </thead>
              <tbody>
                {dropdownResponse &&
                  dropdownResponse.map((showdata) => {
                    const dropdowncondition = config.dropdowncondition || [];
                    const existingSelection = dropdowncondition.find(
                      (item) =>
                        item.filtereddropdownlist === showdata.item_value
                    );

                    return (
                      <tr key={showdata.item_id}>
                        <td>
                          <Checkbox
                            value={showdata.item_value}
                            checked={!!existingSelection}
                            onChange={(e) =>
                              handleCheckboxChange(e, showdata.item_value)
                            }
                          >
                            {showdata.item_name}
                          </Checkbox>
                        </td>
                        <td>
                          <Select
                            onChange={(value) =>
                              handleConditionChange(value, showdata.item_value)
                            }
                            value={
                              existingSelection
                                ? existingSelection.filteredCondition
                                : undefined
                            }
                            placeholder="Select"
                          >
                            {dropdown_Condition.map(({ option, value }) => (
                              <Option key={value} value={value}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        </td>
                        <td>
                          <Select
                            onChange={(value) =>
                              handleMassValidationKey(
                                value,
                                showdata.item_value
                              )
                            }
                            value={
                              existingSelection
                                ? existingSelection.massValidationKey
                                : undefined
                            }
                            placeholder="Select"
                          >
                            {allRules.map(({ field }) => (
                              <Option key={field} value={field}>
                                {field}
                              </Option>
                            ))}
                          </Select>
                          <Select
                            mode="multiple"
                            onChange={(value) =>
                              handleMassValidationvalue(
                                value,
                                showdata.item_value
                              )
                            }
                            value={
                              existingSelection
                                ? existingSelection.massValidationvalue
                                : undefined
                            }
                            placeholder="Select"
                          >
                            {allRules.length > 0 &&
                              allRules[0].values.map((field) => (
                                <Option key={field} value={field}>
                                  {field}
                                </Option>
                              ))}
                          </Select>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Col>
        </Row>
      )}
      <Row gutter={[24, 16]}>
        <Col span={3}>
          <Form.Item
            label="Get From API"
            name="getfromapi"
            initialValue={config.getfromapi || "0"}
          >
            <Select>
              <Option value="" disabled>
                Select
              </Option>
              {yesnoOptions.map(({ option, value }) => (
                <Option key={option} value={value}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {config.getfromapi == 1 && (
          <Col span={5}>
            <Form.Item
              label="Select API"
              name="getdatafromapi"
              initialValue={config.getdatafromapi || ""}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {apidata.map(({ action_name }) => (
                  <Option key={action_name} value={action_name}>
                    {action_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={[24, 16]}>
        {view_type && view_type == "AV" && (
          <Col span={3}>
            <Form.Item
              label="Aggregate Column"
              name="aggregatecolumn"
              initialValue={config.aggregatecolumn || ""}
            >
              <Select showSearch>
                <Option value="" disabled>
                  Select
                </Option>
                {aggregateOptions.map(({ option, value }) => (
                  <Option key={option} value={value}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        <Col span={3}>
          <Form.Item
            label="Mapping Sequence"
            name="globalMappingSequence"
            initialValue={config.globalMappingSequence || 9999}
          >
            <InputNumber min={1} />
          </Form.Item>
        </Col>
      </Row>
      <PEButton element_id="createview_delete"
        className="delete_view_field"
        type="primary"
        danger
        onClick={deleteConfig}
      >
        <DeleteFilled />
      </PEButton>
    </Form>
  );
};

function createNestedJson(objectsList) {
  const result = {};
  function getConfigString(obj, value) {
    if (
      (typeof value === "object" && Object.keys(value).length === 0) ||
      value == undefined
    ) {
      value = "[]";
    }
    const config = { ...obj };
    // delete config['BucketField'];

    let x = JSON.parse(value);
    return JSON.stringify([...x, config]);
  }
  objectsList.forEach((obj) => {
    const keys = obj.BucketField.split(".");
    let currentLevel = result;

    keys.forEach((key, index) => {
      // console.log(key)
      if (key.includes(":")) {
        const nestedKeys = key.split(":");
        nestedKeys.forEach((currentKey, nestedIndex) => {
          if (nestedIndex === nestedKeys.length - 1) {
            if (index != keys.length - 1) {
              if (!currentLevel[currentKey]) {
                currentLevel[currentKey] = {};
              }
            } else {
              currentLevel[currentKey] = getConfigString(
                obj,
                currentLevel[currentKey]
              );
            }
            currentLevel = currentLevel[currentKey];
          } else {
            currentLevel[currentKey] = currentLevel[currentKey] || [];
            currentLevel = currentLevel[currentKey];
          }
        });
      } else {
        if (!currentLevel[key]) {
          currentLevel[key] = {};
        }

        if (index === keys.length - 1) {
          currentLevel[key] = getConfigString(obj, currentLevel[key]);
        } else {
          currentLevel = currentLevel[key];
        }
      }
    });
  });

  return result;
}

function setLeafNodes(obj, value) {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      setLeafNodes(obj[key], value);
    } else {
      obj[key] = value;
    }
  }
}

function getBucketFieldString(path) {
  return [...path].reverse().reduce((str, value, index) => {
    str += value;
    if (typeof value == "string" && index != path.length - 1) {
      str += ".";
    } else if (typeof value == "number" && index != path.length - 1) {
      str += ":";
    }
    return str;
  }, "");
}

function getObjectPath(bucketField) {
  const _path = bucketField.split(".");
  const path = _path.reduce((pathArr, key) => {
    if (key.includes(":")) {
      key.split(":").forEach((item, index) => {
        if (index == 0) pathArr.push(item);
        else pathArr.push(parseInt(item));
      });
    } else {
      pathArr.push(key);
    }
    return pathArr;
  }, []);
  return path;
}

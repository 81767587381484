import React from "react";
import { requestForToken } from "./firebase";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
//import Commonheader from "./components/Commonheader";
import Create_view from "./components/Create_view";
import View_list from "./components/View_list";
import { FilterContextProvider } from "./context/FilterToDepositSlip";
// AT-2018 | AT-2260 | Ankur Makavana | 03/08/2023 | create new screen for transformation
import Transformation from "./components/Transformation";
import Transformation_mapping from "./components/Transformation_mapping";
import Transformation_mapping_list from "./components/Transformation_mapping_list";
import ND_values from "./components/ND_values";
import "./assets/css/main.css";
import Vendor_specific_fields from "./components/Vendor_specific_fields";
import Nd_Fields from "./components/Nd_fields";
import Update from "./components/Update";
import Update_nd_values from "./components/Update_nd_values";
import Test from "./components/Test";
//import e from "./components/e";
import Element_permissions from "./components/Element_permissions";
import Cache_view_delete from "./components/Cache_view_delete";
import SwitchProdPilot from "./components/Switch";
import Performance_view from "./components/Performance_view";
import Dropdown_master from "./components/Dropdown_master";
import Unblock_user from "./components/Unblock_user";
import User_functionality from "./components/User_functionality";
import { PermissionsProvider } from "./components/permissionComponents/PermissionContext";

//import Element_permission from "./components/Element_permission"
require("dotenv").config();
//import Notification from './Notification';
function App() {
  requestForToken();
  return (
    <>
      <Router basename="/">
        <React.StrictMode>
          <PermissionsProvider>
            <FilterContextProvider>
              <Switch>
                <Route
                  exact={true}
                  path="/update_nd_value"
                  component={Update_nd_values}
                />
                <Route exact={true} path="/dashboard" component={Dashboard} />
                <Route exact={true} path="/View" component={Create_view} />
                <Route exact={true} path="/ViewList" component={View_list} />
                <Route
                  exact={true}
                  path="/Vendor_specific_fields"
                  component={Vendor_specific_fields}
                />
                <Route
                  exact={true}
                  path="/Vendor_specific_fields_update"
                  component={Update}
                />
                {/* <Route exact={true} path="/Nd_Fields" component={Nd_Fields} /> */}
                {/* AT-2018 | AT-2260 | Ankur Makavana | 03/08/2023 | create new screen for transformation */}
                <Route
                  exact={true}
                  path="/Transformation_mapping_list"
                  component={Transformation_mapping_list}
                />
                <Route
                  exact={true}
                  path="/Transformation"
                  component={Transformation}
                />
                <Route
                  exact={true}
                  path="/Transformation_mapping"
                  component={Transformation_mapping}
                />
                <Route exact={true} path="/ND_values" component={ND_values} />
                <Route exact={true} path="/Test" component={Test} />
                <Route
                  exact={true}
                  path="/Cache"
                  component={Cache_view_delete}
                />
                <Route
                  exact={true}
                  path="/Switch"
                  component={SwitchProdPilot}
                />
                <Route
                  exact={true}
                  path="/User_functionality"
                  component={User_functionality}
                />
                <Route
                  exact={true}
                  path="/Dropdown_master"
                  component={Dropdown_master}
                />
                <Route
                  exact={true}
                  path="/Unblock_user"
                  component={Unblock_user}
                />
                <Route
                  exact={true}
                  path="/Performance_view"
                  component={Performance_view}
                />
  
<Route
                exact={true}
                path="/Element_permission"
                component={Element_permissions}
              />



              <Route exact={true} path="/" component={Login} />
              </Switch>
            </FilterContextProvider>
          </PermissionsProvider>
        </React.StrictMode>
      </Router>
    </>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";

import "antd/dist/antd.css";

import { Modal, Form, Input, Select, Button} from 'antd';

import EditableJsonTree from "./JsonEditor";
import { PEButton, PEDiv } from "./permissionComponents/index";

const _ = require('lodash');
const { Option } = Select;

function Update(props) {
  const [loading, sendRequest] = useAxios();
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [btndisable, setBtndisable] = useState(true);
  const [vendor_id_list, setVendorlist] = useState();
  const [nd_id, setNd_id] = useState();
  let [ndName, setNd_name] = useState();
  let [eventList, seteventList] = useState();
  var [vendor_id, setVVendor_id] = useState();
  let [eventName, setEventName] = useState();
  let [eventLocation, setEventlocation] = useState();
  let [payloadfields, setPayloayfield] = useState([
    {
      payload_field: "",
      field_datatype: "",
      is_mandatory: "",
      status: "A",
      isdedupe: "no",
      dedupe_fields: [{ event_location: "", event_name: "", event_field: "" }],
    },
  ]);

  const [nestedPayloadFields,setNestedPayloadFields] = useState();
  const [updatedVendorPayload,setUpdatedVendorPayload] = useState(null);
  const [payloadFieldsDiff,setPayloadFieldsDiff] = useState(null);
  const [updatedPayloadFields,setUpdatedPayloadFields] = useState(null);

  useEffect(()=>{
    if(!updatedVendorPayload)
      return
    const result = generatePayloadField(updatedVendorPayload);
    let diff = getObjectDifference(payloadfields,result);

    if(diff){
      setBtndisable(false)
      setPayloadFieldsDiff(diff)
      setUpdatedPayloadFields(result)
    }
    else{
      setBtndisable(true)
    }
  },[updatedVendorPayload])

  let [vendorname, setVendorname] = useState();

  let { view_id } = props.location.state;

  const fieldlist =  [
    {
      vendor_id: "",
      event: "",
      event_location: "",
      payload_field: "",
    },
  ];
 

  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/all/vendor/payload/data?vendor_event_id=${view_id}`,
        method: "GET",
        // data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setVVendor_id(response[0].vendor_id);
        setEventName(response[0].event_name);
        setPayloayfield(response[0].payload_field);
        
        let nestedJson = createNestedJson(response[0].payload_field);
        setNestedPayloadFields(nestedJson);

        setVendorname(response[0].vendor_name);
        setEventlocation(response[0].event_location);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/vendor_id/list`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setVendorlist(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/nd/id`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setNd_id(response[0].vendor_id);
        setNd_name(response[0].vendor_name);
      }
    );
    if (vendor_id) {
      sendRequest(
        {
          url:
            `${process.env.REACT_APP_BASE_URL}/get/vendor/events?vendor_id=` +
            vendor_id,
          method: "GET",
          //data: inputdata,
          headers: {
            "Content-Type": "application/json",
            "api-token": apitoken,
          },
        },
        (response) => {
          seteventList(response);
          //setjiraTicket(response);
        }
      );
    }
  }, [vendor_id]);
  


  const saveConfig = () => {
    if(updatedPayloadFields == null || !payloadFieldsDiff){
      console.log(updatedPayloadFields,payloadFieldsDiff)
      alert("No changes to update");
      return
    }
    setBtndisable(true);
    // let { vendor_id, event, status, } = fieldlist[0];
    let apitoken = localStorage.getItem("api_token");
    let payload_json = JSON.stringify(updatedPayloadFields);

    let { event_location } = fieldlist[0];

    const form = new FormData();
    form.append("vendor_event_id", view_id);
    form.append("event_name", eventName);
    form.append("event_location", eventLocation);
    form.append("payload_field", payload_json);

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/set/vendor/mapping/data`,
        method: "POST",
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          "api-token": apitoken,
        },
      },
      (response) => {
        setisErrOpen(true);
        setapi_error_message(response[0].message);
        // window.location.assign("/Vendor_specific_fields")
      }
    );

    
  };

  
  

  //   let {event_name,payload_field,vendor_event_id}=vlist[0]
  return (
    <div className="container">
      <Commonheader pagetitle={"Create View"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                // window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>

      <PEDiv element_id="update_event" className="form-group col-md-2 mw-100">
        <label htmlFor="inputEmail4">Event :</label>
        <input
          name="event"
          className="form-control"
          placeholder="Event"
          value={eventName}
          disabled
        />
      </PEDiv>
      <PEDiv element_id="update_eventlocation" className="form-group col-md-2 mw-100">
        <label htmlFor="inputEmail4">Event Location:</label>
        <input
          name="event_location"
          className="form-control"
          placeholder="Event Location"
          value={eventLocation}
        />
      </PEDiv>
      <div className="mt-4">
        <div className="viewname-holder">
          {nestedPayloadFields && (
            <EditableJsonTree
              data={nestedPayloadFields}
              editEnabled={true}
              onChange={(updated_value) => {
                setUpdatedVendorPayload(updated_value);
              }}
              ValueRenderer={ConfigFormRenderer}
            />
          )}
        </div>
        <div className="text-center mw-100">
          <PEButton element_id="update_save"
            className="btn btn-primary btn-lg btn-block"
            onClick={saveConfig}
            disabled={btndisable}
          >
            save
          </PEButton>
        </div>
      </div>
    </div>
  );
}

const generateConfig = (jsonInput) => {
  function processNode(node) {
    if (Array.isArray(node)) {
      return node.map(item => processNode(item));
    } else if (typeof node === 'object' && node !== null) {
      const processedNode = {};
      for (let key in node) {
        processedNode[key] = processNode(node[key]);
      }
      return processedNode;
    } else {
      return JSON.stringify({
        is_mandatory: "n",
        status: "A",
        isdedupe:"no",
        field_datatype: determineDataType(node)
      });
    }
  }

  function determineDataType(value) {
    if (typeof value === 'string') {
      return 'textbox';
    } else if (typeof value === 'number') {
      return 'number';
    } else if (typeof value === 'boolean') {
      return 'boolean';
    } else {
      return 'unknown';
    }
  }

  return processNode(jsonInput);
};

export  function createNestedJson(objectsList) {
  const result = {};
  function getConfigString(obj){
    const config = {...obj}
    delete config['payload_field'];
    return JSON.stringify(config)
  }
  objectsList.forEach(obj => {
      const keys = obj.payload_field.split('.');
      let currentLevel = result;
      
      keys.forEach((key, index) => {
              if (key.includes(':')) {
                  const nestedKeys = key.split(":");
                  nestedKeys.forEach((currentKey, nestedIndex) => {
                      if (nestedIndex === nestedKeys.length - 1) {
                          if(index != keys.length - 1){
                              if(!currentLevel[currentKey]){
                                  currentLevel[currentKey] = {};
                              }
                          }
                          else{
                              currentLevel[currentKey] = getConfigString(obj);
                          }
                          currentLevel = currentLevel[currentKey]
                      } else {
                          currentLevel[currentKey] = currentLevel[currentKey] || [];
                          currentLevel = currentLevel[currentKey];
                      }
                  });
              }
           else {
              if (!currentLevel[key]) {
                  currentLevel[key] = {};
              }

              if (index === keys.length - 1) {
                  currentLevel[key] = getConfigString(obj);
              } else {
                  currentLevel = currentLevel[key];
              }
          }
      });
      
  });

  return result;
}

export function linearize(obj){
  const newObj = {};
  function traverse(obj, parent_key = "") {
      for (var key in obj) {
        if (typeof obj[key] === 'object') {
          if (obj[key] instanceof Array) {
            traverse(obj[key], `${parent_key}${key}:`);
          } else {
            traverse(obj[key], `${parent_key}${key}.`);
          }
        } else {
          newObj[`${parent_key}${key}`] =  obj[key];
        }
      }
  }
  traverse(obj)
  return newObj;
}

function generatePayloadField(inputJson){
  const linearPayload = linearize(inputJson);
  const payloadField = Object.keys(linearPayload).reduce((obj,key)=>{
    const config = JSON.parse(linearPayload[key])
    const payloadFieldItem = {
      ...config,
      'payload_field':key
    }
    obj.push(payloadFieldItem)
    return obj
  },[])
  return payloadField
}

 const ConfigFormRenderer = ({ path, jsonConfig, onFormSubmit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [config,setConfig] = useState(JSON.parse(jsonConfig))
  const [editedJsonConfig, setEditedJsonConfig] = useState(jsonConfig);
  const [isDateType,setIsDateType] = useState(config['field_datatype']=='date');
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFormSubmit = (values) => {
    // console.log(values);
    onFormSubmit(path, values);
    setIsModalVisible(false);
  };

  const typeOptions = ['textbox', 'number', 'boolean', 'date'];
  const mandatoryOptions = [{'option':'Yes','value':'y'},{'option':'No','value':'n'}]
  const dedupeOptions = [{'option':'Yes','value':'yes'},{'option':'No','value':'no'}]
  const prettyConfig = () => {
    const keys = Object.keys(config);
    const prettyString = keys.reduce((str, key, index) => {
      str += `${index === 0 ? '' : ' , '}${key}: ${config[key]}`;
      return str;
    }, "");
    return prettyString;
  };

  return (
    <div style={{ display: 'inline' }}>
      <span onClick={showModal} style={{'cursor':'pointer'}}>Edit Config</span>

      <Modal
        title="Edit Config"
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
       
        <Form onFinish={handleFormSubmit}>
          <Form.Item label="Is Mandatory" name="is_mandatory" initialValue={config.is_mandatory}>
              <Select>
                  {mandatoryOptions.map(({option,value}) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
              </Select>
          </Form.Item>

          <Form.Item label="Is Dedupe" name="isdedupe" initialValue={config.isdedupe}>
              <Select>
                  {dedupeOptions.map(({option,value}) => (
                    <Option key={option} value={value}>
                      {option}
                    </Option>
                  ))}
              </Select>
          </Form.Item>

          <Form.Item label="Status" name="status" initialValue={config.status}>
            <Input />
          </Form.Item>

          <Form.Item label="Type" name="field_datatype" initialValue={config.field_datatype}>
            <Select onChange={(value)=>{value==='date'?setIsDateType(true):setIsDateType(false)}}>
              {typeOptions.map((option) => (
                <Option key={option} value={option}>
                  {option}
                </Option>
              ))}
            </Select>
          </Form.Item>

          { isDateType &&
            <Form.Item label="Enter Date Format:" name="date_format" initialValue={config.date_format || "%Y-%m-%d %H:%M:%S"}>
              <Input />
            </Form.Item>
          }

          <Form.Item>
            <PEButton element_id="update_finalsave" type="primary" htmlType="submit">
              Save
            </PEButton>
          </Form.Item>
        </Form>
        
      </Modal>
    </div>
  );
};

export function getObjectDifference(obj1, obj2) {
  const isEqual = _.isEqual(obj1, obj2);

  if (isEqual) {
    return false;
  } else {
    const diff = _.omitBy(obj1, (value, key) => _.isEqual(obj2[key], value));
    return diff;
  }
}

export default Update;
export {ConfigFormRenderer,generateConfig,generatePayloadField}
import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { Button } from "antd";
import {PEButton, PEDiv, PETable, PETd} from './permissionComponents/index'
import { EditOutlined } from "@ant-design/icons";

function SwitchProdPilot(props) {
  const [loading, sendRequest] = useAxios();
  const [prodPilotSwitch, setprodPilotSwitch] = useState();
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [
    effetive_designation_idList,
    seteffetive_designation_idList,
  ] = useState();

  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        //At-2143||rohit chitampalle||api-restructuring-and-connection

        url: `${process.env.REACT_APP_BASE_URL}/get/switch/parameter`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        console.log("setprodPilotSwitch", response);
        setprodPilotSwitch(response);
        //setjiraTicket(response);
      }
    );
    // sendRequest(
    //   {
    //     url: `${process.env.REACT_APP_BASE_URL}/get/designation/list`,
    //     method: "GET",
    //     //data: inputdata,
    //     headers: {
    //       "api-token": apitoken,
    //     },
    //   },
    //   (response) => {
    //     seteffetive_designation_idList(response);
    //     //setjiraTicket(response);
    //   }
    // );
  }, []);

  const updateProdPilot = (e) => {
    let apitoken = localStorage.getItem("api_token");
    // let { fieldname, type, mandatory, status } = serchinput[0];
    const form = new FormData();
    form.append("parameter_value", e.target.value);
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/set/switch/parameter`,
        method: "POST",
        data: form,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        // console.log("Output", response);
        alert(response.message);
        window.reload();
      }
    );
    // console.log(e.target.value);
  };

  const get_eftDesi = (eid) => {
    const foundDesignation =
      effetive_designation_idList &&
      effetive_designation_idList.find(
        (designation) => designation.effective_designation_id === eid
      );
    return foundDesignation && foundDesignation.designation_code;
  };
  return (
    <div className="container">
      <Commonheader pagetitle={"Switch"} props={props} />
      {loading && (
        <div class="load-4">
          <div class="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <PEButton element_id="switch_button"
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </PEButton>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <PETable element_id="switch_table" class="table">
          <thead class="thead-light">
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Prod/Pilot</td>
              <PETd element_id="switch_tabledata">
                <select className="form-control" onChange={updateProdPilot}>
                {
                  prodPilotSwitch &&  (prodPilotSwitch.data == 1) ? <><option value={1} selected>Pilot</option>
                  <option value={0}>Prod</option></> : <><option value={1}>Pilot</option>
                    <option value={0} selected>Prod</option></>
                }
                </select>
              </PETd>
            </tr> 
            {/* {viewList &&
              viewList.map((list) => (
                <tr>
                  <td>{list.view_name}</td>
                  <td>{list.bucket_name}</td>
                  <td>{list.event_name}</td>
                  <td>{get_eftDesi(list.effective_designation_id)}</td>
                  <td>
                    <button
                      type="button"
                      class="btn btn-outline-info"
                      onClick={() =>
                        props.history.push({
                          pathname: "/View",
                          state: {
                            view_id: list.view_id,
                          },
                        })
                      }
                    >
                      <EditOutlined />
                    </button>
                  </td>
                </tr>
              ))} */}
          </tbody>
        </PETable>
      </div>
    </div>
  );
}

export default SwitchProdPilot;

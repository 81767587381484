// AT-2018 | AT-2260 | Ankur Makavana | 03/08/2023 | create new screen for transformation
import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { logout } from "../hooks/Utility";
import logo from "../assets/images/aceN_Logo.png";
import loader from "../assets/images/loader.gif";
import {PEDiv} from './permissionComponents/index'

function Transformation(props) {
  const { effective_designation_id, api_token } =
    (props.location && props.location.state) || {};
  return (
    <div className="container">
      <Commonheader pagetitle={"Transformation"} props={props} />
      <div className="card-holder">
        <div className="row mt-4">
          <PEDiv element_id="transformation_mapping" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Transformation_mapping")}
            >
              <div className="card-body">
                <p className="card-text">Mapping</p>
              </div>
            </div>
          </PEDiv>
          <PEDiv element_id="transformation_mapinglist" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Transformation_mapping_list")}
            >
              <div className="card-body">
                <p className="card-text">Mapping List</p>
              </div>
            </div>
          </PEDiv>
          <PEDiv element_id="transformation_ndvalue" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("ND_values")}
            >
              <div className="card-body">
                <p className="card-text">ND Values</p>
              </div>
            </div>
          </PEDiv>
        </div>
      </div>
    </div>
  );
}
export default Transformation;

import axios from "axios";

export function logout() {
  //const [loading, sendRequest] = useAxios();
  //const formdata = new FormData();
  // console.log(api_token);
  let apitoken = localStorage.getItem("api_token");

  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/logout`,
      {},
      {
        headers: {
          "api-token": apitoken,
        },
      }
    )
    .then((res) => {
      console.log(res);
      localStorage.clear();
      window.location.href = "/";
    })
    .catch((err) => {
      console.log(err);
      localStorage.clear();
      window.location.href = "/";
    });
}

// import axios from "axios";

// export function logout() {
//   //const [loading, sendRequest] = useAxios();
//   //const formdata = new FormData();
//   // console.log(api_token);
//   //let apitoken = localStorage.getItem("api_token");
//   console.log("accesstokenlogout", localStorage.getItem("access_token"));
//   //let apitoken = localStorage.getItem("access_token");

//   axios
//     .post(
//       `${process.env.REACT_APP_BASE_URL}/new/logout`,
//       {},
//       {
//         headers: {
//           "api-token": localStorage.getItem("access_token"),
//         },
//       }
//     )
//     // .then((res) => {
//     //   console.log(res);
//     //   localStorage.clear();
//     //   window.location.href = "/";
//     // })
//     // .catch((err) => {
//     //   console.log(err);
//     // });

//     .then((res) => console.log("res", res))
//     .then((data) => {
//       console.log(data);
//       localStorage.clear();
//       window.location.href = "/";
//     })
//     .catch((error) => {
//       console.log(error);
//     });
// }

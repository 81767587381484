import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { QueryBuilder, formatQuery, RuleGroupType } from "react-querybuilder";
import FieldMappingEditor from "./CreateView";
import PayloadTile from "./PayloadTile";
import { PEButton, PEDiv, PEInput } from "./permissionComponents/index";

function Create_view(props) {
  const [loading, sendRequest] = useAxios();
  const [searchKey, setsearchKey] = useState([
    { searchKey: "", operator: "", searchValue: "", inputType: "string" },
  ]);
  const [searchKeygetpayloadfields, setsearchKeygetpayloadfields] = useState([
    { searchKey: "", operator: "", searchValue: "", inputType: "string" },
  ]);
  const [tempsearchKey, settempsearchKey] = useState([
    { searchKey: "", operator: "", searchValue: "", inputType: "string" },
  ]);
  const [istempsearch, setistempsearch] = useState("0");
  const [get_bucketfield, setget_bucketfield] = useState(false);
  const [fetch_onload, setfetch_onload] = useState(true);
  const [hide_loadmore, sethide_loadmore] = useState();
  const [new_field_mapping, setnew_field_mapping] = useState();
  const [editsearchKey, seteditsearchKey] = useState();
  const [searchValue, setsearchValue] = useState();
  const [fields_array, setfields_array] = useState();
  const [fields_array_payload, setfields_array_payload] = useState();
  const [default_payload, setdefault_payload] = useState([]);
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [api_fields_list, setapi_fields_list] = useState();
  const [api_fields_payload, setapi_fields_payload] = useState();
  const [bucket_list, setbucket_list] = useState();
  const [bucket_name, setbucket_name] = useState("");
  const [event_name, setevent_name] = useState("");
  const [event_location, setevent_location] = useState("");
  const [view_name, setview_name] = useState();
  const [view_type, setview_type] = useState();
  const [agg_view, setagg_view] = useState();
  const [select_payload, setselect_payload] = useState();
  const [mongo_combinesearch, setmongo_combinesearch] = useState();
  const [processedis, setprocessedis] = useState("false");
  const [status, setstatus] = useState("A");
  const [effetive_designation_id, seteffetive_designation_id] = useState();
  const [save_design_flag, setsave_design_flag] = useState(false);
  const [db_name, setdb_name] = useState();
  const [db_namegetpayload, setdb_namegetpayload] = useState();
  const [collection_name, setcollection_name] = useState();
  const [sourcedataoutputformat, setsourcedataoutputformat] = useState();
  const [outputfilter, setoutputfilter] = useState();
  const [payloaddataoutputformat, setpayloaddataoutputformat] = useState();
  const [collection_namegetpayload, setcollection_namegetpayload] = useState();
  const [finalArray, setfinalArray] = useState({});
  const [db_list, setdb_list] = useState();
  const [collection_list, setcollection_list] = useState();
  const [component_mapping, setcomponent_mapping] = useState();
  const [record_per_call, setrecord_per_call] = useState();
  const [get_data_by, setget_data_by] = useState("C");
  const [setupdated_at, setsetupdated_at] = useState();
  const [isDropdown, setisDropdown] = useState(false);
  const [dropdownResponse, setdropdownResponse] = useState([]);

  const [
    collection_listgetpayloadfields,
    setcollection_listgetpayloadfields,
  ] = useState();
  const [view_list, setview_list] = useState();

  //Ankur Makavana | 04-08-2023 | Disable Bucket, Event and Designation id on edit
  const [view_id, setview_id] = useState();
  const [resondropdownlist, setresondropdownlist] = useState();
  const [
    effetive_designation_idList,
    seteffetive_designation_idList,
  ] = useState();

  const [fieldlist, setfieldlist] = useState([
    {
      fieldname: "",
      BucketField: "",
      isEdit: "0",
      isRequired: "0",
      isVisible: "1",
      defaultfilter: "",
      addapi: "0",
      datatype: "textbox",
      tablesource: "",
      showLabel: "1",
      dropdownlist: "",
      Downloadfile: "",
      isSearch: "0",
      productlist: "",
      tenure: "",
      productid: "",
      massupdate: "0",
      isConfirm: "0",
      isEditDisable: "0",
      isRedirect: "0",
      RedirectData: "path:'',requiredata:[]",
      RedirectCondition: "",
      //DisableOnField: "",
      DisableOnFieldValue: "",
      FieldLength: "",
      FileType: "",
      FileSize: "",
      FilePath: "",
      FileName: "",
      //tabledata: [{ id: 1, tablekey: "", tablevalue: "" }],
    },
  ]);
  const [divs, setDivs] = useState([
    {
      id: 1,
      action_name: "",
      api_url: "",
      formdata: [
        {
          id: 1,
          formdatakey: "",
          formdatavalue: "",
          inputtype: "text",
          defaultvalue: "",
        },
      ],
      inputType: "formdata",
      formrow: "",
      action_event: "",
      url_type: "",
    },
  ]);
  const [datasource, setdatasource] = useState([
    {
      id: 1,
      action_name: "",
      api_url: "",
      formdata: [{ id: 1, formdatakey: "", formdatavalue: "" }],
      inputType: "formdata",
      formrow: "",
      action_event: "",
      url_type: "",
      outputformat: "",
      outputfilter: "",
    },
  ]);

  const [payloadfields, setpayloadfields] = useState([
    {
      id: 1,
      action_name: "",
      api_url: "",
      formdata: [{ id: 1, formdatakey: "", formdatavalue: "" }],
      inputType: "formdata",
      formrow: "",
      action_event: "",
      url_type: "",
      outputformat: "",
    },
  ]);
  const [exprow, setexprow] = useState([
    {
      fieldname: "",
      BucketField: "",
      isEdit: "0",
      isRequired: "0",
      isVisible: "1",
      defaultfilter: "",
      addapi: "0",
      datatype: "textbox",
      tablesource: "",
      showLabel: "1",
      dropdownlist: "",
      Downloadfile: "",
      isSearch: "0",
      massupdate: "0",
      isConfirm: "0",
      isEditDisable: "0",
      isRedirect: "0",
      RedirectData: "path:'',requiredata:[]",
      RedirectCondition: "",
      //DisableOnField: "",
      DisableOnFieldValue: "",
      FieldLength: "",
      FileType: "",
      FileSize: "",
      FilePath: "",
      FileName: "",
    },
  ]);
  const [apidata, setapidata] = useState([
    {
      id: 1,
      action_name: "",
      api_url: "",
      formdata: [
        { id: 1, formdatakey: "", formdatavalue: "", defaultvalue: "" },
      ],
      outputdata: [{ id: 1, outputkey: "", outputvalue: "" }],
      tabledata: [{ id: 1, tablekey: "", tablevalue: "" }],
      inputType: "",
      formrow: "",
      getoutputdata: "",
    },
  ]);
  const [search_fields, setsearch_fields] = useState();
  const [isMobileview, setisMobileview] = useState(false);
  const [query, setQuery] = useState({ combinator: "and", rules: [] });
  const [Operators, setOperators] = useState([
    { name: "=", label: "=" },
    { name: "!=", label: "!=" },
    { name: ">", label: ">" },
    { name: "<", label: "<" },
    { name: ">=", label: ">=" },
    { name: "<=", label: "<=" },
    { name: "exists", label: "Exists" },
    { name: "TDHG", label: "Time Different > (In Hour)" },
    { name: "TDHL", label: "Time Different < (In Hour)" },
  ]);
  const api_input_type = [
    { option: "JSON", value: "json" },
    { option: "Date", value: "date" },
    { option: "Date Time", value: "datetime" },
    { option: "Text", value: "text" },
    { option: "INT", value: "int" },
  ];

  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");

    const view_id =
      props.location && props.location.state.view_id != ""
        ? props.location.state.view_id
        : agg_view;
    console.log("agg_view--", view_id);
    // if (agg_view != null) {
    //   if (!view_id) {
    //     setview_id(agg_view);
    //   }
    // }

    if (!view_list) {
      sendRequest(
        {
          //At-2143||rohit chitampalle||api-restructuring-and-connection

          url: `${process.env.REACT_APP_BASE_URL}/get/webhook/configuration/data`,
          method: "GET",
          //data: inputdata,
          headers: {
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log("setviewList", response);
          setview_list(response);
          //setjiraTicket(response);
        }
      );
    }
    if (!bucket_list) {
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/payload/getcollections`,
          method: "POST",
          //data: inputdata,
          headers: {
            "Content-Type": "application/json",
            "api-token": apitoken,
          },
        },
        (response) => {
          setbucket_list(response);
          //setjiraTicket(response);
        }
      );
    }
    if (!effetive_designation_idList) {
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/get/designation/list`,
          method: "GET",
          //data: inputdata,
          headers: {
            "api-token": apitoken,
          },
        },
        (response) => {
          seteffetive_designation_idList(response);
          //setjiraTicket(response);
        }
      );
    }
    if (!resondropdownlist) {
      sendRequest(
        {
          url: `${process.env.REACT_APP_BASE_URL}/get/dropdown/list`,
          method: "GET",
          //data: inputdata,
          headers: {
            "api-token": apitoken,
          },
        },
        (response) => {
          setresondropdownlist(response);
          //setjiraTicket(response);
        }
      );
    }

    if (view_id && fieldlist.length == 1) {
      //Ankur Makavana | 04-08-2023 | Disable Bucket, Event and Designation id on edit

      setview_id(view_id);
      const formdata = new FormData();
      // formdata.append("id", view_id);
      sendRequest(
        {
          url:
            `${process.env.REACT_APP_BASE_URL}/get/webhook/configuration/data?id=` +
            view_id,
          method: "GET",
          headers: {
            "api-token": apitoken,
          },
        },
        (response) => {
          console.log("field_mappingg", response[0].field_mapping);
          setcomponent_mapping(response[0].component_mapping);
          response[0].field_mapping.map((item) => {
            // console.log(item.hasOwnProperty("showLabel"));
            if (!item.hasOwnProperty("showLabel")) {
              item.showLabel = "1";
            }
          });

          props.location.state.fieldslist = response[0].field_mapping;
          // console.log(props.location.state);
          let url = "";
          let form;
          let outputformat = "";
          if (response[0].payload_type == "M" || response[0].getpayloadfields) {
            outputformat = response[0].payloaddataoutputformat;
            const stateArray = [];
            const restArray = [];
            let originalArray = JSON.parse(
              response[0].getpayloadfields
                ? response[0].getpayloadfields
                : response[0].search_by_pair
            );
            originalArray.forEach((item) => {
              if (item[0] == "processed") {
                stateArray.push(item);
              } else {
                restArray.push(item);
              }
            });
            // console.log("restArray->", restArray);
            const convertedArray = originalArray.map((subArray) => {
              return [subArray[0], subArray[1], subArray[2]];
            });
            url = `${process.env.REACT_APP_BASE_URL}/mongo/get/${response[0].db_namegetpayload}/${response[0].collection_namegetpayload}?limit=1&offset=0`;
            form = convertedArray;
            console.log("form_convertedArray", form);
          } else if (response[0].payload_type == "B") {
            console.log("response[0].payload_type->", response[0].payload_type);
            url = `${process.env.REACT_APP_BASE_URL}/payload/get/${response[0].bucket_name}?limit=1`;

            form = JSON.stringify([
              [response[0].event_location, "==", response[0].event_name],
            ]);
            //}
          } else if (response[0].payload_type == "A") {
            console.log("response[0].payload_type", response);
            if (response[0].payloadfields[0].url_type == "N") {
              url = `${process.env.REACT_APP_BASE_URL}/${response[0].payloadfields[0].api_url}`;
            } else {
              url = response[0].payloadfields[0].api_url;
            }
            outputformat = response[0].payloadfields[0].outputformat;
            const api = response[0].payloadfields[0];
            form = new FormData();

            api.formdata.forEach((item) => {
              form.append(item.formdatakey, item.formdatavalue);
            });
          }
          let apitoken = localStorage.getItem("api_token");
          sendRequest(
            {
              url: url,
              method: "POST",
              data: form,
              headers: {
                "Content-Type": "application/json",
                "api-token": apitoken,
              },
            },
            (json) => {
              let response;
              if (outputformat) {
                response = getNestedValue(json, outputformat);
                if (!Array.isArray(response)) {
                  response = [response];
                }
              } else {
                response = json;
              }
              //setgetfields(response);
              var jsonkey = Object.keys(response);

              const childKey = jsonkey[0];
              setfields_array(response[childKey]);
              setfields_array_payload(response[childKey]);

              setdefault_payload(response);
              const childData = response[childKey];

              setapi_fields_list(Object.keys(childData));
              setapi_fields_payload(Object.keys(childData));
            }
          );

          setfetch_onload(response[0].fetch_onload);
          if (response[0].record_per_call) {
            setrecord_per_call(response[0].record_per_call);
          }
          if (response[0].hide_loadmore) {
            sethide_loadmore(response[0].hide_loadmore);
          }
          if (response[0].get_data_by) {
            setget_data_by(response[0].get_data_by);
          }
          setpayloaddataoutputformat(response[0].payloaddataoutputformat);
          setsourcedataoutputformat(response[0].sourcedataoutputformat);
          setoutputfilter(response[0].outputfilter);
          if (response[0].setupdated_at) {
            setsetupdated_at(response[0].setupdated_at);
          }
          setbucket_name(response[0].bucket_name);
          setevent_name(response[0].event_name);
          setevent_location(response[0].event_location);
          if (!view_name) {
            setview_name(response[0].view_name);
          }
          setselect_payload(response[0].payload_type);
          if (!effetive_designation_id) {
            seteffetive_designation_id(response[0].effective_designation_id);
          }
          setfieldlist(response[0].field_mapping);
          setDivs(response[0].action_mapping);
          setdatasource(response[0].datasource);
          setpayloadfields(response[0].payloadfields);
          setapidata(response[0].get_api_mapping);
          setQuery(response[0].field_mapping[0].search_query);
          seteditsearchKey(response[0].getpayloadfields);
          setdb_name(response[0].db_name);
          if (response[0].db_namegetpayload) {
            setdb_namegetpayload(response[0].db_namegetpayload);
          } else {
            setdb_namegetpayload(response[0].db_name);
          }
          if (!view_type) {
            setview_type(response[0].view_type);
          }
          if (response[0].agg_view) {
            setagg_view(response[0].agg_view);
          }
          setcollection_name(response[0].collection_name);
          if (response[0].collection_namegetpayload) {
            setcollection_namegetpayload(response[0].collection_namegetpayload);
          } else {
            setcollection_namegetpayload(response[0].collection_name);
          }
          if (response[0].getpayloadfields) {
            //if (response[0].payload_type == "M") {
            const stateArray = [];
            const restArray = [];
            let originalArray = JSON.parse(response[0].getpayloadfields);
            console.log("originalArray", originalArray);
            originalArray.forEach((item) => {
              if (item[0] == "processed") {
                stateArray.push(item);
              } else {
                restArray.push(item);
              }
            });
            const convertedArray = restArray.map((subArray) => {
              console.log("restArray->", subArray);
              return {
                searchKey: subArray[0],
                operator: subArray[1],
                searchValue: subArray[2],
                inputType: subArray[3],
              };
            });
            setsearchKeygetpayloadfields(convertedArray);
            // } else {
            //   setsearchKeygetpayloadfields(response[0].getpayloadfields);
            // }
          }
          if (response[0].search_by_pair) {
            if (response[0].payload_type == "M") {
              const stateArray = [];
              const restArray = [];
              let originalArray = JSON.parse(response[0].search_by_pair);
              originalArray.forEach((item) => {
                if (item[0] == "processed") {
                  stateArray.push(item);
                } else {
                  restArray.push(item);
                }
              });
              const convertedArray = restArray.map((subArray) => {
                console.log("restArray->", subArray);
                return {
                  searchKey: subArray[0],
                  operator: subArray[1],
                  searchValue: subArray[2],
                  inputType: subArray[3],
                };
              });
              setsearchKey(convertedArray);
              setprocessedis(stateArray[0][2]);
              console.log("convertedArray-->", convertedArray);
            } else {
              const searchByPair = JSON.parse(response[0].search_by_pair);
              const hasValue = searchByPair.some((pair) =>
                pair.some((value) => value !== "")
              );

              if (hasValue) {
                setsearchKey(response[0].search_by_pair);
              } else {
                setsearchKey([]);
              }
            }
          }
        }
      );
    }
    if (fieldlist) {
      //filterissearch();
      const filtersearch = fieldlist.filter((item) => item.isSearch === "1");
      const extractedDataArray = [];

      filtersearch.forEach((item) => {
        console.log("extractedDataArray_", item);
        const name = item.BucketField;
        const label = item.fieldname;
        if (item.datatype == "dropdownlist") {
          setisDropdown(item.dropdownlist);
          const valueEditorType = "select";
          const values = dropdownResponse;
          extractedDataArray.push({ name, label, valueEditorType, values });
        }
        // Push the extracted data as an object into the array
        else {
          extractedDataArray.push({ name, label });
        }
      });
      console.log("extractedDataArray", extractedDataArray);

      setsearch_fields(extractedDataArray);
    }
    // if (query) {
    //   const updatedData = [...fieldlist];
    //   let search_condition = [];
    //   updatedData.zz = query;
    //   //updatedData.push(search_condition);
    //   console.log("query-->", updatedData);

    //   //setfieldlist(updatedData);
    // }
    if (query) {
      let mongoquery = formatQuerybuild(query);
      console.log("mongoquery->", mongoquery);
    }
  }, [fieldlist, query, agg_view]);
  useEffect(() => {
    if (isDropdown) {
      getDataFromApi(isDropdown);
    }
  }, [isDropdown]);
  const getDataFromApi = async (dropdownlistid) => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url:
          `${process.env.REACT_APP_BASE_URL}/get/dropdown/item/list?dropdown_id=` +
          dropdownlistid,
        method: "GET",
        //data: inputdata,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (response) => {
        //setndid(response[0].vendor_id);
        //setjiraTicket(response);
        const transformedData = response.map((item) => ({
          label: item.item_name,
          name: item.item_value,
        }));
        console.log("dropdownlist", transformedData);
        setdropdownResponse(transformedData);
      }
    );
  };
  // const filterissearch = () => {
  useEffect(() => {
    if (db_name && db_list) {
      console.log("call db name");
      getcollection(db_name);
    }
    if (db_namegetpayload && db_list) {
      console.log("call db name");
      getpayloadfieldscollection(db_namegetpayload);
    }
  }, [db_name, db_list, db_namegetpayload]);
  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/mongo/list`,
        method: "POST",
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        //setgetfields(response);
        setdb_list(response);
      }
    );
  }, []);
  // };
  const handleAddDiv = () => {
    const newId = divs.length + 1;
    setDivs([
      ...divs,
      {
        id: newId,
        action_name: "",
        api_url: "",
        formdata: [
          {
            id: 1,
            formdatakey: "",
            formdatavalue: "",
            inputtype: "text",
            defaultvalue: "",
          },
        ],
        inputType: "formdata",
        formrow: "",
        action_event: "",
        url_type: "",
      },
    ]);
  };
  const handleAddApidata = () => {
    const newId = apidata.length + 1;
    setapidata([
      ...apidata,
      {
        id: newId,
        action_name: "",
        api_url: "",
        formdata: [
          { id: 1, formdatakey: "", formdatavalue: "", defaultvalue: "" },
        ],
        outputdata: [{ id: 1, outputkey: "", outputvalue: "" }],
        tabledata: [{ id: 1, tablekey: "", tablevalue: "" }],
        inputType: "",
        formrow: "",
        getoutputdata: "",
      },
    ]);
  };

  const handleAddsearch = () => {
    setsearchKey([
      ...searchKey,
      { searchKey: "", operator: "", searchValue: "", inputType: "string" },
    ]);
  };
  const handleAddpayloadfieldssearch = () => {
    setsearchKeygetpayloadfields([
      ...searchKeygetpayloadfields,
      { searchKey: "", operator: "", searchValue: "", inputType: "string" },
    ]);
  };

  const handleAddfield = () => {
    // setfieldlist([
    //   ...fieldlist,
    //   {
    //     fieldname: "",
    //     BucketField: [{ id: 1, BuckeSubtField: [{ id: 0, value: "" }] }],
    //   },
    // ]);
    setfieldlist([
      ...fieldlist,
      {
        fieldname: "",
        BucketField: "",
        isEdit: "0",
        isRequired: "0",
        isVisible: "1",
        defaultfilter: "",
        addapi: "0",
        datatype: "textbox",
        showLabel: "1",
        dropdownlist: "",
        Downloadfile: "",
        isSearch: "0",
        productlist: "",
        tenure: "",
        productid: "",
        massupdate: "0",
        isConfirm: "0",
        isEditDisable: "0",
        isRedirect: "0",
        RedirectData: "path:'',requiredata:[]",
        RedirectCondition: "",
        //DisableOnField: "",
        DisableOnFieldValue: "",
        FieldLength: "",
        FileType: "",
        FileSize: "",
        FilePath: "",
        FileName: "",
      },
    ]);

    var jsonkey = Object.keys(default_payload);

    const childKey = jsonkey[0];
    const childData = default_payload[childKey];
    setfields_array(childData);
    setapi_fields_list(Object.keys(childData));

    props.location.state &&
      props.location.state.fieldslist.push({
        fieldname: "",
        BucketField: "",
        isEdit: "0",
        isRequired: "0",
        isVisible: "1",
        defaultfilter: "",
        addapi: "0",
        datatype: "textbox",
        showLabel: "1",
        massupdate: "0",
        isConfirm: "0",
        isEditDisable: "0",
        isRedirect: "0",
        RedirectData: "path:'',requiredata:[]",
        RedirectCondition: "",
        //DisableOnField: "",
        DisableOnFieldValue: "",
        FieldLength: "",
      });
  };
  //  let api_header = 2;
  const handleAddheaderfield = (api_index) => {
    // const api = divs[api_index];
    // const newFormID = api.formdata.length + 1;
    // const newForm = { id: newFormID, formdatakey: "", formdatavalue: "" };
    // const newFormdata = [...api.formdata, newForm];
    // const updatedApi = { ...api, formdata: newFormdata };
    // setDivs([
    //   ...divs.slice(0, api_index),
    //   updatedApi,
    //   ...divs.slice(api_index + 1),
    // ]);

    const api = divs[api_index];
    const newFormID = api.formdata.length + 1;
    const newForm = { id: newFormID, formdatakey: "", formdatavalue: "" };
    const newFormdata = [...api.formdata, newForm];
    const updatedApi = { ...api, formdata: newFormdata };
    setDivs([
      ...divs.slice(0, api_index),
      updatedApi,
      ...divs.slice(api_index + 1),
    ]);

    var jsonkey = Object.keys(default_payload);

    const childKey = jsonkey[0];
    const childData = default_payload[childKey];
    setfields_array_payload(childData);
    setapi_fields_payload(Object.keys(childData));
  };
  const handleAddSourcefield = (api_index) => {
    const api = datasource[api_index];
    const newFormID = api.formdata.length + 1;
    const newForm = { id: newFormID, formdatakey: "", formdatavalue: "" };
    const newFormdata = [...api.formdata, newForm];
    const updatedApi = { ...api, formdata: newFormdata };
    setdatasource([
      ...datasource.slice(0, api_index),
      updatedApi,
      ...datasource.slice(api_index + 1),
    ]);

    console.log("datasource", updatedApi);
    // var jsonkey = Object.keys(default_payload);

    // const childKey = jsonkey[0];
    // const childData = default_payload[childKey];
    // setfields_array_payload(childData);
    // setapi_fields_payload(Object.keys(childData));
  };
  const handleAddpayloadfields = (api_index) => {
    const api = payloadfields[api_index];
    const newFormID = api.formdata.length + 1;
    const newForm = { id: newFormID, formdatakey: "", formdatavalue: "" };
    const newFormdata = [...api.formdata, newForm];
    const updatedApi = { ...api, formdata: newFormdata };

    // Create a new array with the updated API object at the correct index
    const updatedPayloadfields = [
      ...payloadfields.slice(0, api_index),
      updatedApi,
      ...payloadfields.slice(api_index + 1),
    ];

    // Update the state with the new array
    setpayloadfields(updatedPayloadfields);

    console.log("payloadfields", updatedPayloadfields);
  };

  const handleAddapiheaderfield = (api_index) => {
    const api = apidata[api_index];
    const newFormID = api.formdata.length + 1;
    const newForm = { id: newFormID, formdatakey: "", formdatavalue: "" };
    const newFormdata = [...api.formdata, newForm];
    const updatedApi = { ...api, formdata: newFormdata };
    setapidata([
      ...apidata.slice(0, api_index),
      updatedApi,
      ...apidata.slice(api_index + 1),
    ]);

    var jsonkey = Object.keys(default_payload);

    const childKey = jsonkey[0];
    const childData = default_payload[childKey];
    setfields_array_payload(childData);
    setapi_fields_payload(Object.keys(childData));
  };

  const handleAddapioutputheaderfield = (api_index) => {
    const api = apidata[api_index];
    const newFormID = api.outputdata.length + 1;
    const newForm = { id: newFormID, outputkey: "", outputvalue: "" };
    const newFormdata = [...api.outputdata, newForm];
    const updatedApi = { ...api, outputdata: newFormdata };
    setapidata([
      ...apidata.slice(0, api_index),
      updatedApi,
      ...apidata.slice(api_index + 1),
    ]);

    var jsonkey = Object.keys(default_payload);

    const childKey = jsonkey[0];
    const childData = default_payload[childKey];
    setfields_array_payload(childData);
    setapi_fields_payload(Object.keys(childData));
  };

  const removeApiFormdata = (apiid, headerId) => {
    const updatedFieldList = apidata.map((field) => {
      if (field.id === apiid) {
        field.formdata = field.formdata.filter(
          (formData) => formData.id !== headerId
        );
      }
      return field;
    });
    setapidata(updatedFieldList);
  };

  const removeApioutputFormdata = (apiid, headerId) => {
    const updatedFieldList = apidata.map((field) => {
      if (field.id === apiid) {
        field.outputdata = field.outputdata.filter(
          (formData) => formData.id !== headerId
        );
      }
      return field;
    });
    setapidata(updatedFieldList);
  };

  const removeFormdata = (divId, headerId) => {
    // const updatedDivs = divs.map((div) => {
    //   if (div.id === divId) {
    //     const updatedFormdata = div.formdata.filter(
    //       (header) => header.id !== headerId
    //     );

    //     return { ...div, formdata: updatedFormdata };
    //   }
    //   return div;
    // });
    // setDivs(updatedDivs);

    const updatedDivs = divs.map((div) => {
      if (div.id === divId) {
        const updatedFormdata = div.formdata.filter(
          (header) => header.id !== headerId
        );

        return { ...div, formdata: updatedFormdata };
      }
      return div;
    });
    console.log("updatedDivs", updatedDivs);
    setDivs(updatedDivs);
  };
  const removesoueceFormdata = (divId, headerId) => {
    const updatedDivs = datasource.map((div) => {
      if (div.id === divId) {
        const updatedFormdata = div.formdata.filter(
          (header) => header.id !== headerId
        );

        return { ...div, formdata: updatedFormdata };
      }
      return div;
    });
    console.log("updatedDivs", updatedDivs);
    setdatasource(updatedDivs);
  };
  const removepayloadfieldsFormdata = (divId, headerId) => {
    const updatedDivs = payloadfields.map((div) => {
      if (div.id === divId) {
        const updatedFormdata = div.formdata.filter(
          (header) => header.id !== headerId
        );

        return { ...div, formdata: updatedFormdata };
      }
      return div;
    });
    console.log("updatedDivs", updatedDivs);
    setpayloadfields(updatedDivs);
  };

  const handleInputAPI = (e, api_index = null, index) => {
    const { name, value } = e.target;
    const api = divs[api_index];
    if (name === "api_url") {
      const updatedApi = { ...api, api_url: value };
      setDivs([
        ...divs.slice(0, api_index),
        updatedApi,
        ...divs.slice(api_index + 1),
      ]);
    } else if (name === "action_name") {
      const updatedApi = { ...api, action_name: value };
      setDivs([
        ...divs.slice(0, api_index),
        updatedApi,
        ...divs.slice(api_index + 1),
      ]);
    } else if (name === "action_event") {
      const updatedApi = { ...api, action_event: value };
      setDivs([
        ...divs.slice(0, api_index),
        updatedApi,
        ...divs.slice(api_index + 1),
      ]);
    } else if (name === "url_type") {
      const updatedApi = { ...api, url_type: value };
      setDivs([
        ...divs.slice(0, api_index),
        updatedApi,
        ...divs.slice(api_index + 1),
      ]);
    } else if (name === "inputType") {
      const updatedApi = { ...api, inputType: value };
      setDivs([
        ...divs.slice(0, api_index),
        updatedApi,
        ...divs.slice(api_index + 1),
      ]);
    } else if (name === "formrow") {
      const updatedApi = { ...api, formrow: value };
      setDivs([
        ...divs.slice(0, api_index),
        updatedApi,
        ...divs.slice(api_index + 1),
      ]);
    } else if (name === "getoutputdata") {
      console.log("name", name);
      console.log("value", value);
      const updatedApi = { ...api, getoutputdata: value };
      console.log("updatedApi", updatedApi);
      setDivs([
        ...divs.slice(0, api_index),
        updatedApi,
        ...divs.slice(api_index + 1),
      ]);
    } else {
      const formdata = [...api.formdata];
      const headerIndex = formdata.findIndex((header) => header.id === index);
      let result;
      // if (name === "formdatavalue") {
      //   let prevdata = formdata[headerIndex][name];
      //   console.log("prevdata", prevdata);
      //   if (prevdata) {
      //     result = prevdata.concat(".", value);
      //   } else {
      //     result = value;
      //   }
      //   const childData = fields_array_payload[e.target.value];
      //   if (Object.keys(childData).length > 0) {
      //     setfields_array_payload(childData);
      //     setapi_fields_payload(Object.keys(childData));
      //   }
      // } else {
      //   result = value;
      // }
      // list[fieldindex]["BucketField"] = result;
      // setfieldlist(list);

      formdata[headerIndex][name] = value;
      const updatedApi = { ...api, formdata: formdata };
      //console.log("updatedApi", updatedApi);
      setDivs([
        ...divs.slice(0, api_index),
        updatedApi,
        ...divs.slice(api_index + 1),
      ]);
    }

    //console.log("childData", fields_array_payload);
  };
  const handlesourceInputAPI = (e, api_index = null, index) => {
    const { name, value } = e.target;
    const api = datasource[api_index];
    if (name === "api_url") {
      const updatedApi = { ...api, api_url: value };
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    } else if (name === "action_name") {
      const updatedApi = { ...api, action_name: value };
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    } else if (name === "action_event") {
      const updatedApi = { ...api, action_event: value };
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    } else if (name === "url_type") {
      const updatedApi = { ...api, url_type: value };
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    } else if (name === "inputType") {
      const updatedApi = { ...api, inputType: value };
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    } else if (name === "formrow") {
      const updatedApi = { ...api, formrow: value };
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    } else if (name === "outputformat") {
      const updatedApi = { ...api, outputformat: value };
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    } else if (name === "outputfilter") {
      const updatedApi = { ...api, outputfilter: value };
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    } else {
      const formdata = [...api.formdata];
      const headerIndex = formdata.findIndex((header) => header.id === index);
      let result;
      // if (name === "formdatavalue") {
      //   let prevdata = formdata[headerIndex][name];
      //   console.log("prevdata", prevdata);
      //   if (prevdata) {
      //     result = prevdata.concat(".", value);
      //   } else {
      //     result = value;
      //   }
      //   const childData = fields_array_payload[e.target.value];
      //   if (Object.keys(childData).length > 0) {
      //     setfields_array_payload(childData);
      //     setapi_fields_payload(Object.keys(childData));
      //   }
      // } else {
      //   result = value;
      // }
      // list[fieldindex]["BucketField"] = result;
      // setfieldlist(list);

      formdata[headerIndex][name] = value;
      const updatedApi = { ...api, formdata: formdata };
      //console.log("updatedApi", updatedApi);
      setdatasource([
        ...datasource.slice(0, api_index),
        updatedApi,
        ...datasource.slice(api_index + 1),
      ]);
    }

    //console.log("childData", fields_array_payload);
  };
  const handlepayloadfieldsInputAPI = (e, api_index = null, index) => {
    const { name, value } = e.target;
    const api = payloadfields[api_index];
    if (name === "api_url") {
      const updatedApi = { ...api, api_url: value };
      setpayloadfields([
        ...payloadfields.slice(0, api_index),
        updatedApi,
        ...payloadfields.slice(api_index + 1),
      ]);
    } else if (name === "action_name") {
      const updatedApi = { ...api, action_name: value };
      setpayloadfields([
        ...payloadfields.slice(0, api_index),
        updatedApi,
        ...payloadfields.slice(api_index + 1),
      ]);
    } else if (name === "action_event") {
      const updatedApi = { ...api, action_event: value };
      setpayloadfields([
        ...payloadfields.slice(0, api_index),
        updatedApi,
        ...payloadfields.slice(api_index + 1),
      ]);
    } else if (name === "url_type") {
      const updatedApi = { ...api, url_type: value };
      setpayloadfields([
        ...payloadfields.slice(0, api_index),
        updatedApi,
        ...payloadfields.slice(api_index + 1),
      ]);
    } else if (name === "inputType") {
      const updatedApi = { ...api, inputType: value };
      setpayloadfields([
        ...payloadfields.slice(0, api_index),
        updatedApi,
        ...payloadfields.slice(api_index + 1),
      ]);
    } else if (name === "formrow") {
      const updatedApi = { ...api, formrow: value };
      setpayloadfields([
        ...payloadfields.slice(0, api_index),
        updatedApi,
        ...payloadfields.slice(api_index + 1),
      ]);
    } else if (name === "outputformat") {
      const updatedApi = { ...api, outputformat: value };
      setpayloadfields([
        ...payloadfields.slice(0, api_index),
        updatedApi,
        ...payloadfields.slice(api_index + 1),
      ]);
    } else {
      const formdata = [...api.formdata];
      const headerIndex = formdata.findIndex((header) => header.id === index);
      let result;
      // if (name === "formdatavalue") {
      //   let prevdata = formdata[headerIndex][name];
      //   console.log("prevdata", prevdata);
      //   if (prevdata) {
      //     result = prevdata.concat(".", value);
      //   } else {
      //     result = value;
      //   }
      //   const childData = fields_array_payload[e.target.value];
      //   if (Object.keys(childData).length > 0) {
      //     setfields_array_payload(childData);
      //     setapi_fields_payload(Object.keys(childData));
      //   }
      // } else {
      //   result = value;
      // }
      // list[fieldindex]["BucketField"] = result;
      // setfieldlist(list);

      formdata[headerIndex][name] = value;
      const updatedApi = { ...api, formdata: formdata };
      //console.log("updatedApi", updatedApi);
      setpayloadfields([
        ...payloadfields.slice(0, api_index),
        updatedApi,
        ...payloadfields.slice(api_index + 1),
      ]);
    }

    //console.log("childData", fields_array_payload);
  };

  const handleApiInput = (e, api_index = null, index) => {
    const { name, value } = e.target;
    const api = apidata[api_index];
    if (name === "outputvalue" || name === "outputkey") {
      const formdata = [...api.outputdata];
      const headerIndex = formdata.findIndex((header) => header.id === index);
      let result;
      formdata[headerIndex][name] = value;
      const updatedApi = { ...api, outputdata: formdata };
      //console.log("updatedApi", updatedApi);
      setapidata([
        ...apidata.slice(0, api_index),
        updatedApi,
        ...apidata.slice(api_index + 1),
      ]);
    } else if (name === "api_url") {
      const updatedApi = { ...api, api_url: value };
      setapidata([
        ...apidata.slice(0, api_index),
        updatedApi,
        ...apidata.slice(api_index + 1),
      ]);
    } else if (name === "action_name") {
      const updatedApi = { ...api, action_name: value };
      setapidata([
        ...apidata.slice(0, api_index),
        updatedApi,
        ...apidata.slice(api_index + 1),
      ]);
    } else if (name === "inputType") {
      const updatedApi = { ...api, inputType: value };
      setapidata([
        ...apidata.slice(0, api_index),
        updatedApi,
        ...apidata.slice(api_index + 1),
      ]);
    } else if (name === "formrow") {
      const updatedApi = { ...api, formrow: value };
      setapidata([
        ...apidata.slice(0, api_index),
        updatedApi,
        ...apidata.slice(api_index + 1),
      ]);
    } else if (name === "getoutputdata") {
      const updatedApi = { ...api, getoutputdata: value };
      setapidata([
        ...apidata.slice(0, api_index),
        updatedApi,
        ...apidata.slice(api_index + 1),
      ]);
    } else {
      const formdata = [...api.formdata];
      const headerIndex = formdata.findIndex((header) => header.id === index);
      let result;
      formdata[headerIndex][name] = value;
      const updatedApi = { ...api, formdata: formdata };
      //console.log("updatedApi", updatedApi);
      setapidata([
        ...apidata.slice(0, api_index),
        updatedApi,
        ...apidata.slice(api_index + 1),
      ]);
    }

    //console.log("childData", fields_array_payload);
  };
  const handleInputChange = (e, index, api_index = null) => {
    const { name, value } = e.target;
    if (api_index === null) {
      const list = [...fieldlist];
      list[index][name] = value;
      setfieldlist(list);

      // console.log(fieldlist);
      props.location.state &&
        props.location.state.fieldslist.map((item) => {
          // if(item.BucketField == )
          fieldlist.map((fl) => {
            if (item.BucketField === fl.BucketField) {
              item.fieldname = fl.fieldname;
            }
          });
        });

      if (props.location.state) {
        props.location.state.view_name = view_name;
      }
      // console.log(effetive_designation_id);
      // console.log(props.location.state);
    }
  };
  const handleInputfieldChange = (e, index, api_index = null) => {};
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...fieldlist];
    list.splice(index, 1);
    setfieldlist(list);

    props.location.state && props.location.state.fieldslist.splice(index, 1);
  };

  const handleRemoveSearch = (index) => {
    const list = [...searchKey];
    list.splice(index, 1);
    setsearchKey(list);
  };
  const handleRemovepayloadfieldsSearch = (index) => {
    const list = [...searchKeygetpayloadfields];
    list.splice(index, 1);
    setsearchKeygetpayloadfields(list);
  };

  const handleRemoveTable = (fieldIndex, tableDataId) => {
    const updatedFieldList = apidata.map((field, index) => {
      if (index === fieldIndex) {
        field.tabledata = field.tabledata.filter(
          (tableData) => tableData.id !== tableDataId
        );
      }
      return field;
    });
    setapidata(updatedFieldList);
  };

  const handleAddTableData = (fieldIndex) => {
    let length = apidata[fieldIndex].tabledata.length + 1;
    const updatedFieldList = apidata.map((field, index) => {
      if (index === fieldIndex) {
        field.tabledata.push({ id: length, tablekey: "", tablevalue: "" });
      }
      return field;
    });
    setapidata(updatedFieldList);
  };

  const getData = (finalArray) => {
    // console.log(finalArray);
    // console.log(finalArray.length);
    // if(finalArray.length > 0){
    // save_design_flag = false;
    // setsave_design_flag(false);
    // setfinalArray(finalArray);
    // }
    // console.log(save_design_flag);
  };

  const saveConfig = () => {
    let apitoken = localStorage.getItem("api_token");
    const form = new FormData();

    // console.log(finalArray);
    // return;

    // console.log(JSON.stringify(fieldlist));
    // return;
    let search_by_pairdata;
    if (searchKey) {
      search_by_pairdata = searchKey.map((item) => [
        item.searchKey,
        item.operator,
        item.searchValue,
      ]);
    }

    form.append("bucket_name", bucket_name);
    form.append("event_name", event_name);
    form.append("view_name", view_name);
    form.append("field_mapping", JSON.stringify(fieldlist));
    form.append("action_mapping", JSON.stringify(divs));
    form.append("status", status);
    form.append("get_api_mapping", JSON.stringify(apidata));
    form.append("effective_designation_id", effetive_designation_id);
    form.append("event_location", event_location);
    form.append("payload_type", select_payload);
    form.append("fetch_onload", fetch_onload);
    if (record_per_call) {
      form.append("record_per_call", parseInt(record_per_call));
    }
    if (hide_loadmore) {
      form.append("hide_loadmore", hide_loadmore);
    }
    if (get_data_by) {
      form.append("get_data_by", get_data_by);
    }
    if (setupdated_at) {
      form.append("setupdated_at", setupdated_at);
    }
    if (agg_view) {
      form.append("agg_view", agg_view);
    }
    if (view_id != agg_view) {
      form.append("view_id", view_id);
      if (editsearchKey) {
        form.append("getpayloadfields", editsearchKey);
      }
    } else {
      if (mongo_combinesearch) {
        form.append("getpayloadfields", JSON.stringify(mongo_combinesearch));
      } else {
        form.append("getpayloadfields", "");
      }
    }
    if (search_by_pairdata) {
      form.append("search_by_pair", JSON.stringify(search_by_pairdata));
    } else {
      form.append("search_by_pair", []);
    }
    if (db_name) {
      form.append("db_name", db_name);
    }
    if (collection_name) {
      form.append("collection_name", collection_name);
    }
    if (db_namegetpayload) {
      form.append("db_namegetpayload", db_namegetpayload);
    }
    if (collection_namegetpayload) {
      form.append("collection_namegetpayload", collection_namegetpayload);
    }
    if (datasource) {
      form.append("datasource", JSON.stringify(datasource));
    }
    if (payloadfields) {
      form.append("payloadfields", JSON.stringify(payloadfields));
    }
    if (outputfilter) {
      form.append("outputfilter", outputfilter);
    }
    if (payloaddataoutputformat) {
      form.append("payloaddataoutputformat", payloaddataoutputformat);
    }
    if (sourcedataoutputformat) {
      form.append("sourcedataoutputformat", sourcedataoutputformat);
    }

    if (view_type) {
      form.append("view_type", view_type);
    }
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/store/webhook/configuration`,
        method: "POST",
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          "api-token": apitoken,
        },
      },
      (response) => {
        // props.location.state.component_mapping = finalArray;
        setsave_design_flag(true);
        console.log(props.location.state);
        setisErrOpen(true);
        setapi_error_message(response[0].message);
      }
    );
  };
  const removeDiv = (i) => {
    const list = [...divs];
    list.splice(i, 1);
    setDivs(list);
  };
  const removeGetApi = (i) => {
    const list = [...apidata];
    list.splice(i, 1);
    setapidata(list);
  };

  // const getEventList = (e) => {
  //   setbucket_name(e.target.value);
  //   setevent_name("");
  //   let apitoken = localStorage.getItem("api_token");
  //   //    const form = new FormData();
  //   sendRequest(
  //     {
  //       url: `${process.env.REACT_APP_BASE_URL}/payload/getfields/${e.target.value}`,
  //       method: "POST",
  //       //data: inputdata,
  //       headers: {
  //         "Content-Type": "application/json",
  //         "api-token": apitoken,
  //       },
  //     },
  //     (response) => {
  //       //setgetfields(response);

  //       var jsonkey = Object.keys(response);

  //       setfields_list(jsonkey);
  //     }
  //   );
  // };
  const getFieldList = (istempsearch = 0) => {
    let url = "";
    let form;
    console.log("select_payload", select_payload);
    // if (select_payload == "B") {
    //   url = `${process.env.REACT_APP_BASE_URL}/payload/get/${bucket_name}?limit=1`;
    //   // if (searchKey && searchValue) {
    //   //   form = JSON.stringify([
    //   //     [event_location, "==", event_name, searchKey, "==", searchValue],
    //   //   ]);
    //   // } else {
    //   //   form = JSON.stringify([[event_location, "==", event_name]]);
    //   // }

    //   let searchinput;
    //   let formattedSearchKey;
    //   if (istempsearch == 1) {
    //     searchinput = tempsearchKey;
    //     formattedSearchKey = searchinput.map((item) => [
    //       item.searchKey,
    //       item.operator,
    //       convertToInputType(item.searchValue, item.inputType),
    //     ]);
    //   } else {
    //     searchinput = searchKey;
    //     formattedSearchKey = searchinput.map((item) => [
    //       item.searchKey,
    //       item.operator,
    //       convertToInputType(item.searchValue, item.inputType),
    //     ]);
    //   }
    //   console.log("searchinput->", formattedSearchKey);
    //   form = JSON.stringify(formattedSearchKey);
    //   if (formattedSearchKey[0][0] != "") {
    //     setmongo_combinesearch(formattedSearchKey);
    //   } else {
    //     setmongo_combinesearch("");
    //   }
    // }
    // if (select_payload == "M") {
    if (select_payload == "A") {
      if (payloadfields[0].url_type == "N") {
        url = `${process.env.REACT_APP_BASE_URL}/${payloadfields[0].api_url}`;
      } else {
        url = payloadfields[0].api_url;
      }

      const api = payloadfields[0];
      form = new FormData();

      api.formdata.forEach((item) => {
        form.append(item.formdatakey, item.formdatavalue);
      });

      for (let pair of form.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }
    } else {
      url = `${process.env.REACT_APP_BASE_URL}/mongo/get/${db_namegetpayload}/${collection_namegetpayload}?limit=1&offset=0`;
      let searchinput;
      console.log("istempsearch->", istempsearch);
      let formattedSearchKey;
      if (istempsearch == 1) {
        searchinput = tempsearchKey;
        formattedSearchKey = searchinput.map((item) => [
          item.searchKey,
          item.operator,
          item.searchValue,
        ]);
      } else {
        searchinput = searchKeygetpayloadfields;
        formattedSearchKey = searchinput.map((item) => [
          item.searchKey,
          item.operator,
          item.searchValue,
        ]);
      }
      let finalArray;
      if (istempsearch == 1) {
        finalArray = formattedSearchKey;
      } else {
        if (formattedSearchKey[0][0] != "") {
          finalArray = [
            ["processed", "exists", processedis],
            ...formattedSearchKey,
          ];
        } else {
          finalArray = [["processed", "exists", processedis]];
        }
      }
      console.log("finalArray->", finalArray);
      setmongo_combinesearch(finalArray);
      form = JSON.stringify(finalArray);
    }
    let apitoken = localStorage.getItem("api_token");

    console.log("FormData entries:", form);
    sendRequest(
      {
        url: url,
        method: "POST",
        data: form,
        headers: {
          "Content-Type": "application/json",
          "api-token": apitoken,
        },
      },
      (json) => {
        let response;
        if (
          (payloadfields && payloadfields[0].outputformat != undefined) ||
          (payloaddataoutputformat && payloaddataoutputformat != undefined)
        ) {
          console.log(
            "payloadfields[0].outputformat",
            json[payloadfields[0].outputformat]
          );
          const outputFormatPath =
            payloadfields[0].outputformat || payloaddataoutputformat;
          response = getNestedValue(json, outputFormatPath);
          if (!Array.isArray(response)) {
            response = [response];
          }
        } else {
          response = json;
        }
        console.log("response--", response);
        setget_bucketfield(false);
        //setgetfields(response);
        var jsonkey = Object.keys(response);

        const childKey = jsonkey[0];
        setfields_array(response[childKey]);
        setfields_array_payload(response[childKey]);

        setdefault_payload(response);
        const childData = response[childKey];

        setapi_fields_list(Object.keys(childData));
        setapi_fields_payload(Object.keys(childData));

        if (!view_id) {
          console.log(view_name);
          {
            /* AT-2072 | Vaibhav Sawant | 06-09-2023 | Setting props value for PayloadTile */
          }
          props.location.state.fieldslist = fieldlist;
          // props.location.state.payloadTile = true;
          props.location.state.bucket_name = bucket_name;
          props.location.state.event_name = event_name;
          // props.location.state.view_name = view_name;
          props.location.state.field_mapping = JSON.stringify(fieldlist);
          props.location.state.action_mapping = JSON.stringify(divs);
          props.location.state.status = status;
          props.location.state.get_api_mapping = JSON.stringify(apidata);
          // props.location.state.effective_designation_id = effetive_designation_id;
          // form.append("bucket_name", bucket_name);
          // form.append("event_namefield_mapping", event_name);
          // form.append("view_name", view_name);
          // form.append("field_mapping", JSON.stringify(fieldlist));
          // form.append("action_mapping", JSON.stringify(divs));
          // form.append("status", status);
          // form.append("get_api_mapping", JSON.stringify(apidata));
          // form.append("effective_designation_id", effetive_designation_id);
          // handleFieldListForPayloadTile(Object.keys(childData));
          //   // console.log(fieldlist);
          //   // console.log(Object.keys(childData));
          //   // console.log(localStorage);
        }
      }
    );
  };

  const getNestedValue = (obj, path) => {
    return path
      .split(/[\[\]\.]+/)
      .filter(Boolean)
      .reduce((acc, part) => acc && acc[part], obj);
  };
  const handlechangeisRequired = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["isRequired"] = e.target.value;
      setfieldlist(list);
    }
  };

  const handleChangeLabel = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      // console.log(...fieldlist);
      const list = [...fieldlist];
      list[fieldindex]["showLabel"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeisVisible = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["isVisible"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeisSearch = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["isSearch"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangedefaultfilter = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["defaultfilter"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangemassupdate = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["massupdate"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeaddapi = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["addapi"] = e.target.value;
      setfieldlist(list);
    }
  };

  const handlechangeselectapi = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["selectapi"] = e.target.value;
      setfieldlist(list);
    }
  };

  const handlechangeisConfirm = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["isConfirm"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeisEditDisable = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["isEditDisable"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeisRedirect = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["isRedirect"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeRedirectData = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["RedirectData"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeRedirectCondition = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["RedirectCondition"] = e.target.value;
      setfieldlist(list);
    }
  };
  // const handlechangeDisableOnField = (e, fieldindex, api_index = null) => {
  //   if (api_index === null) {
  //     const list = [...fieldlist];
  //     list[fieldindex]["DisableOnField"] = e.target.value;
  //     setfieldlist(list);
  //   }
  // };
  const handlechangeDisableOnFieldValue = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["DisableOnFieldValue"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeFieldLength = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["FieldLength"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangedropdownlist = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["dropdownlist"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeDownloadfile = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["Downloadfile"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeexprow = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["exprow"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeproductlist = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["productlist"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangetenure = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["tenure"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangeproductid = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["productid"] = e.target.value;
      setfieldlist(list);
    }
  };
  const handlechangedatatype = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["datatype"] = e.target.value;
      setfieldlist(list);
    }
  };

  const handlechangetablekey = (e, fieldindex, api_index) => {
    const list = [...apidata];
    list[fieldindex]["tabledata"][api_index]["tablekey"] = e.target.value;
    setapidata(list);
  };

  const handlechangetablevalue = (e, fieldindex, api_index) => {
    const list = [...apidata];
    list[fieldindex]["tabledata"][api_index]["tablevalue"] = e.target.value;
    setapidata(list);
  };

  const handlechangeisEdit = (e, fieldindex, api_index = null) => {
    if (api_index === null) {
      const list = [...fieldlist];
      list[fieldindex]["isEdit"] = e.target.value;
      setfieldlist(list);
    }
  };

  const handlechangebucketfield = (
    e,
    fieldindex,
    bucketFieldIndex,
    fieldvalue,
    api_index = null
  ) => {
    if (api_index === null) {
      const list = [...fieldlist];
      let prevdata = list[fieldindex]["BucketField"];
      let result;
      if (prevdata) {
        result = prevdata.concat(".", e.target.value);
      } else {
        result = e.target.value;
      }

      list[fieldindex]["BucketField"] = result;
      setfieldlist(list);
    }
    // const updatedFields = [...fieldlist];
    // let prevdata =
    //   updatedFields[fieldindex].BucketField[bucketFieldIndex].BuckeSubtField[
    //     fieldvalue
    //   ].value;
    // let result;
    // if (prevdata) {
    //   result = prevdata.concat(".", e.target.value);
    // } else {
    //   result = e.target.value;
    // }

    // updatedFields[fieldindex].BucketField[bucketFieldIndex].BuckeSubtField[
    //   fieldvalue
    // ].value = result;
    // setfieldlist(updatedFields);

    const childData = fields_array[e.target.value];

    if (childData && Object.keys(childData).length > 0) {
      setfields_array(childData);
      setapi_fields_list(Object.keys(childData));
    }

    // if (!view_id) {
    // console.log(props.location.state.fieldslist);
    // console.log(fieldindex);
    if (props.location.state) {
      props.location.state.fieldslist[fieldindex].BucketField = e.target.value;
    }
    if (props.location.state) {
      props.location.state.effective_designation_id = effetive_designation_id;
    }
    // });
    //   props.location.state.fieldslist = fieldlist;
    //   console.log(props.location.state);
    //   props.location.state.payloadTile = true
    // }
  };
  const handleRemovebucketfield = (
    fieldindex,
    bucketFieldIndex,
    subfieldindex
  ) => {
    const updatedFields = [...fieldlist];
    updatedFields[fieldindex].BucketField[
      bucketFieldIndex
    ].BuckeSubtField.splice(subfieldindex, 1);
    setfieldlist(updatedFields);
  };
  const handleAddbucketfield = (fieldindex, bucketFieldIndex) => {
    const newBucketSubField = {
      id:
        fieldlist[fieldindex].BucketField[bucketFieldIndex].BuckeSubtField
          .length,
      value: "",
    };
    const updatedFields = [...fieldlist];
    updatedFields[fieldindex].BucketField[bucketFieldIndex].BuckeSubtField.push(
      newBucketSubField
    );
    //setFields(updatedFields);
    setfieldlist(updatedFields);
  };

  const setQuerydata = (q) => {
    setQuery(q);
    const updatedData = [...fieldlist];
    if (updatedData.length > 0) {
      updatedData[0].search_query = q;
      setfieldlist(updatedData);
    }
  };
  const formatQuerybuild = (query) => {
    if (!query) return {};
    const mongoQuery = {};
    if (query.combinator === "and") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$and = query.rules.map(formatQuerybuild);
      }
    } else if (query.combinator === "or") {
      if (query.rules && query.rules.length > 0) {
        mongoQuery.$or = query.rules.map(formatQuerybuild);
      }
    }

    if (query.field && query.operator) {
      const field = query.field;
      const operator = query.operator;
      const value = query.value;

      if (operator === "=") {
        mongoQuery[field] = value;
      } else if (operator === "!=") {
        mongoQuery[field] = { $ne: value };
      } else if (operator === ">") {
        mongoQuery[field] = { $gt: value };
      } else if (operator === "<") {
        mongoQuery[field] = { $lt: value };
      } else if (operator === ">=") {
        mongoQuery[field] = { $gte: value };
      } else if (operator === "<=") {
        mongoQuery[field] = { $lte: value };
      }

      if (operator === "exists") {
        mongoQuery[field] = {
          $exists: value.toLowerCase() == "true" ? true : false,
        };
      }
    }

    return mongoQuery;
  };

  // const formatRule = (rule) => {
  //   const field = rule.field;
  //   const operator = rule.operator;
  //   const value = rule.value;

  //   switch (operator) {
  //     case "exists":
  //       return {
  //         [field]: { $exists: value },
  //       };
  //     case "=":
  //       return {
  //         [field]: value,
  //       };
  //     case "!=":
  //       return {
  //         [field]: { $ne: value },
  //       };
  //     case ">":
  //       return {
  //         [field]: { $gt: value },
  //       };
  //     case "<":
  //       return {
  //         [field]: { $lt: value },
  //       };
  //     case ">=":
  //       return {
  //         [field]: { $gte: value },
  //       };
  //     case "<=":
  //       return {
  //         [field]: { $lte: value },
  //       };
  //     default:
  //       return {}; // Handle other operators as needed
  //   }
  // };

  const customOperators = {
    exists: {
      label: "Exists",
      type: "unary",
      compile: (field, operator, value) => {
        return { [field]: { $exists: true } };
      },
    },
  };

  const convertToInputType = (value, inputType) => {
    switch (inputType) {
      case "string":
        return String(value);
      case "float":
        return parseFloat(value);
      case "int":
        return parseInt(value, 10) | 0;
      // Add more cases for other input types as needed
      default:
        return value;
    }
  };

  const setsearchKeydata = (e, i) => {
    const list = [...searchKey];
    let value;
    if (list[i]["inputType"]) {
      value = convertToInputType(e.target.value, list[i]["inputType"]);
    } else {
      value = convertToInputType(e.target.value, "string");
    }

    list[i][e.target.name] = value;
    setsearchKey(list);
    console.log("searchKey", searchKey);
  };

  const setsearchKeypayloadfieldsdata = (e, i) => {
    const list = [...searchKeygetpayloadfields];
    let value;
    if (list[i]["inputType"]) {
      value = convertToInputType(e.target.value, list[i]["inputType"]);
    } else {
      value = convertToInputType(e.target.value, "string");
    }

    list[i][e.target.name] = value;
    setsearchKeygetpayloadfields(list);
  };

  const settemp_searchKey = (e) => {
    console.log("settempsearchKey->", e);
    const list = [...tempsearchKey];

    let value;
    if (list[0]["inputType"]) {
      value = convertToInputType(e.target.value, list[0]["inputType"]);
    } else {
      value = convertToInputType(e.target.value, "string");
    }

    list[0][e.target.name] = value;
    settempsearchKey(list);
  };
  const getcollection = (dbName) => {
    setdb_name(dbName);
    const filtered = db_list.filter((item) => item.db_name === dbName);
    setcollection_list(filtered[0].collections);
  };
  const getpayloadfieldscollection = (dbName) => {
    setdb_namegetpayload(dbName);
    const filtered = db_list.filter((item) => item.db_name === dbName);
    setcollection_listgetpayloadfields(filtered[0].collections);
  };
  console.log("searchKeygetpayloadfields", divs);
  return (
    <div className="container">
      <Commonheader pagetitle={"Create View"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>
      <div
        className={
          isMobileview
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisMobileview(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {isMobileview && (
              <PayloadTile
                data={props.location.state}
                component_mapping={component_mapping}
                create_view={getData}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={
          get_bucketfield
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setget_bucketfield(false);
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">
              <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">Key</label>
                  <PEInput
                    element_id="create_view_searchkey"
                    className="form-control"
                    name="searchKey"
                    //Ankur Makavana | 18-09-2023 | To add filters & sorting of fields on the payload screen
                    value={tempsearchKey.searchKey}
                    onChange={(e) => settemp_searchKey(e)}
                  />
                </div>
                <PEDiv
                  element_id="create_view_operator"
                  className="form-group col-md-2"
                >
                  <label htmlFor="inputEmail4">Operator</label>
                  <select
                    className="form-control"
                    name="operator"
                    onChange={(e) => settemp_searchKey(e)}
                  >
                    <option value="">select</option>
                    <option value="==">==</option>
                    <option value="exists">exists</option>
                    <option value=">">&#62;</option>
                    <option value="<">&#60;</option>
                    <option value="!=">!=</option>
                  </select>
                </PEDiv>
                <PEDiv
                  element_id="create_view_inputtype"
                  className="form-group col-md-2"
                >
                  <label htmlFor="inputEmail4">Input Type</label>
                  <select
                    className="form-control"
                    name="inputType"
                    value={tempsearchKey.inputType}
                    onChange={(e) => settemp_searchKey(e)}
                  >
                    <option value="string">String</option>
                    <option value="int">Int</option>
                    <option value="float">Float</option>
                  </select>
                </PEDiv>
                <PEDiv
                  element_id="create_view_value"
                  className="form-group col-md-3"
                >
                  <label htmlFor="inputEmail4">Value</label>
                  <input
                    className="form-control"
                    name="searchValue"
                    //Ankur Makavana | 18-09-2023 | To add filters & sorting of fields on the payload screen
                    value={tempsearchKey.searchValue}
                    onChange={(e) => settemp_searchKey(e)}
                    //onBlur={() => getFieldList()}
                  />
                </PEDiv>
                <PEDiv
                  element_id="create_view_search"
                  className="form-group  col-md-2"
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      getFieldList("1");
                    }}
                  >
                    search
                  </button>
                </PEDiv>
              </div>
            </p>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="viewname-holder">
          <div className="row">
            <PEDiv
              element_id="create_view_viewname"
              className="form-group col-md-3"
            >
              <label htmlFor="inputEmail4">View Name</label>
              <input
                className="form-control"
                id="inputEmail4"
                placeholder="View Name"
                name="view_name"
                value={view_name}
                onChange={(e) => setview_name(e.target.value)}
              />
            </PEDiv>
            <PEDiv
              element_id="create_view_designationid"
              className="form-group col-md-3"
            >
              <label htmlFor="inputEmail4">Designation ID</label>
              <select
                className="form-control"
                name="effetive_designation_id"
                //Ankur Makavana | 04-08-2023 | Disable Bucket, Event and Designation id on edit
                disabled={view_id}
                value={effetive_designation_id}
                onChange={(e) => seteffetive_designation_id(e.target.value)}
              >
                <option value="">Select Designation ID</option>
                {effetive_designation_idList &&
                  effetive_designation_idList.map((eid) => (
                    <>
                      <option value={eid.effective_designation_id}>
                        {eid.designation_name}
                      </option>
                    </>
                  ))}
              </select>
            </PEDiv>
            <PEDiv
              element_id="create_view_payloadform"
              className="form-group col-md-3"
            >
              <label htmlFor="inputEmail4">Select Payload From</label>
              <select
                className="form-control"
                name="select_payload"
                //Ankur Makavana | 02-09-2023 | select payload from bucket or mongo
                disabled={view_id}
                value={select_payload}
                onChange={(e) => setselect_payload(e.target.value)}
              >
                <option value="">Select</option>
                <>
                  <option value="B">Bucket</option>
                  <option value="M">Mongo</option>
                  <option value="A">API</option>
                </>
              </select>
            </PEDiv>
            <PEDiv
              element_id="create_view_defaultsearch"
              className="form-group col-md-3"
            >
              <label>Default Search</label>
              <select
                className="form-control"
                name="fetch_onload"
                //Ankur Makavana | 02-09-2023 | select payload from bucket or mongo
                value={fetch_onload}
                onChange={(e) => setfetch_onload(e.target.value)}
              >
                <option value="">Select</option>
                <>
                  <option value="false">False</option>
                  <option value="true">True</option>
                </>
              </select>
            </PEDiv>
            <div className="form-group col-md-3">
              <label>Max Record per request</label>
              <input
                className="form-control"
                type="number"
                name="record_per_call"
                value={record_per_call}
                onChange={(e) => setrecord_per_call(e.target.value)}
              />
            </div>
            <div className="form-group col-md-3">
              <label>Hide Load More</label>
              <select
                className="form-control"
                name="hide_loadmore"
                value={hide_loadmore}
                onChange={(e) => sethide_loadmore(e.target.value)}
              >
                <option value="">Select</option>
                <>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </>
              </select>
            </div>
            <div className="form-group col-md-3">
              <label>Get Data Response</label>
              <select
                className="form-control"
                name="get_data_by"
                value={get_data_by}
                onChange={(e) => setget_data_by(e.target.value)}
              >
                <option value="">Select</option>
                <option value="P">Payload</option>
                <option value="C">Column</option>
              </select>
            </div>
            <div className="form-group col-md-3">
              <label>Store UpdatedAt</label>
              <select
                className="form-control"
                name="setupdated_at"
                value={setupdated_at}
                onChange={(e) => setsetupdated_at(e.target.value)}
              >
                <option value="">Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">View Type</label>
              <select
                name="viewtype"
                className="form-control"
                value={view_type}
                onChange={(e) => setview_type(e.target.value)}
              >
                <option value="">Select Type</option>
                <option value="NV">Normal View</option>
                <option value="AV">Aggregate View</option>
              </select>
            </div>
            {view_type == "AV" && (
              <>
                <div className="form-group col-md-3">
                  <label>Select View</label>
                  <select
                    name="agg_view"
                    className="form-control"
                    value={agg_view}
                    onChange={(e) => setagg_view(e.target.value)}
                  >
                    <option value="">Select View</option>
                    {view_list &&
                      view_list.map((view) => (
                        <option value={view.view_id}>{view.view_name}</option>
                      ))}
                  </select>
                </div>
              </>
            )}
          </div>
          {select_payload && (
            <div className="block-shadow">
              <h3 className="text-center">Data Source</h3>
              {select_payload == "M" && (
                <div className="row">
                  <PEDiv
                    element_id="create_view_processed"
                    className="form-group col-md-3"
                  >
                    <label>Processed is</label>
                    <select
                      className="form-control"
                      name="processedis"
                      //Ankur Makavana | 02-09-2023 | select payload from bucket or mongo
                      disabled={view_id}
                      value={processedis}
                      onChange={(e) => setprocessedis(e.target.value)}
                    >
                      <option value="">Select</option>
                      <>
                        <option value="false">False</option>
                        <option value="true">True</option>
                      </>
                    </select>
                  </PEDiv>
                  <PEDiv
                    element_id="create_view_dbname"
                    className="form-group col-md-3"
                  >
                    <label>DB Name</label>
                    <select
                      className="form-control"
                      name="db_name"
                      value={db_name}
                      disabled={view_id}
                      onChange={(e) => getcollection(e.target.value)}
                    >
                      <option value="">Select DB</option>
                      {db_list &&
                        db_list.map((dbdata) => (
                          <option value={dbdata.db_name}>
                            {dbdata.db_name}
                          </option>
                        ))}
                    </select>
                  </PEDiv>
                  <PEDiv
                    element_id="create_view_collection"
                    className="form-group col-md-3"
                  >
                    <label>Collection Name</label>
                    {/* <input
                    className="form-control"
                    name="collection_name"
                    onChange={(e) => setcollection_name(e.target.value)}
                   /> */}
                    <select
                      className="form-control"
                      name="db_name"
                      value={collection_name}
                      disabled={view_id}
                      onChange={(e) => setcollection_name(e.target.value)}
                    >
                      <option value="">Select Collection</option>
                      {collection_list &&
                        collection_list.map((colnm) => (
                          <option value={colnm}>{colnm}</option>
                        ))}
                    </select>
                  </PEDiv>
                </div>
              )}
              {select_payload == "B" && (
                <>
                  <div className="row">
                    <PEDiv
                      element_id="create_view_bucket"
                      className="form-group col-md-3"
                    >
                      <label htmlFor="inputEmail4">Bucket</label>
                      <select
                        className="form-control"
                        name="bucket_name"
                        value={bucket_name}
                        //Ankur Makavana | 04-08-2023 | Disable Bucket, Event and Designation id on edit
                        disabled={view_id}
                        onChange={(e) => {
                          setbucket_name(e.target.value);
                          setevent_name("");
                          setevent_location("");
                        }}
                      >
                        <option value="">Select Bucket</option>
                        {bucket_list &&
                          bucket_list.map((bucket, index) => (
                            <option value={bucket}>{bucket}</option>
                          ))}
                      </select>
                    </PEDiv>
                    <PEDiv
                      element_id="create_view_eventlocation"
                      className="form-group col-md-3"
                    >
                      <label htmlFor="inputEmail4">Event Location</label>

                      <input
                        className="form-control"
                        name="event_location"
                        //Ankur Makavana | 04-08-2023 | Disable Bucket, Event and Designation id on edit
                        disabled={view_id}
                        value={event_location}
                        onChange={(e) => setevent_location(e.target.value)}
                      />
                    </PEDiv>
                    <PEDiv
                      element_id="create_view_event"
                      className="form-group col-md-3"
                    >
                      <label htmlFor="inputEmail4">Event</label>

                      <input
                        className="form-control"
                        name="event_name"
                        //Ankur Makavana | 04-08-2023 | Disable Bucket, Event and Designation id on edit
                        disabled={view_id}
                        value={event_name}
                        onChange={(e) => setevent_name(e.target.value)}
                        //onBlur={() => getFieldList()}
                      />
                    </PEDiv>
                  </div>
                  <PEDiv
                    element_id="create_view_specificpay"
                    className="row justify-content-center"
                  >
                    <strong>OR Search Specific Payload</strong>
                  </PEDiv>
                </>
              )}
              {select_payload != "A" && (
                <>
                  <div className="row justify-content-center">
                    {searchKey &&
                      searchKey.map((searchdata, i) => (
                        <>
                          <PEDiv
                            element_id="create_view_key"
                            className="form-group col-md-3"
                          >
                            <label htmlFor="inputEmail4">Key</label>
                            <input
                              className="form-control"
                              name="searchKey"
                              //Ankur Makavana | 18-09-2023 | To add filters & sorting of fields on the payload screen
                              disabled={view_id}
                              value={searchdata.searchKey}
                              onChange={(e) => setsearchKeydata(e, i)}
                            />
                          </PEDiv>
                          <PEDiv
                            element_id="create_view_operator"
                            className="form-group col-md-2"
                          >
                            <label htmlFor="inputEmail4">Operator</label>
                            <select
                              className="form-control"
                              name="operator"
                              disabled={view_id}
                              onChange={(e) => setsearchKeydata(e, i)}
                              value={searchdata.operator}
                            >
                              <option value="">select</option>
                              <option value="==">==</option>
                              <option value="exists">exists</option>
                              <option value=">">&#62;</option>
                              <option value="<">&#60;</option>
                              <option value="!=">!=</option>
                            </select>
                          </PEDiv>
                          <PEDiv
                            element_id="create_view_iptype"
                            className="form-group col-md-2"
                          >
                            <label htmlFor="inputEmail4">Input Type</label>
                            <select
                              className="form-control"
                              name="inputType"
                              disabled={view_id}
                              value={searchdata.inputType}
                              onChange={(e) => setsearchKeydata(e, i)}
                            >
                              <option value="string">String</option>
                              <option value="int">Int</option>
                              <option value="float">Float</option>
                            </select>
                          </PEDiv>
                          <PEDiv
                            element_id="create_view_value"
                            className="form-group col-md-3"
                          >
                            <label htmlFor="inputEmail4">Value</label>
                            <input
                              className="form-control"
                              name="searchValue"
                              //Ankur Makavana | 18-09-2023 | To add filters & sorting of fields on the payload screen
                              disabled={view_id}
                              value={searchdata.searchValue}
                              onChange={(e) => setsearchKeydata(e, i)}
                              //onBlur={() => getFieldList()}
                            />
                          </PEDiv>

                          {!view_id && (
                            <div className="btn-box">
                              <div className="form-group col-md-2">
                                {searchKey.length !== 1 && (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => handleRemoveSearch(i)}
                                  >
                                    -
                                  </button>
                                )}
                                {searchKey.length - 1 === i && (
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleAddsearch}
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="col-md-12">
                            <label htmlFor="inputEmail4">Output Format</label>
                            <input
                              className="form-control"
                              name="sourcedataoutputformat"
                              onChange={(e) =>
                                setsourcedataoutputformat(e.target.value)
                              }
                              placeholder="payload[0].hhdata"
                              value={sourcedataoutputformat}
                            />
                          </div>
                          <div className="col-md-12">
                            <label htmlFor="inputEmail4">Output Filter</label>
                            <input
                              className="form-control"
                              name="outputfilter"
                              onChange={(e) => setoutputfilter(e.target.value)}
                              placeholder="payload[0].hhdata!='',payload[0].hhdata!=''"
                              value={outputfilter}
                            />
                          </div>
                        </>
                      ))}
                  </div>
                </>
              )}
              {select_payload == "A" && (
                <>
                  {datasource.map((x, i) => {
                    return (
                      <div key={i} className="row">
                        <div className="form-group col-md-6">
                          <label>Url Type</label>
                          <select
                            className="form-control"
                            name="url_type"
                            value={x.url_type}
                            onChange={(e) => handlesourceInputAPI(e, i)}
                          >
                            <option value="">select</option>
                            <option value="B">With Base URL</option>
                            <option value="N">Without Base URL</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">API URL</label>
                          <input
                            name="api_url"
                            className="form-control"
                            placeholder="Enter API URL"
                            value={x.api_url}
                            onChange={(e) => handlesourceInputAPI(e, i)}
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <label>Input Type</label>
                          <select
                            className="form-control"
                            name="inputType"
                            value={x.inputType}
                            onChange={(e) => handlesourceInputAPI(e, i)}
                          >
                            <option value="formdata">Form Data</option>
                            <option value="formrow">Row Data</option>
                          </select>
                        </div>

                        {x.inputType == "formdata" &&
                          x.formdata.map((head, index) => (
                            <>
                              <div className="form-group col-md-4" key={index}>
                                <label htmlFor="inputEmail4">
                                  Formdata Field
                                </label>
                                <input
                                  className="form-control"
                                  name="formdatakey"
                                  placeholder="Enter Header Field"
                                  value={head.formdatakey}
                                  onChange={(e) =>
                                    handlesourceInputAPI(e, i, head.id)
                                  }
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="inputEmail4">
                                  Formdata Value
                                </label>
                                <input
                                  className="form-control"
                                  name="formdatavalue"
                                  onChange={(e) =>
                                    handlesourceInputAPI(e, i, head.id)
                                  }
                                  placeholder="Enter Header Field"
                                  value={head.formdatavalue}
                                />
                              </div>
                              <div className="form-group col-md-2">
                                {x.formdata.length !== 1 && (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      removesoueceFormdata(x.id, head.id)
                                    }
                                  >
                                    -
                                  </button>
                                )}
                                {x.formdata.length - 1 === index && (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleAddSourcefield(i)}
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                            </>
                          ))}
                        {x.inputType == "formrow" && (
                          <div className="form-group col-md-12">
                            <label>
                              Use {"{{ user_id }}"} for user id and for other
                              fields use {"{{ fieldname }}"}
                            </label>
                            <textarea
                              name="formrow"
                              className="form-control"
                              placeholder="Enter Action Name"
                              onChange={(e) => handlesourceInputAPI(e, i)}
                              rows="10"
                            >
                              {x.formrow}
                            </textarea>
                          </div>
                        )}
                        <div className="col-md-12">
                          <label htmlFor="inputEmail4">Output Format</label>
                          <input
                            className="form-control"
                            name="outputformat"
                            onChange={(e) => handlesourceInputAPI(e, i)}
                            placeholder="payload[0].hhdata"
                            value={x.outputformat}
                          />
                        </div>
                        <div className="col-md-12">
                          <label htmlFor="inputEmail4">Output Filter</label>
                          <input
                            className="form-control"
                            name="outputfilter"
                            onChange={(e) => handlesourceInputAPI(e, i)}
                            placeholder="payload[0].hhdata!='',payload[0].hhdata!=''"
                            value={x.outputfilter}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <hr />
                  <h3 className="text-center">Get Payload Fields</h3>
                  {payloadfields.map((x, i) => {
                    return (
                      <div key={i} className="row">
                        <div className="form-group col-md-6">
                          <label>Url Type</label>
                          <select
                            className="form-control"
                            name="url_type"
                            value={x.url_type}
                            onChange={(e) => handlepayloadfieldsInputAPI(e, i)}
                          >
                            <option value="">select</option>
                            <option value="B">With Base URL</option>
                            <option value="N">Without Base URL</option>
                          </select>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputEmail4">API URL</label>
                          <input
                            name="api_url"
                            className="form-control"
                            placeholder="Enter API URL"
                            value={x.api_url}
                            onChange={(e) => handlepayloadfieldsInputAPI(e, i)}
                          />
                        </div>
                        <div className="form-group col-md-12">
                          <label>Input Type</label>
                          <select
                            className="form-control"
                            name="inputType"
                            value={x.inputType}
                            onChange={(e) => handlepayloadfieldsInputAPI(e, i)}
                          >
                            <option value="formdata">Form Data</option>
                            <option value="formrow">Row Data</option>
                          </select>
                        </div>

                        {x.inputType == "formdata" &&
                          x.formdata.map((head, index) => (
                            <>
                              <div className="form-group col-md-4" key={index}>
                                <label htmlFor="inputEmail4">
                                  Formdata Field
                                </label>
                                <input
                                  className="form-control"
                                  name="formdatakey"
                                  placeholder="Enter Header Field"
                                  value={head.formdatakey}
                                  onChange={(e) =>
                                    handlepayloadfieldsInputAPI(e, i, head.id)
                                  }
                                />
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="inputEmail4">
                                  Formdata Value
                                </label>
                                <input
                                  className="form-control"
                                  name="formdatavalue"
                                  onChange={(e) =>
                                    handlepayloadfieldsInputAPI(e, i, head.id)
                                  }
                                  placeholder="Enter Header Field"
                                  value={head.formdatavalue}
                                />
                              </div>
                              <div className="form-group col-md-2">
                                {x.formdata.length !== 1 && (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() =>
                                      removepayloadfieldsFormdata(x.id, head.id)
                                    }
                                  >
                                    -
                                  </button>
                                )}
                                {x.formdata.length - 1 === index && (
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleAddpayloadfields(i)}
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                            </>
                          ))}
                        {x.inputType == "formrow" && (
                          <div className="form-group col-md-12">
                            <label>
                              Use {"{{ user_id }}"} for user id and for other
                              fields use {"{{ fieldname }}"}
                            </label>
                            <textarea
                              name="formrow"
                              className="form-control"
                              placeholder="Enter Action Name"
                              onChange={(e) =>
                                handlepayloadfieldsInputAPI(e, i)
                              }
                              rows="10"
                            >
                              {x.formrow}
                            </textarea>
                          </div>
                        )}
                        <div className="col-md-12">
                          <label htmlFor="inputEmail4">Output Format</label>
                          <input
                            className="form-control"
                            name="outputformat"
                            onChange={(e) => handlepayloadfieldsInputAPI(e, i)}
                            placeholder="payload[0].hhdata"
                            value={x.outputformat}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          )}
          {select_payload && select_payload != "A" && (
            <PEDiv element_id="create_view_getpayload" className="block-shadow">
              <h3 className="text-center">Get Payload Fields</h3>
              <div className="row">
                <PEDiv
                element_id="create_view_getp_db"
                className="form-group col-md-3"
              >
                  <label>DB Name</label>
                  <select
                    className="form-control"
                    name="db_name"
                    value={db_namegetpayload}
                    disabled={view_id}
                    onChange={(e) => getpayloadfieldscollection(e.target.value)}
                  >
                    <option value="">Select DB</option>
                    {db_list &&
                      db_list.map((dbdata) => (
                        <option value={dbdata.db_name}>{dbdata.db_name}</option>
                      ))}
                  </select>
                </PEDiv>
                <PEDiv
                element_id="create_view_getp_coll"
                className="form-group col-md-3"
              >
                  <label>Collection Name</label>
                  {/* <input
                    className="form-control"
                    name="collection_name"
                    onChange={(e) => setcollection_name(e.target.value)}
                  /> */}
                  <select
                    className="form-control"
                    name="db_name"
                    value={collection_namegetpayload}
                    disabled={view_id}
                    onChange={(e) =>
                      setcollection_namegetpayload(e.target.value)
                    }
                  >
                    <option value="">Select Collection</option>
                    {collection_listgetpayloadfields &&
                      collection_listgetpayloadfields.map((colnm) => (
                        <option value={colnm}>{colnm}</option>
                      ))}
                  </select>
                </PEDiv>
              </div>
              <div className="row justify-content-center">
                {searchKeygetpayloadfields.map((searchdata, i) => (
                  <>
                    <div className="form-group col-md-3">
                      <label htmlFor="inputEmail4">Key</label>
                      <input
                        className="form-control"
                        name="searchKey"
                        //Ankur Makavana | 18-09-2023 | To add filters & sorting of fields on the payload screen
                        disabled={view_id}
                        value={searchdata.searchKey}
                        onChange={(e) => setsearchKeypayloadfieldsdata(e, i)}
                      />
                    </div>
                    <PEDiv
                    element_id="create_view_getp_operator"
                    className="form-group col-md-2"
                  >
                      <label htmlFor="inputEmail4">Operator</label>
                      <select
                        className="form-control"
                        name="operator"
                        disabled={view_id}
                        onChange={(e) => setsearchKeypayloadfieldsdata(e, i)}
                        value={searchdata.operator}
                      >
                        <option value="">select</option>
                        <option value="==">==</option>
                        <option value="exists">exists</option>
                        <option value=">">&#62;</option>
                        <option value="<">&#60;</option>
                        <option value="!=">!=</option>
                      </select>
                    </PEDiv>
                    <PEDiv
                    element_id="create_view_getp_input"
                    className="form-group col-md-2"
                  >
                      <label htmlFor="inputEmail4">Input Type</label>
                      <select
                        className="form-control"
                        name="inputType"
                        disabled={view_id}
                        value={searchdata.inputType}
                        onChange={(e) => setsearchKeypayloadfieldsdata(e, i)}
                      >
                        <option value="string">String</option>
                        <option value="int">Int</option>
                        <option value="float">Float</option>
                      </select>
                    </PEDiv>
                    <PEDiv
                    element_id="create_view_getp_val"
                    className="form-group col-md-3"
                  >
                      <label htmlFor="inputEmail4">Value</label>
                      <input
                        className="form-control"
                        name="searchValue"
                        //Ankur Makavana | 18-09-2023 | To add filters & sorting of fields on the payload screen
                        disabled={view_id}
                        value={searchdata.searchValue}
                        onChange={(e) => setsearchKeypayloadfieldsdata(e, i)}
                        //onBlur={() => getFieldList()}
                      />
                    </PEDiv>

                    {!view_id && (
                      <div className="btn-box">
                        <div className="form-group col-md-2">
                          {searchKey.length !== 1 && (
                            <PEButton
                              element_id="create_view_removepayload"
                            className="btn btn-danger"
                              onClick={() => handleRemovepayloadfieldsSearch(i)}
                            >
                              -
                            </PEButton>
                          )}
                          {searchKey.length - 1 === i && (
                            <PEButton
                              element_id="create_view_addpayload"
                            className="btn btn-primary"
                              onClick={handleAddpayloadfieldssearch}
                            >
                              +
                            </PEButton>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-md-12">
                      <label htmlFor="inputEmail4">Output Format</label>
                      <input
                        className="form-control"
                        name="payloaddataoutputformat"
                        onChange={(e) =>
                          setpayloaddataoutputformat(e.target.value)
                        }
                        placeholder="payload[0].hhdata"
                        value={payloaddataoutputformat}
                      />
                    </div>
                  </>
                ))}
              </div>
            </PEDiv>
          )}
          {/* {!view_id && (
            <PEButton
              element_id="create_view_getfield"
              className="btn btn-primary"
              onClick={() => getFieldList()}
            >
              Get Bucket Field
            </button>
          )} */}
          {view_id && select_payload != "A" ? (
            <div className="row">
              <PEButton
                element_id="create_view_getbucket"
                className="btn btn-primary"
                onClick={() => setget_bucketfield(true)}
              >
                Get Bucket Field
              </PEButton>
            </div>
          ) : (
            <button className="btn btn-primary" onClick={() => getFieldList()}>
              Get Bucket Field
            </button>
          )}

          <div className="form-group">
            <PEDiv
              element_id="create_view_create"
              className="create-label"
              //onClick={handleAddfield}
            >
              Create Fields
            </PEDiv>

            <div>
              <FieldMappingEditor
                field_mapping={fieldlist}
                referenceJson={default_payload[0]}
                apidata={apidata}
                dropdowndata={resondropdownlist}
                viewlist={view_list}
                view_type={view_type}
                onChange={(new_field_mapping) => {
                  // you can handle it your own way, but this is the recommended approach to check if there is a difference in initial vs new value.
                  // if (
                  //   new_field_mapping.length != 0 &&
                  //   getObjectDifference(new_field_mapping, fieldlist)
                  // ) {
                  //   console.log(new_field_mapping);
                  // }
                  // console.log("new_field_mapping", new_field_mapping);
                  setfieldlist(new_field_mapping);
                }}
              />
            </div>
          </div>
          {/* <div className="form-group">
            <div
              className="create-label"
              //onClick={handleAddfield}
            >
              Create Action API Mapping
            </div>
          </div> */}
          <div className="form-group">
            <QueryBuilder
              fields={search_fields}
              query={query}
              onQueryChange={(q) => setQuerydata(q)}
              operators={Operators}
              addRuleToNewGroups
              controlClassnames={{ queryBuilder: "queryBuilder-branches" }}
            />
            {/* <div
              className="create-label"
              //onClick={handleAddfield}
            >
              Expand Row
            </div>
            <button>Add Row</button>
            {exprow.map((rowdata, i) => (
              <>
                <div className="form-group col-md-2">
                  <label htmlFor="inputEmail4">Field Name</label>
                  <input
                    name="fieldname"
                    className="form-control"
                    placeholder="Enter Field Name"
                    value={rowdata.fieldname}
                    onChange={(e) => handleInputChange(e, i)}
                  />
                </div>
                <div className="form-group col-md-2">
                  <label htmlFor="inputEmail4">Bucket Field Name</label>
                  <select
                    className="form-control"
                    name="BucketField"
                    onChange={(e) => handlechangebucketfield(e, i)}
                    value="" // always use null do not add any value here
                  >
                    <option value="">Select API Field</option>
                    {api_fields_list &&
                      api_fields_list.map((field) => (
                        <option value={field}>{field}</option>
                      ))}
                  </select>
                  {rowdata.BucketField}
                </div>
              </>
            ))} */}
            <PEDiv
              element_id="create_view_createapi"
              className="create-label"
              //onClick={handleAddfield}
            >
              Create Action API Mapping
            </PEDiv>
            <PEButton
              element_id="create_view_addapi"
              onClick={handleAddDiv}
              className="btn btn-info"
            >
              Add API
            </PEButton>
            {divs.map((x, i) => {
              return (
                <div
                  key={i}
                  className="row"
                  style={{ border: "1px solid", marginTop: "5px" }}
                >
                  <PEDiv
                    element_id="create_view_actionname"
                    className="form-group col-md-6"
                  >
                    <label htmlFor="inputEmail4">Action Name</label>
                    <input
                      name="action_name"
                      className="form-control"
                      placeholder="Enter Action Name"
                      value={x.action_name}
                      onChange={(e) => handleInputAPI(e, i)}
                    />
                  </PEDiv>
                  <PEDiv
                    element_id="create_view_actionevent"
                    className="form-group col-md-6"
                  >
                    <label htmlFor="inputEmail4">Action Event</label>
                    <select
                      name="action_event"
                      className="form-control"
                      value={x.action_event}
                      onChange={(e) => handleInputAPI(e, i)}
                    >
                      <option value="">Select Event</option>
                      <option value="action">Action</option>
                      <option value="onsave">On Save</option>
                      <option value="massupdate">Mass Update</option>
                    </select>
                  </PEDiv>
                  {divs.length !== 1 && (
                    <PEDiv
                      element_id="create_view_removeAction"
                      className="form-group col-md-2"
                    >
                      <PEButton
                        element_id="create_view_removediv"
                        className="btn btn-danger"
                        onClick={() => removeDiv(i)}
                      >
                        -
                      </PEButton>
                    </PEDiv>
                  )}
                  <PEDiv
                    element_id="create_view_url"
                    className="form-group col-md-6"
                  >
                    <label>Url Type</label>
                    <select
                      className="form-control"
                      name="url_type"
                      value={x.url_type}
                      onChange={(e) => handleInputAPI(e, i)}
                    >
                      <option value="">select</option>
                      <option value="B">With Base URL</option>
                      <option value="N">Without Base URL</option>
                    </select>
                  </PEDiv>
                  <PEDiv
                    element_id="create_view_apiurl"
                    className="form-group col-md-6"
                  >
                    <label htmlFor="inputEmail4">API URL</label>
                    <input
                      name="api_url"
                      className="form-control"
                      placeholder="Enter API URL"
                      value={x.api_url}
                      onChange={(e) => handleInputAPI(e, i)}
                    />
                  </PEDiv>
                  <PEDiv
                    element_id="create_view_iptype"
                    className="form-group col-md-12"
                  >
                    <label>Input Type</label>
                    <select
                      className="form-control"
                      name="inputType"
                      value={x.inputType}
                      onChange={(e) => handleInputAPI(e, i)}
                    >
                      <option value="formdata">Form Data</option>
                      <option value="formrow">Row Data</option>
                    </select>
                  </PEDiv>

                  {x.inputType == "formdata" &&
                    x.formdata.map((head, index) => (
                      <>
                        <PEDiv
                          element_id="create_view_formdata"
                          className="form-group col-md-4"
                          key={index}
                        >
                          <label htmlFor="inputEmail4">Formdata Field</label>
                          <input
                            className="form-control"
                            name="formdatakey"
                            placeholder="Enter Header Field"
                            value={head.formdatakey}
                            onChange={(e) => handleInputAPI(e, i, head.id)}
                          />
                        </PEDiv>
                        <PEDiv
                          element_id="create_view_formval"
                          className="form-group col-md-4"
                        >
                          <label htmlFor="inputEmail4">Formdata Value</label>

                          <select
                            className="form-control"
                            name="formdatavalue"
                            onChange={(e) => handleInputAPI(e, i, head.id)}
                            value={head.formdatavalue}
                          >
                            <option value="">Select API Field</option>
                            {fieldlist &&
                              fieldlist.map((field) => (
                                <option value={field.fieldname}>
                                  {field.fieldname}
                                </option>
                              ))}
                          </select>
                          {head.formdatavalue}
                        </PEDiv>
                        <div
                          element_id="create_view_formdata"
                          className="form-group col-md-2"
                          key={index}
                        >
                          <label htmlFor="inputEmail4">Type</label>
                          {/* <input
                            className="form-control"
                            name="userdata"
                            placeholder="Enter Header Field"
                            value={head.userdata}
                            onChange={(e) => handleInputAPI(e, i, head.id)}
                          /> */}
                          <select
                            name="inputtype"
                            className="form-control"
                            onChange={(e) => handleInputAPI(e, i, head.id)}
                            value={head.inputtype}
                          >
                            {api_input_type.map((item) => (
                              <option value={item.value}>{item.option}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          element_id="create_view_formdata"
                          className="form-group col-md-2"
                          key={index}
                        >
                          <label htmlFor="inputEmail4">Defaultvalue</label>
                          <input
                            className="form-control"
                            name="defaultvalue"
                            placeholder="Enter Header Field"
                            value={head.defaultvalue}
                            onChange={(e) => handleInputAPI(e, i, head.id)}
                          />
                        </div>
                        <div className="form-group col-md-2">
                          {x.formdata.length !== 1 && (
                            <button
                              className="btn btn-danger"
                              onClick={() => removeFormdata(x.id, head.id)}
                            >
                              -
                            </button>
                          )}
                          {x.formdata.length - 1 === index && (
                            <button
                              className="btn btn-primary"
                              onClick={() => handleAddheaderfield(i)}
                            >
                              +
                            </button>
                          )}
                        </div>
                      </>
                    ))}
                  {x.inputType == "formrow" && (
                    <PEDiv
                      element_id="create_view_uid"
                      className="form-group col-md-12"
                    >
                      <label>
                        Use {"{{ user_id }}"} for user id and for other fields
                        use {"{{ fieldname }}"}
                      </label>
                      <textarea
                        name="formrow"
                        className="form-control"
                        placeholder="Enter Action Name"
                        onChange={(e) => handleInputAPI(e, i)}
                        rows="10"
                      >
                        {x.formrow}
                      </textarea>
                    </PEDiv>
                  )}
                </div>
              );
            })}
          </div>
          <div className="form-group">
            <div className="create-label">Create Get API</div>
            <button onClick={handleAddApidata} className="btn btn-info">
              Add API
            </button>

            {apidata.map((x, i) => {
              return (
                <>
                  <div
                    key={i}
                    className="row"
                    style={{ border: "1px solid", marginTop: "5px" }}
                  >
                    <div className="form-group col-md-10">
                      <label htmlFor="inputEmail4">Action Name</label>
                      <input
                        name="action_name"
                        className="form-control"
                        placeholder="Enter Action Name"
                        value={x.action_name}
                        onChange={(e) => handleApiInput(e, i)}
                      />
                    </div>

                    {apidata.length !== 1 && (
                      <div className="form-group col-md-2">
                        <button
                          className="btn btn-danger"
                          onClick={() => removeGetApi(i)}
                        >
                          -
                        </button>
                      </div>
                    )}
                    <div className="form-group col-md-12">
                      <label htmlFor="inputEmail4">API URL</label>
                      <input
                        name="api_url"
                        className="form-control"
                        placeholder="Enter API URL"
                        value={x.api_url}
                        onChange={(e) => handleApiInput(e, i)}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label>Configure Output</label>
                      <div className="form-group col-md-12">
                        {x.getoutputdata}
                        <textarea
                          name="getoutputdata"
                          className="form-control"
                          placeholder={"{key:value,key:value}"}
                          onChange={(e) => handleApiInput(e, i)}
                        ></textarea>
                      </div>
                    </div>

                    {/* <div className="row form-group col-md-12">
                      <label className="col-md-12">Output Fields</label>

                      {x.outputdata.map((head, index) => (
                        <>
                          <div className="form-group col-md-4" key={index}>
                            <label htmlFor="inputEmail4">Output Key</label>
                            <input
                              className="form-control"
                              name="outputkey"
                              placeholder="Enter Header Field"
                              value={head.outputkey}
                              onChange={(e) => handleApiInput(e, i, head.id)}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="inputEmail4">Output Value</label>

                            <input
                              className="form-control"
                              name="outputvalue"
                              placeholder="Enter Header Field"
                              value={head.outputvalue}
                              onChange={(e) => handleApiInput(e, i, head.id)}
                            />
                            {head.formdatavalue}
                          </div>
                          <div className="form-group col-md-2">
                            {x.outputdata.length !== 1 && (
                              <button
                                className="btn btn-danger"
                                onClick={() =>
                                  removeApioutputFormdata(x.id, head.id)
                                }
                              >
                                -
                              </button>
                            )}
                            {x.outputdata.length - 1 === index && (
                              <button
                                className="btn btn-primary"
                                onClick={() => handleAddapioutputheaderfield(i)}
                              >
                                +
                              </button>
                            )}
                          </div>
                        </>
                      ))}
                      <div className="form-group row col-md-12">
                        <div className="col-md-12">
                          <label className="mr-2">is Table ?</label>
                        </div>

                        {x.tabledata.map((tabledata, index) => (
                          <>
                            <div className="form-group col-md-4" key={index}>
                              <label>Table Key</label>
                              <input
                                className="form-control"
                                value={tabledata.tablekey}
                                onChange={(e) =>
                                  handlechangetablekey(e, i, index)
                                }
                                name="tablekey"
                              />
                            </div>
                            <div className="form-group col-md-4">
                              <label>Table Source</label>
                              <input
                                className="form-control"
                                value={tabledata.tablevalue}
                                onChange={(e) =>
                                  handlechangetablevalue(e, i, index)
                                }
                                name="tablevalue"
                              />
                            </div>
                            <div className="form-group col-md-2">
                              {x.tabledata.length !== 1 && (
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    handleRemoveTable(i, tabledata.id)
                                  }
                                >
                                  -
                                </button>
                              )}

                              {x.tabledata.length - 1 === index && (
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleAddTableData(i)}
                                >
                                  +
                                </button>
                              )}
                            </div>
                          </>
                        ))}
                      </div>
                    </div> */}
                  </div>
                </>
              );
            })}
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary mr-2"
              onClick={() => setisMobileview(true)}
            >
              Create Mobile View
            </button>
            <PEButton
              element_id="create_view_save"
              className="btn btn-primary"
              onClick={saveConfig}
            >
              Save Configuration
            </PEButton>
          </div>
          {/* <br/> */}
          <div></div>
        </div>
      </div>
      {console.log("fieldlist_data", fieldlist)}
      {console.log("props.location.state", props.location.state)}
      {/* {props.location.state.payloadTile && isMobileview ? (
        <PayloadTile
          flag={props.location.state.flag}
          fieldslist={fieldlist}
          create_view={getData}
        />
      ) : (
        ""
      )} */}
    </div>
  );
}

export default Create_view;

import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../assets/images/aceN_Logo.png";
import loader from "../assets/images/loader.gif";
import { logout } from "../hooks/Utility";
import {PEButton, PEDiv, PEImg} from './permissionComponents/index'
//const api_token = localStorage.getItem("api_token");
// const effective_designation_id = localStorage.getItem(
//   "effective_designation_id "
// );
function Dashboard(props) {
  const location = useLocation();
  //const effective_designation_id = location.effective_designation_id;
  const { effective_designation_id, api_token } =
    (props.location && props.location.state) || {};
  return (
    <div className="container">
      <div className="header-area">
        <div className="logout-btn-holder text-right">
          <PEButton element_id="dashboard_logout"
            type="button"
            onClick={logout}
            className="btn btn-outline-primary rounded-circle"
          >
            <i className="fa fa-power-off"></i>
          </PEButton>
        </div>
        <div className="text-center">
          <img src={logo} className="img-responsive logo30" />
        </div>
      </div>
      <div className="card-holder">
        <div className="row mt-4">
          <div className="col-sm-6 mb-2">
            {/* AT-2072 | Vaibhav Sawant | 06-09-2023 | Adding variables in state for create_view */}
            <PEDiv element_id="dashboard_createview"
              className="card text-center"
              onClick={() =>
                props.history.push({
                  pathname: "/View",
                  state: {
                    view_id: "",
                    fieldslist: [],
                    effective_designation_id: effective_designation_id,
                    event_name: "",
                    bucket_name: "",
                    component_mapping: "",
                    view_name: "",
                    api_token: api_token,
                    flag: "C",
                    payloadTile: true,
                  },
                })
              }
            >
              <div className="card-body">
                <p className="card-text">Create View</p>
              </div>
            </PEDiv>
          </div>
          <PEDiv element_id="dashboard_viewlist" className="col-sm-6">
            <div
              className="card text-center"
              onClick={() => props.history.push("ViewList")}
            >
              <div className="card-body">
                <p className="card-text">View List</p>
              </div>
            </div>
          </PEDiv>
          <PEDiv  element_id="dashboard_vendorfield" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Vendor_specific_fields")}
            >
              <div className="card-body">
                <p className="card-text">Vendor Specific Fields</p>
              </div>
            </div>
          </PEDiv>
          {/*------not needed----- */}
          {/* <div className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Nd_Fields")}
            >
              <div className="card-body">
                <p className="card-text">ND Fields</p>
              </div>
            </div>
          </div> */}
          <PEDiv element_id="dashboard_payload"
            className={
              effective_designation_id == ""
                ? "col-sm-6 mt-4 disabletile"
                : "col-sm-6 mt-4"
            }
          >
            <div
              className="card text-center"
              onClick={() =>
                window.location.assign(
                  process.env.REACT_APP_PAYLOAD_VIEW +
                    "/payload?api_token=" +
                    api_token +
                    "&desc_id=" +
                    effective_designation_id +
                    "&uid=" +
                    localStorage.getItem("in_userid")
                )
              }
            >
              <div className="card-body">
                <p className="card-text">
                  Payload{" "}
                  {effective_designation_id == "" && (
                    <div>
                      <img className="t10-r10-25" src={loader} />
                    </div>
                  )}
                </p>
              </div>
            </div>
          </PEDiv>
          {/* AT-2018 | AT-2260 | Ankur Makavana | 03/08/2023 | create new screen for transformation */}
          <PEDiv element_id="dashboard_transformation" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Transformation")}
            >
              <div className="card-body">
                <p className="card-text">Transformation</p>
              </div>
            </div>
          </PEDiv>
          {/* AT-1659 | AT-2299 | Ankur Makavana | 12/08/2023 | show ui and api performance payload on ui */}
          <PEDiv  element_id="dashboard_performance" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Performance_view")}
            >
              <div className="card-body">
                <p className="card-text">Performance</p>
              </div>
            </div>
          </PEDiv>
          <PEDiv element_id="dashboard_cache" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Cache")}
            >
              <div className="card-body">
                <p className="card-text">Cache</p>
              </div>
            </div>
          </PEDiv>
          <PEDiv element_id="dashboard_switch" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Switch")}
            >
              <div className="card-body">
                <p className="card-text">Switch</p>
              </div>
            </div>
          </PEDiv>
          <PEDiv element_id="dashboard_dropdownMaster" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Dropdown_master")}
            >
              <div className="card-body">
                <p className="card-text">Dropdown Master</p>
              </div>
            </div>
          </PEDiv>
          <PEDiv element_id="dashboard_unblockUser" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Unblock_user")}
            >
              <div className="card-body">
                <p className="card-text">Unblock User</p>
              </div>
            </div>
          </PEDiv>
          {/* <div className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Permission")}
            >
              <div className="card-body">
                <p className="card-text">Permission</p>
              </div>
            </div>
          </div> */}
          <PEDiv element_id="dashboard_userFunctionality" className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("User_functionality")}
            >
              <div className="card-body">
                <p className="card-text">User Functionality</p>
              </div>
            </div>
          </PEDiv>




          <div className="col-sm-6 mt-4">
            <div
              className="card text-center"
              onClick={() => props.history.push("Element_permission")}
            >
              <div className="card-body">
                <p className="card-text">Element Permission</p>
              </div>
            </div>
          </div>


          
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
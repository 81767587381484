import React, { useState, useEffect } from "react";
import Commonheader from "./Commonheader";
import useAxios from "../hooks/useAxios";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { SlCalender } from "react-icons/sl";
import { AiOutlineSearch } from "react-icons/ai";
import EditableJsonTree from "./JsonEditor";
import {ConfigFormRenderer,generateConfig,generatePayloadField} from "./Update.js";
import {PEButton, PEDiv, PETh, PETr} from './permissionComponents/index.js'

import { update } from "react-querybuilder";

function Vendor_specific_fields(props) {
  const [default_payload, setdefault_payload] = useState();
  const [loading, sendRequest] = useAxios();
  const [isErrOpen, setisErrOpen] = useState(false);
  const [api_error_message, setapi_error_message] = useState();
  const [viewList, setViewList] = useState([]);
  const [copy_ofviewlist, setCopy_of_viewList] = useState([]);
  const [filData, setFilData] = useState([]);
  const [btndisable, setBtndisable] = useState(false);
  const [vendor_id_list, setVendorlist] = useState();
  const [otherVendor, setOtherVendor] = useState(false);
  const [showMore, setShowMore] = useState();
  const [fields, setfields] = useState([]);
  const [vedoridvalidation, setVendoridvalidation] = useState(false);
  const [eventvalidation, setEventvalidation] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [originalList, setOriginalList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchName, setsearchName] = useState("");
  const [searchLoc, setsearchLoc] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [inputJson,setInputJson] = useState("");

  let [DireError, setDirecError] = useState(false);
  let [showsorting, setSorting] = useState(false);
  let [eventrequired, setEventrequired] = useState(false);
  const [filterText, setFilterText] = useState("");
  let [EvntSort, setEvntSort] = useState(false);
  let [locSort, setLocSort] = useState(false);
  let [dateSort, setDateSort] = useState(false);
  const [nd_id, setNd_id] = useState("");
  let [ndName, setNd_name] = useState();
  const [toggle, setToggle] = useState(true);
  const [filters, setFilters] = useState({});
  const [fieldlist, setfieldlist] = useState([
    {
      vendor_id: "",
      event: "",
      event_location: "",
      event_direction: "",
      payload_field: "",
    },
  ]);
  const [val, setVal] = useState([
    {
      payload_field: "",
      field_datatype: "",
      is_mandatory: "",
      status: "A",
    },
  ]);

  const [configedJson,setConfigedJson] = useState(generateConfig({'editMe':''}))
  const [textareaVisible,setTextareaVisible] = useState(false)

  useEffect(() => {
    let apitoken = localStorage.getItem("api_token");
    sendRequest(
      {
        //At-2143||rohit chitampalle||api-restructuring-and-connection

        url: `${process.env.REACT_APP_BASE_URL}/get/all/vendor/payload/data`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        // console.log("View--List", response);
        response.sort(function(a, b) {
          //sorting by date
          return new Date(b.created_at) - new Date(a.created_at);
        });
        setViewList(response);
        setOriginalList(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/vendor_id/list`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setVendorlist(response);
      }
    );
    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/nd/id`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setNd_id(response[0].vendor_id);
        setNd_name(response[0].vendor_name);
      }
    );
    // let {event}=fieldlist[0]
    // let {payload_field,field_datatype,is_mandatory}=val[0]
    // if(event.length>0 && payload_field.length>0&&field_datatype>0,is_mandatory>0){
    //   setEventrequired(true)
    // }
  }, []);

  // const filteredData = viewList.filter(item =>
  //   item.toLowerCase().includes(filterText.toLowerCase())
  // );

  const handleAdd = () => {
    const abc = [
      ...val,
      {
        payload_field: "",
        field_datatype: "",
        is_mandatory: "",
        status: "A",
      },
    ];
    setVal(abc);
    // setBtndisable(false)
    if (otherVendor == true) {
      let { event, event_location } = fieldlist[0];
      viewList.map((value) => {
        if (
          value.event_name == event &&
          value.event_location == event_location
        ) {
          setBtndisable(true);
        }
      });
      if (event == 0) {
        setBtndisable(false);
        setEventvalidation(false);
      }
    }
    if (otherVendor == false) {
      let { event, event_location } = fieldlist[0];
      viewList.map((value) => {
        if (
          value.event_name == event &&
          value.event_location == event_location
        ) {
          setBtndisable(true);
        }
      });
      if (event == 0) {
        setBtndisable(false);
        setEventvalidation(false);
      }
    }
  };
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...val];
    onchangeVal[i][name] = value;
    setVal(onchangeVal);
  };
  const handleDelete = (i) => {
    const deletVal = [...val];
    deletVal.splice(i, 1);
    setVal(deletVal);
    // setBtndisable(false)
    if (otherVendor == true) {
      let { event } = fieldlist[0];
      viewList.map((value) => {
        if (value.event_name == event) {
          setBtndisable(true);
        }
      });
      if (event == 0) {
        setBtndisable(false);
        setEventvalidation(false);
      }
    }

    if (otherVendor == false) {
      let { event } = fieldlist[0];
      viewList.map((value) => {
        if (value.event_name == event) {
          setBtndisable(true);
        }
      });
      if (event == 0) {
        setBtndisable(false);
        setEventvalidation(false);
      }
    }
  };
  // console.log(val, "data-");
  const handleAddfield = () => {
    setfields([
      {
        payload_field: "",
        field_datatype: "",
        is_mandatory: "",
      },
    ]);
  };

  const handleInputChange = (e, index, api_index = null) => {
    // console.log(e.target);
    const { name, value } = e.target;
    if (api_index === null) {
      const list = [...fieldlist];
      list[index][name] = value;

      setfieldlist(list);
      let { event, event_direction } = fieldlist[0];
      viewList.map((value) => {
        if (value.event_name != event) {
          setBtndisable(false);
        }
      });
      if (event == 0) {
        setBtndisable(false);
        setEventvalidation(false);
      }
      if (event_direction.length > 0) {
        setDirecError(false);
      }
    }

    // if (e.target.name == "event" && fieldlist[0].event.length >0) {
    //   setEventrequired(false)
    // }
    // if(e.target)
  };
  let displaynone = () => {
    setShowInput(false);
  };
  let blurEvent = () => {
    setSorting(false);
    setEvntSort(false);
    setLocSort(false);
    setDateSort(false);

    let { vendor_id, event, status } = fieldlist[0];
    for (let i = 0; i < viewList.length; i++) {
      // console.log({ index: i, value: viewList[i] });
      if (otherVendor == true) {
        if (
          viewList[i].vendor_id == vendor_id &&
          viewList[i].event_name == event
        ) {
          setBtndisable(true);
          setisErrOpen(true);
          setapi_error_message("Event for vendor already exists");
        }
      }
      if (otherVendor == false) {
        if (viewList[i].vendor_id == nd_id && viewList[i].event_name == event) {
          setBtndisable(true);
          setisErrOpen(true);
          setapi_error_message("Event for vendor already exists");
        }
      }
    }
  };
  const vendor_id = () => {
    let apitoken = localStorage.getItem("api_token");
    setOtherVendor(true);

    sendRequest(
      {
        url: `${process.env.REACT_APP_BASE_URL}/get/vendor_id/list`,
        method: "GET",
        //data: inputdata,
        headers: {
          "api-token": apitoken,
        },
      },
      (response) => {
        setVendorlist(response);
      }
    );
  };

  let load = () => {
    // console.log(viewList,"In load function")
    // console.log(originalList,"originalList in load function")
    // setViewList(originalList);
    // console.log(viewList,"After load function----")
    // console.log(viewList,"After load")
    // let apitoken = localStorage.getItem("api_token");
    // setTimeout(() => {
    //   sendRequest(
    //     {
    //       //At-2143||rohit chitampalle||api-restructuring-and-connection
    //       url: `${process.env.REACT_APP_BASE_URL}/get/all/vendor/payload/data`,
    //       method: "GET",
    //       //data: inputdata,
    //       headers: {
    //         "api-token": apitoken,
    //       },
    //     },
    //     (response) => {
    //       // console.log("View--List", response);
    //       response.sort(function (a, b) {
    //         //sorting by date
    //         return new Date(b.created_at) - new Date(a.created_at);
    //       });
    //       setViewList(response);
    //     }
    //   );
    // })
  };
  const saveConfig = () => {
    // setBtndisable(true)
    let {
      vendor_id,
      event,
      event_location,
      status,
      event_direction,
    } = fieldlist[0];
    try {
      if (event.length == 0) {
        setEventvalidation(true);
      }

      let apitoken = localStorage.getItem("api_token");
      let payload_json = JSON.stringify(val);

      const form = new FormData();
      if (otherVendor) {
        form.append("vendor_id", vendor_id);
        if (vendor_id.length == 0) {
          setVendoridvalidation(true);
        }
      } else {
        form.append("vendor_id", nd_id);
        if (nd_id < 0) {
          setVendoridvalidation(true);
        }
      }

      if (otherVendor == false) {
        if (nd_id != 0 && event.length != 0) {
          form.append("event_name", event);
          form.append("event_location", event_location);
          form.append("payload_field", payload_json);
          form.append("event_direction", event_direction);
          sendRequest(
            {
              url: `${process.env.REACT_APP_BASE_URL}/set/vendor/mapping/data`,
              method: "POST",
              data: form,
              headers: {
                "Content-Type": "multipart/form-data",
                "api-token": apitoken,
              },
            },
            (response) => {
              console.log(response);
              setisErrOpen(true);
              setapi_error_message(response[0].message);
              setBtndisable(true);
              setfields({
                payload_field: "",
                field_datatype: "",
                is_mandatory: "",
              });
              load();
            }
          );
        }
      }

      if (otherVendor == true) {
        if (vendor_id != 0 && event.length != 0) {
          form.append("event_name", event);
          form.append("event_location", event_location);
          form.append("payload_field", payload_json);
          form.append("event_direction", event_direction);

          sendRequest(
            {
              url: `${process.env.REACT_APP_BASE_URL}/set/vendor/mapping/data`,
              method: "POST",
              data: form,
              headers: {
                "Content-Type": "multipart/form-data",
                "api-token": apitoken,
              },
            },
            (response) => {
              // console.log(response);
              setisErrOpen(true);
              setapi_error_message(response[0].message);
              setBtndisable(true);

              setfields({
                payload_field: "",
                field_datatype: "",
                is_mandatory: "",
              });
              load();
            }
          );
        }
      }
    } catch (error) {
      throw error(error);
    }

    // const formdata = new FormData();
    // let flag = 0;
    // console.log("posting data---", val)

    // for (let index = 0; index <= val.length; index++) {
    //   flag = flag++
    //   formdata.append("vendor_id", vendor_id);
    //   formdata.append("event_name", event);
    //   const element = val[index];
    //   if (element != undefined) {
    //     formdata.append("payload_field", element['payload_field'])
    //     sendRequest(
    //       {
    //         url: `${process.env.REACT_APP_BASE_URL}/set/vendor/mapping/data`,
    //         method: "POST",
    //         data: formdata,
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //           "api-token": apitoken,
    //         },
    //       },
    //       (response) => {
    //         setisErrOpen(true);
    //         setapi_error_message(response[0].message);
    //       }
    //     );
    //   }

    // }
  };
  //---- Rohit Chitampalle || 29-08-2023 || Manage state instead of calling again api managing state only--

  const handleSearch = (event) => {
    const query = event.target.value;
    // console.log(query);
    setSearchQuery(query);
    // console.log(query.length, "Length --");
    if (copy_ofviewlist.length == 0) {
      const searchList = originalList.filter((item) => {
        // console.log(item);
        return (
          item.vendor_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      });
      setViewList(searchList);
      setCopy_of_viewList(searchList);
    } else {
      const slist = viewList.filter((item) => {
        // console.log(item);
        return (
          item.vendor_name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      });
      setViewList(slist);
    }
    if (query.length == 0) {
      if (viewList.length == 1) {
        viewList.pop();

        setViewList(originalList);
      }

      // setViewList(searchList)
      // window.location.reload()
      // load()
      // console.log(originalList, "When search length is 0");
      // setViewList(originalList);
    }

    // setFilteredList(searchList);
  };

  const filterData = () => {
    return originalList.filter((row) => {
      for (const columnName in filters) {
        const filterText = filters[columnName].toLowerCase();
        const cellText = String(row[columnName]).toLowerCase();
        if (!cellText.includes(filterText)) {
          return false;
        }
      }
      return true;
    });
  };
  const eventNameSearch = (e, columnName) => {
    const updatedFilters = { ...filters, [columnName]: e.target.value };
    setFilters(updatedFilters);
  };
  const showalldata = (i) => {
    setShowMore(i);
  };
  const removedata = () => {
    setShowMore();
  };

  const handleCreateFromSample = (e)=>{
    try{
      const input = JSON.parse(inputJson.trim());
      setConfigedJson(generateConfig(input));
    }
    catch(e){
      alert("invalid JSON")
    }
  }
  
  const filteredData = filterData();
  return (
    <div className="container">
      <Commonheader pagetitle={"Create View"} props={props} />
      {loading && (
        <div className="load-4">
          <div className="ring-1"></div>
        </div>
      )}
      <div
        className={
          isErrOpen
            ? "modal fade show d-block mymodal"
            : "modal fade bd-example-modal-sm"
        }
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        id="confirm-box"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content cdb-confirm-box col-sm-12">
            <button
              type="button"
              className="close text-right"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setisErrOpen(false);
                // window.location.reload();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p className="popuperrtext">{api_error_message}</p>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="viewname-holder">
          <div className="form-group">
            <div className="create-label">Create Fields</div>
            {fieldlist.map((x, i) => {
              return (
                <div className="row d-flex flex-column" key={i}>
                  <PEDiv element_id="vendorfield_vendorid" className="px-3">
                    <label htmlFor="inputEmail4">Navadhan :</label>
                    <input
                      type="radio"
                      name="vendor_id"
                      className="mr-3"
                      placeholder="Enter Vendor Id"
                      onClick={(e) => setOtherVendor(false)}
                    />

                    <label htmlFor="inputEmail4">Other :</label>
                    <input
                      type="radio"
                      name="vendor_id"
                      // className="form-control"
                      placeholder="Enter Vendor Id"
                      value={x.vendor_id}
                      onClick={(e) => vendor_id()}
                    />
                  </PEDiv>
                  {otherVendor ? (
                    <>
                      <PEDiv element_id="vendorfield_vendor_id" className="form-group col-md-2 mw-100">
                        <label>Vendor :</label>
                        <select
                          className="form-control"
                          name="vendor_id"
                          onChange={(e) => handleInputChange(e, i)}
                          value={x.vendor_id}
                        >
                          <option value="">Select</option>
                          {vendor_id_list.map((val, index) => {
                            return (
                              <>
                                {" "}
                                <option value={val.vendor_id}>
                                  {val.vendor_name}
                                </option>
                              </>
                            );
                          })}
                        </select>
                        {vedoridvalidation ? (
                          <>
                            <div className="w-75  text-danger">
                              Please select a valid vendor.
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-75 text-danger d-none">
                              Please select a valid vendor.
                            </div>
                          </>
                        )}
                      </PEDiv>
                    </>
                  ) : (
                    <>
                      <PEDiv element_id="vendorfield_navadhanid"className="form-group col-md-2 mw-100">
                        <label htmlFor="inputEmail4">Navadhan id :</label>
                        <input
                          name="vendor_id"
                          className="form-control"
                          placeholder="Vendor id"
                          value={ndName}
                          disabled
                          onChange={(e) => setNd_id(e.target.value)}
                        />
                        {vedoridvalidation ? (
                          <>
                            <div className="w-75  text-danger">
                              Please select a valid .
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="w-75 text-danger d-none">
                              Please select a valid .
                            </div>
                          </>
                        )}
                      </PEDiv>
                    </>
                  )}
                  <div className="form-group col-md-2 mw-100 needs-validation">
                    <label htmlFor="inputEmail4">Event :</label>
                    <input
                      name="event"
                      className="form-control"
                      placeholder="Event"
                      onBlur={blurEvent}
                      value={x.event}
                      required
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    {eventvalidation ? (
                      <>className="d-flex justify-content-between"
                        <div className="w-75  text-danger">
                          Please enter a Event.
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-75 text-danger d-none">
                          Please enter a Event.
                        </div>
                      </>
                    )}
                  </div>
                  <PEDiv element_id="vendorfield_location" className="form-group col-md-2 mw-100 needs-validation">
                    <label htmlFor="inputEmail4">Event Location:</label>
                    <input
                      name="event_location"
                      className="form-control"
                      placeholder="Event Location"
                      onBlur={blurEvent}
                      value={x.event_location}
                      required
                      onChange={(e) => handleInputChange(e, i)}
                    />
                    {eventvalidation ? (
                      <>
                        <PEDiv element_id="vendorfield_eventlocation" className="w-75  text-danger">
                          Please enter a Event Location.
                        </PEDiv>
                      </>
                    ) : (
                      <>
                        <PEDiv element_id="vendorfield_event_location" className="w-75 text-danger d-none">
                          Please enter a Event Location.
                        </PEDiv>
                      </>
                    )}
                  </PEDiv>

                 
                </div>
              );
            })}
          </div>
          <div>
            <PEDiv element_id="vendorfield_samplediv" className="d-flex justify-content-between">
              <span>Json Config: </span>
              <PEButton element_id="vendorfield_jsonsample"
                className="btn btn-primary"
                onClick={()=>{setTextareaVisible(true)}}
              >
                Create from Json sample
              </PEButton>
            </PEDiv>
            { 
              textareaVisible &&
              <div className="mb-3">
                <div>
                  <textarea value={inputJson} rows="4"  onChange={(e)=>{setInputJson(e.target.value)}} style={{width:'100%',marginTop:'10px',padding:'20px'}} placeholder="Enter valid JSON..."></textarea>
                </div>
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    <PEButton element_id="vendorfield_cancel"
                      className="btn btn-primary mr-2"
                      onClick={()=>{setTextareaVisible(false)}}
                    >
                      Cancel
                    </PEButton>
                    <PEButton element_id="vendorfield_createconfig"
                      className="btn btn-primary"
                      onClick={()=>{setTextareaVisible(false);handleCreateFromSample()}}
                    >
                      Create Config
                    </PEButton>
                  </div>
                </div>
              </div>
            }
            {
              !textareaVisible &&
              <EditableJsonTree data={configedJson} 
                onChange={(updated_value)=>{
                  setVal(generatePayloadField(updated_value))
                }} 
                editEnabled={true}
                ValueRenderer={ConfigFormRenderer}
            />
            }
          </div>
          <div className="text-center mw-100 not-allowed">
            <PEButton element_id="vendorfield_save"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveConfig}
              disabled={btndisable}
            >
              save
            </PEButton>
          </div>
        </div>
        {/* <div className="mt-4">
          <input placeholder="Search Vendor Name" name="search" className="form-control" type="text" value={searchQuery}
            onChange={handleSearch} />
        </div> */}

        <PEDiv element_id="vendorfield_vendorname" className="mt-4">
          <table className="table">
            <thead className="thead-light">
              <PETr element_id="vendorfield_vendorrow">
                <PETh element_id="vendorfield_vendorname"scope="col" className="w-25 position-relative">
                  Vendor Name
                  <br />
                  <input
                    placeholder="Search Vendor Name"
                    name="search"
                    onBlur={blurEvent}
                    className="form-control"
                    type="text"
                    value={filters["vendor_name"]}
                    onChange={(e) => eventNameSearch(e, "vendor_name")}
                  />
                </PETh>

                <PETh element_id="vendorfield_eventname" scope="col">
                  Event Name
                  <br />
                  <input
                    placeholder="Event Name"
                    name="search"
                    className="form-control"
                    type="text"
                    onBlur={blurEvent}
                    value={filters["event_name"]}
                    onChange={(e) => eventNameSearch(e, "event_name")}
                  />
                </PETh>

                <PETh element_id="vendorfield_eventlocation" scope="col">
                  Event Location
                  <br />
                  <input
                    placeholder="Event Location"
                    name="search"
                    className="form-control"
                    type="text"
                    onBlur={blurEvent}
                    value={filters["event_location"]}
                    onChange={(e) => eventNameSearch(e, "event_location")}
                  />
                </PETh>

                <PETh element_id="vendorfield_paylod" scope="col">
                  Payload Fields
                  <br />
                  <input
                    placeholder="Event Location"
                    name="search"
                    className="form-control"
                    type="text"
                    onBlur={blurEvent}
                    value={filters["payload_field"]}
                    onChange={(e) => eventNameSearch(e, "payload_field")}
                  />
                </PETh>

                <PETh element_id="vendorfield_created" scope="col">
                  Created At
                  <br />
                  <input
                    placeholder="Search date"
                    name="search"
                    className="form-control"
                    type="text"
                    onBlur={blurEvent}
                    value={filters["created_at"]}
                    onChange={(e) => eventNameSearch(e, "created_at")}
                  />
                </PETh>
                <th scope="col">Action </th>
              </PETr>
            </thead>
            <tbody>
              {filteredData &&
                filteredData.map((list, index) => (
                  <PETr element_id="vendorfield_data" key={index}>
                    <td>{list.vendor_name}</td>
                    <td>{list.event_name}</td>
                    <td>{list.event_location}</td>
                    <td>
                      {/* --------------AT-2326||Rohit Chitampalle ||22-08-2023||added show more functionality----------------- */}
                      {showMore == index
                        ? JSON.stringify(list.payload_field)
                        : `${JSON.stringify(list.payload_field).slice(0, 60)}`}
                      {showMore == index ? (
                        <>
                          <PEButton element_id="vendorfield_showless" className="btn" onClick={removedata}>
                            showLess
                          </PEButton>
                        </>
                      ) : (
                        <>
                          <PEButton element_id="vendorfield_showmore"
                            className="btn"
                            onClick={() => showalldata(index)}
                          >
                            showMore....
                          </PEButton>
                        </>
                      )}
                    </td>
                    <td>{list.created_at}</td>
                    <td>
                      <PEButton element_id="vendorfield_update"
                        type="button"
                        className="btn btn-outline-info"
                        onClick={() =>
                          props.history.push({
                            pathname: `Vendor_specific_fields_update`,
                            state: {
                              view_id: list.id,
                            },
                          })
                        }
                      >
                        <EditOutlined />
                      </PEButton>
                    </td>
                  </PETr>
                ))}
            </tbody>
          </table>
        </PEDiv>
      </div>
    </div>
  );
}

export default Vendor_specific_fields;

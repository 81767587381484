import React, { Component } from "react";
import PropTypes from "prop-types";
import { PermissionsContext } from "./PermissionContext";
import { Modal, Form, Input, Button, Radio } from "antd";
import styles from "./Permission.module.css";

export const withFeaturePermission = (WrappedComponent) => {
  class FeaturePermissionComponent extends Component {
    static contextType = PermissionsContext;

    static propTypes = {
      element_id: PropTypes.string.isRequired,
    };

    constructor(props) {
      super(props);
      this.clickTimer = null;
      this.state = {
        isModalVisible: false,
        elementId: this.props.element_id,
        isPopUpButtonVisible: false,
      };
    }

    componentDidMount() {
      if (!this.props.element_id) {
        throw `Missing element_id property`;
      }
    }

    resetLongPressStatus = () => {
      this.context.setLongPressActive(false);
    };

    handleLongClick = () => {
      if (!this.context.longPressActive) {
        this.context.setLongPressActive(true);
        const elementDetail = this.context.getElementById(this.state.elementId);
        if (!elementDetail.element_id) {
          this.context.saveElement([this.state.elementId]);
        }
        this.setIsModalVisible(true);
      }
    };

    handleMouseDown = () => {
      this.clickTimer = setTimeout(this.handleLongClick, 500);
    };

    handleMouseUp = () => {
      clearTimeout(this.clickTimer);
      if (this.props.onClick && !this.context.longPressActive) {
        this.props.onClick();
      }
    };

    handleFormSubmit = (values) => {
      this.resetLongPressStatus();
      this.context.saveElementPermission({
        element_id: this.context.getElementById(this.state.elementId)
          .element_id,
        permission: values.element_permission,
      });
    };

    setIsModalVisible = (value) => {
      this.setState({ isModalVisible: value });
    };

    render() {
      let isFeatureHidden = false;
      let isFeatureDisabled = false;

      const permission = this.context.getPermissionById(this.state.elementId);

      if (permission) {
        isFeatureHidden = permission === "H";
        isFeatureDisabled = permission === "D";
        console.log("isFeatureDisabled",this.state.elementId+"="+isFeatureDisabled);
      } 
      // else if (!this.context.isAdminMode) {
      //   //return null;
      // }

      if (!this.context.isAdminMode && isFeatureHidden) {
        return null;
      }

      if (this.context.isAdminMode) {
        return (
          <>
            <EditComponentModal
              component_id={this.props.element_id}
              initialValues={permission}
              isModalVisible={this.state.isModalVisible}
              setIsModalVisible={this.setIsModalVisible}
              resetLongPressStatus={this.resetLongPressStatus}
              handleFormSubmit={this.handleFormSubmit}
            />

            <WrappedComponent
              {...this.props}
              onMouseOver={(event) => {
                event.stopPropagation();
                this.handleMouseOver();
              }}
              onMouseDown={(event) => {
                event.stopPropagation();
                this.handleMouseDown();
              }}
              onMouseUp={(event) => {
                event.stopPropagation();
                this.handleMouseUp();
              }}
              style={{ outline: "2px solid orange" }}
              onClick={() => {}}
            />
          </>
        );
      }

      return (
        <>
          <WrappedComponent
            {...this.props}
            disabled={
              isFeatureDisabled
                ? isFeatureDisabled
                : this.props.disabled
            }
            className={
              isFeatureDisabled
                ? this.props.className + " "+ styles.permission_disabled
                : this.props.className
            }
          />
        </>
      );
    }
  }
  return FeaturePermissionComponent;
};

const EditComponentModal = ({
  initialValues,
  component_id,
  isModalVisible,
  setIsModalVisible,
  handleFormSubmit,
  resetLongPressStatus,
}) => {
  const handleCancel = () => {
    resetLongPressStatus();
    setIsModalVisible(false);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
                Open Modal
            </Button> */}
      <Modal
        title={`Edit Permission-${component_id}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          name="editComponentForm"
          initialValues={{
            name: "Initial Name",
            description: "Initial Description",
          }}
          onFinish={(values) => {
            handleFormSubmit(values);
            setIsModalVisible(false);
          }}
        >
          <Form.Item
            label="Element Permission"
            name="element_permission"
            initialValue={initialValues || "E"}
          >
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="E">Enabled</Radio.Button>
              <Radio.Button value="D">Disabled</Radio.Button>
              <Radio.Button value="H">Hidden</Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditComponentModal;

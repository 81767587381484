import React from "react";
import { logout } from "../hooks/Utility";
import {PEDiv,PEButton} from './permissionComponents/index'

const divStyle = {

  backgroundColor: '#319ee7',
  padding : '10px 40px 10px 40px' ,
  borderRadius: '2px',
  color:'white'
  

  
  
  
};

function Commonheader(props) {
  return (
    <div>
      <PEDiv element_id="commnoheader" className="header-holder d-flex align-items-center justify-content-between my-2">
        <div className="back-button">
          <PEButton element_id="commnoheader_goback"
            type="button"
            className="btn btn-outline-primary rounded-circle"
            onClick={() => props.props.history.goBack()}
          >
            <i className="fa fa-chevron-left"></i>
          </PEButton>
        </div>
        <div className="title-holder" style={divStyle}>{props.pagetitle}</div>
        <div className="logout-button">
          <PEButton element_id="commnoheader_logout"
            type="button"
            onClick={logout}
            className="btn btn-outline-primary rounded-circle"
          >
            <i className="fa fa-power-off"></i>
          </PEButton>
        </div>
      </PEDiv>
    </div>
  );
}

export default Commonheader;
